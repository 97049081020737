import { useContext, useEffect, useMemo } from 'react';

import { DashboardTableContext } from './dashboard-table-context';
import { DataTableContext } from 'components/data-table/data-table-context';
import { useAllocationsContext } from 'ui/allocations/allocations-context';
import { useModals } from '@ncc-frontend/core';
import DashboardSidePanelContent from './dashboard-side-panel';
import DashboardTableFilters from './dashboard-table-filters';
import DataTableContainer from 'components/data-table/data-table-container';

function DashboardTable({ dashboardData, refetch, ...props }) {
    const { push } = useModals();
    const { toggleSidePanelFunction } = useContext(DataTableContext);
    const { month, setMonth, setWeek, setYear, week, year } =
        useAllocationsContext();
    const tableId = 'admin-dashboard';
    const pickerType = 'month';

    useEffect(() => {
        toggleSidePanelFunction(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => [
            {
                Cell: ({ value }) => {
                    return (
                        <span className="text-ncc-portal-blue font-SemiBold">
                            {value}
                        </span>
                    );
                },
                Header: 'Product',
                accessor: 'product.name',
                enableGlobalFilter: true,
                showInMainFilters: true
            },
            {
                Header: 'Time spent',
                accessor: (prop) => {
                    const { time_spent } = prop;
                    const valInDays = Math.floor(time_spent / 24);
                    const valToOneDecimalPlace =
                        Math.round(time_spent * 10) / 10;

                    return `${valToOneDecimalPlace} hrs (${valInDays} days)`;
                },
                enableGlobalFilter: true,
                id: 'time_spent',
                showInSideFilters: false
            },

            {
                Cell: ({ value }) => {
                    return (
                        <div className="flex items-center gap-2">
                            {`£${value}`}
                        </div>
                    );
                },
                Header: 'Development cost',
                accessor: 'cost',
                enableGlobalFilter: true
            }
        ],
        []
    );

    const defaultColumn = useMemo(
        () => ({
            // Set up our default Filter UI
            Filter: DashboardTableFilters
        }),
        []
    );

    const tableContext = useMemo<DashboardTableContext>(
        () => ({
            month,
            pickerType,
            push,
            refetch,
            setMonth,
            setWeek,
            setYear,
            sideStats: dashboardData['key_info'],
            week,
            year
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            push,
            refetch,
            setWeek,
            week,
            year,
            setMonth,
            setYear,
            month,
            pickerType,
            dashboardData
        ]
    );

    return (
        <DataTableContainer
            openFilters={true}
            defaultColumn={defaultColumn}
            tableId={tableId}
            context={tableContext}
            columns={columns}
            data={dashboardData ? dashboardData.products : []}
            textSearchId="product"
            sidePanelContent={
                <DashboardSidePanelContent context={tableContext} {...props} />
            }
        />
    );
}

export default DashboardTable;
