import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import TypographyHeader from 'components/typography/typography-header';

const TeamsSidePanelContent = () => {
    return (
        <>
            <TypographyHeader
                level={3}
                shadow
                className="border-b border-solid pb-ncc-padding-12 mb-ncc-margin-24"
            >
                Filters
                <span
                    // onClick={() => props.toggleSidePanelFunction(true)}
                    className="ml-auto cursor-pointer"
                >
                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </span>
            </TypographyHeader>
            {/* <div {...props.getTableProps()}>
                {props.headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <ul
                                {...column.getHeaderProps()}
                                className="mb-ncc-margin-24"
                            >
                                <li>
                                    {column.showInSideFilters &&
                                    column.canFilter
                                        ? column.render('Filter')
                                        : null}
                                </li>
                            </ul>
                        ))}
                    </div>
                ))}
            </div> */}
        </>
    );
};

export default TeamsSidePanelContent;
