import { FunctionComponent, useMemo } from 'react';
import { TailwindProps } from '@ncc-frontend/core';
import Typography from '../../components/typography';
import classNames from 'classnames';

interface IMainRouteHeading extends TailwindProps {
    children?;
    level: 1 | 2 | 3 | 4 | 5 | 6;
    shadow: boolean;
    title: string | null | undefined;
}

const MainRouteHeading: FunctionComponent<IMainRouteHeading> = ({
    children,
    className,
    level,
    shadow,
    title
}) => {
    const cssClasses = useMemo(
        () =>
            classNames(
                className,
                'main-route-heading dark:text-white dark:bg-utilisation-page-dark h-[75px]'
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Typography.Header
                level={level}
                className={cssClasses}
                shadow={shadow}
            >
                {title}
                {children}
            </Typography.Header>
        </>
    );
};

export default MainRouteHeading;
export type { IMainRouteHeading };
