import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-solid-svg-icons';

interface IWeekPicker {
    currentWeekRange?: string;
    currentYear?;
    selectedWeek?;
    weekScroll?;
    year?;
}

function WeekPicker({
    currentWeekRange,
    selectedWeek,
    weekScroll,
    year
}: IWeekPicker) {
    return (
        <>
            <div className="py-2 px-[24px] rounded-[8px] border border-solid border-ncc-grey-30 bg-white">
                <p>
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                    <strong>
                        Wk {selectedWeek} - {currentWeekRange} - {year}
                    </strong>
                </p>
            </div>
            <div className="py-2 px-1 ml-2 items-center border border-solid border-ncc-grey-30 bg-white">
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="border-r px-2 cursor-pointer"
                    onClick={() => {
                        weekScroll('back');
                    }}
                    id="scrollBackwards"
                />
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className="px-2 cursor-pointer"
                    onClick={() => {
                        weekScroll('forw');
                    }}
                    id="scrollForwards"
                />
            </div>
        </>
    );
}

export default WeekPicker;
