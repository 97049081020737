import { ComponentType, MouseEventHandler, useCallback, useMemo } from 'react';

import { CalendarItemProps } from './calendar-item';

interface MonthProps {
    activeDates: [Date | undefined, Date | undefined];
    calendarItemComponent: ComponentType<CalendarItemProps>;
    constrain: 'future' | 'past' | 'unrestricted';
    locale: string;
    month: number;
    onSelect: (month: number) => void;
    year: number;
}

function Month({
    activeDates,
    calendarItemComponent: CalendarItemComponent,
    constrain,
    locale,
    month,
    onSelect,
    year,
    ...restProps
}: MonthProps) {
    const date = useMemo(() => new Date(year, month), [month, year]);

    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.stopPropagation();
            onSelect(month);
        },
        [month, onSelect]
    );

    const today = useMemo(() => {
        const result = new Date();
        result.setDate(1);
        result.setHours(0);
        result.setMinutes(0);
        result.setSeconds(0);
        result.setMilliseconds(0);
        return result;
    }, []);

    const active = activeDates
        .filter(Boolean)
        .map((item) => {
            const result = new Date(item as Date);
            result.setDate(1);
            result.setHours(0);
            result.setMinutes(0);
            result.setSeconds(0);
            result.setMilliseconds(0);
            return result;
        })
        .some((item) => item.getTime() === date.getTime());

    const dayInvariantActiveDates = [
        (activeDates[0] &&
            new Date(
                activeDates[0].getFullYear(),
                activeDates[0].getMonth()
            )) ||
            undefined,
        (activeDates[1] &&
            new Date(
                activeDates[1].getFullYear(),
                activeDates[1].getMonth()
            )) ||
            undefined
    ];

    const highlight: CalendarItemProps['highlight'] =
        !!activeDates &&
        !!dayInvariantActiveDates[0] &&
        !!dayInvariantActiveDates[1]
            ? new Date(
                  dayInvariantActiveDates[0].getFullYear(),
                  dayInvariantActiveDates[0].getMonth() + 1
              ) > dayInvariantActiveDates[1]
                ? undefined
                : date.getTime() === dayInvariantActiveDates[0].getTime()
                ? 'right'
                : date.getTime() === dayInvariantActiveDates[1].getTime()
                ? 'left'
                : dayInvariantActiveDates[0] < date &&
                  date < dayInvariantActiveDates[1]
                ? 'full'
                : undefined
            : undefined;

    return (
        <CalendarItemComponent
            {...restProps}
            className="w-20 h-14"
            date={date}
            today={today}
            constrain={constrain}
            onClick={handleClick}
            active={active}
            highlight={highlight}
        >
            {date.toLocaleString(locale, { month: 'short' })}
        </CalendarItemComponent>
    );
}

export default Month;
export type { MonthProps };
