/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ActivityGroup,
    Allocation,
    DayRate,
    FilterItem,
    Notification,
    NotificationBody,
    NotificationSubject,
    Product,
    ProductType,
    UserBase,
    UserNotificationPreferences,
    UserWithAllocationsAndSummaryForWeek,
    UserWithContractAndDayRate,
    Uuid,
    UuidRO,
    UuidWO
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AppController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * @description Gathers the data required for viewing the My Allocations view for regular users.
     *
     * @tags app
     * @name AppUserAllocations
     * @summary Data for user allocation page
     * @request GET:/app/user/allocations
     * @secure
     */
    appUserAllocations = (
        query?: {
            /**
             * Limit the result set to resources of the given week
             * @format YYYY-WW
             * @example "2022-45"
             */
            week?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<
            {
                /**
                 * The current week
                 * @format YYYY-WW
                 * @example "2022-22"
                 */
                week: string;
                /** The user's current contract */
                contract: {
                    /** A UUID in the v4 format */
                    uuid?: UuidRO;
                    /** A UUID in the v4 format */
                    user_uuid?: UuidWO;
                    user?: {
                        /** A UUID in the v4 format */
                        uuid: UuidRO;
                        /** @example "peter.scopes" */
                        username: string;
                        /** @example "peter.scopes@nccgroup.com" */
                        email: string;
                        /** @example "Peter" */
                        given_name: string | null;
                        /** @example "Scopes" */
                        family_name: string | null;
                        /**
                         * The preferred way of displaying this user's name. Use this, if available, instead of guessing which of `given_name` or `family_name` to show and in what order.
                         * @example "Pete Scopes"
                         */
                        display_name: string | null;
                        /**
                         * @minItems 1
                         * @example ["ALLOCATOR","ADMINISTRATOR"]
                         */
                        roles: ('ALLOCATOR' | 'REPORTER' | 'ADMINISTRATOR' | 'LINE_MANAGER')[];
                        /**
                         * The timestamp when the user was deactivated
                         * @format date-time
                         * @default null
                         */
                        deactivated_at: string | null;
                        /**
                         * The last time this user was seen in our system's ID Pool. Their last login time will be up to a few days prior to this.
                         * @format date-time
                         * @default null
                         */
                        last_seen_in_id_pool_at?: string | null;
                        /** @format date-time */
                        created_at?: string;
                        /** @format date-time */
                        updated_at?: string;
                        /** The top products this user has allocated to recently */
                        recent_products?: Product[];
                        line_manager?: UserBase | null;
                        /** @format uuid */
                        line_manager_uuid?: string | null;
                        notification_preferences?: UserNotificationPreferences;
                    };
                    /** @example 37.5 */
                    contracted_hours?: number;
                    /** @example 5 */
                    contracted_days?: number;
                    /** A UUID in the v4 format */
                    day_rate_uuid?: UuidWO;
                    /**
                     * @format date
                     * @example "2021-11-17"
                     */
                    began_at?: string;
                    /** @format date */
                    ended_at?: string | null;
                    /** @format date-time */
                    created_at?: string;
                    /** @format date-time */
                    updated_at?: string;
                    day_rate?: {
                        /** A UUID in the v4 format */
                        uuid?: UuidRO;
                        /**
                         * @pattern \A[a-zA-Z0-9 \-\(\)\&\/]+\Z
                         * @example "Senior"
                         */
                        name: string;
                        activity_groups?: ActivityGroup[];
                        /** @format date-time */
                        created_at?: string;
                        /** @format date-time */
                        updated_at?: string;
                    } | null;
                };
                /**
                 * The total percentage allocated for the week
                 * @min 0
                 * @example 50
                 */
                allocation_percentage_sum: number;
                /** This week's allocations for the current user */
                allocations: Allocation[];
                /** List of all projects the current user can assign time against */
                products: Product[];
                /** The previous week's allocations for the current user */
                previous_week_allocations: Allocation[];
                /** The top products this user has allocated to recently */
                recent_products?: Product[];
            },
            any
        >({
            path: `/app/user/allocations`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags app
     * @name SetAllocations
     * @summary Store an allocation for the specified week (replaces existing)
     * @request POST:/app/user/allocations
     * @secure
     */
    setAllocations = (
        data: {
            /**
             * The week these allocations are for (defaults to the current week)
             * @format YYYY-WW
             */
            week: string;
            allocations: {
                /**
                 * The percentage of the week spent on this allocation
                 * @min 1
                 * @max 100
                 * @example 50
                 */
                percentage: number;
                /** A UUID in the v4 format */
                contract_uuid: Uuid;
                /** A UUID in the v4 format */
                product_uuid: Uuid;
            }[];
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/app/user/allocations`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description Update the notification preferences for the authenticated user.
     *
     * @tags app
     * @name PutNotificationPreferences
     * @summary Update user notification preferences
     * @request PUT:/app/user/notification-preferences
     * @secure
     */
    putNotificationPreferences = (data: UserNotificationPreferences, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/app/user/notification-preferences`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description Gathers the data required for viewing the Reporter dashboard.
     *
     * @tags app
     * @name AppReporterDashboard
     * @summary Data for reporter dashboard page
     * @request GET:/app/reporter/dashboard
     * @secure
     */
    appReporterDashboard = (
        query: {
            /**
             * Limit the result set to resources from on or after the given date
             * @format YYYY-MM-DD
             * @example "2022-11-01"
             */
            start_date: string;
            /**
             * Limit the result set to resources from on or before the given date
             * @format YYYY-MM-DD
             * @example "2022-11-30"
             */
            end_date: string;
            /** List of product UUIDs to be used to filter the result set */
            product_uuids?: string[];
            /** List of user UUIDs to be used to filter the result set */
            users?: string[];
            /**
             * Limit the result set to resources of the given week
             * @format YYYY-WW
             * @example "2022-45"
             */
            week?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<
            {
                /** All possible options for filtering the report */
                filters: {
                    /** List of products available to filter the report by */
                    products: FilterItem[];
                    /** List of day rates available to filter the report by */
                    day_rates?: FilterItem[];
                    /** List of users available to filter the report by */
                    users?: UserBase[];
                };
                report: {
                    products: {
                        /** A UUID in the v4 format */
                        uuid?: UuidRO;
                        /**
                         * The name of the product
                         * @pattern \A[a-zA-Z0-9 \-\(\)\&\/]+\Z
                         * @example "Annual Leave"
                         */
                        name?: string;
                        /**
                         * The short code to designate the product
                         * @pattern \A[a-zA-Z0-9\-]+\Z
                         * @example "rnd"
                         */
                        short_code?: string;
                        /** The product type (ie; identifying the department it belongs to) */
                        type?: ProductType;
                        /**
                         * Whether this product is actually an activity. Activities will not be included in utilisation metrics
                         * @example true
                         */
                        is_activity?: boolean;
                        /** @format date-time */
                        created_at?: string;
                        /** @format date-time */
                        updated_at?: string;
                        allocated_users?: {
                            user: UserBase;
                            day_rate: DayRate;
                            /** @min 0 */
                            cost: number;
                            /** @min 0 */
                            hours: number;
                        }[];
                    }[];
                };
            },
            any
        >({
            path: `/app/reporter/dashboard`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * @description Gathers the data required for viewing the Admin dashboard.
     *
     * @tags app
     * @name GetAdminDashboard
     * @summary Data for admin dashboard page
     * @request GET:/app/admin/dashboard
     * @secure
     */
    getAdminDashboard = (
        query: {
            /**
             * The year and month
             * @format YYYY-MM
             * @example "2022-04"
             */
            month: string;
            /** List of product UUIDs to be used to filter the result set */
            product_uuids?: string[];
            /**
             * The order in which to sort the products
             * @default "name"
             */
            sort?: 'name' | 'time_spent' | 'cost';
        },
        params: RequestParams = {}
    ) =>
        this.request<
            {
                /**
                 * The inclusive start date
                 * @format YYYY-MM-DD
                 * @example "2023-04-01T00:00:00.000Z"
                 */
                start_date: string;
                /**
                 * The inclusive end date
                 * @format YYYY-MM-DD
                 * @example "2023-04-30T00:00:00.000Z"
                 */
                end_date: string;
                /** Utilisation metrics for the products */
                products: {
                    product: Product;
                    /**
                     * Total time spent on the product (within the timeframe)
                     * @example 18.7
                     */
                    time_spent: number;
                    /**
                     * Total cost of the product (within the timeframe)
                     * @example 473.73
                     */
                    cost: number;
                }[];
                /** High level summary info for the dashboard */
                key_info?: {
                    /** The number of developers who allocated during the requested time interval */
                    total_users?: number;
                    /** How complete developer utilisations are during the requested time interval */
                    utilisation_percentage?: number;
                    /** The percentage of 'activity' allocations made during the requested time interval */
                    activity_percentage?: number;
                };
            },
            any
        >({
            path: `/app/admin/dashboard`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * @description Gather relevant data required for admins to administrate users.
     *
     * @tags app
     * @name GetAdminUsers
     * @summary Paginated list of users for admins
     * @request GET:/app/admin/users
     * @secure
     */
    getAdminUsers = (
        query?: {
            /**
             * Number of results to return (up to)
             * @min 1
             * @max 50
             * @default 10
             */
            limit?: number;
            /**
             * Last evaluated key of the current page used to get the next page - Base 64 Encoded.
             * @format paginationKey
             */
            cursor?: string;
            /** If given, only users with this role will be returned */
            role?: 'ALLOCATOR' | 'REPORTER' | 'ADMINISTRATOR' | 'LINE_MANAGER';
            /** If given, only users with a matching given/family name will be returned */
            name?: string;
            /**
             * If given, only users with this line manager will be returned
             * @format uuid
             */
            line_manager_uuid?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<UserWithContractAndDayRate[], any>({
            path: `/app/admin/users`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * @description Gather relevant data required for admins to administrate user.
     *
     * @tags app
     * @name GetAdminUserModel
     * @summary Retrieve a user for admins
     * @request GET:/app/admin/users/{model_uuid}
     * @secure
     */
    getAdminUserModel = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<UserWithContractAndDayRate, any>({
            path: `/app/admin/users/${modelUuid}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * @description Get information on users who have incomplete allocations on a given week
     *
     * @tags app
     * @name GetAdminIncompleteAllocations
     * @summary Incomplete allocations list
     * @request GET:/app/admin/incomplete-allocations
     * @secure
     */
    getAdminIncompleteAllocations = (
        query?: {
            /**
             * Last evaluated key of the current page used to get the next page - Base 64 Encoded.
             * @format paginationKey
             */
            cursor?: string;
            /**
             * Number of results to return (up to)
             * @min 1
             * @max 50
             * @default 10
             */
            limit?: number;
            /**
             * Limit the result set to resources of the given week
             * @format YYYY-WW
             * @example "2022-45"
             */
            week?: string;
            /**
             * If given, only users with this line manager will be returned
             * @format uuid
             */
            line_manager_uuid?: string;
            /** If true, only users whose allocations for the week total less than 100% will be included */
            only_incomplete?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.request<UserWithAllocationsAndSummaryForWeek[], any>({
            path: `/app/admin/incomplete-allocations`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags app
     * @name SetAllocations2
     * @summary Store an allocation for the specified user and week (replaces existing)
     * @request POST:/app/admin/allocations
     * @originalName setAllocations
     * @duplicate
     * @secure
     */
    setAllocations2 = (
        data: {
            /**
             * The week these allocations are for (defaults to the current week)
             * @format YYYY-WW
             */
            week: string;
            /** A UUID in the v4 format */
            user_uuid: Uuid;
            allocations: {
                /**
                 * The percentage of the week spent on this allocation
                 * @min 1
                 * @max 100
                 * @example 50
                 */
                percentage: number;
                /** A UUID in the v4 format */
                contract_uuid: Uuid;
                /** A UUID in the v4 format */
                product_uuid: Uuid;
                /**
                 * An optional activity name for this allocation. Any member of the activity groups of the user's day rate at the time of allocating.
                 * @example null
                 */
                activity?: string | null;
            }[];
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/app/admin/allocations`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags app
     * @name AdminPostNotification
     * @summary Create a new notification to be sent now or later
     * @request POST:/app/admin/notifications
     * @secure
     */
    adminPostNotification = (
        data: {
            subject: NotificationSubject;
            body: NotificationBody;
        },
        params: RequestParams = {}
    ) =>
        this.request<Notification, any>({
            path: `/app/admin/notifications`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags app
     * @name AdminDeleteNotification
     * @summary Delete an existing unsent notification
     * @request DELETE:/app/admin/notifications/{model_uuid}
     * @secure
     */
    adminDeleteNotification = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<any, any>({
            path: `/app/admin/notifications/${modelUuid}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags app
     * @name AppReportAllocations
     * @summary Download report of user allocations
     * @request GET:/app/report/allocations
     * @secure
     */
    appReportAllocations = (
        query: {
            /**
             * Limit the result set to resources from on or after the given date
             * @format YYYY-MM-DD
             * @example "2022-11-01"
             */
            start_date: string;
            /**
             * Limit the result set to resources from on or before the given date
             * @format YYYY-MM-DD
             * @example "2022-11-30"
             */
            end_date: string;
            /** List of product UUIDs to be used to filter the result set */
            product_uuids?: string[];
        },
        params: RequestParams = {}
    ) =>
        this.request<Uuid, any>({
            path: `/app/report/allocations`,
            method: 'GET',
            query: query,
            secure: true,
            ...params
        });
}
