import { Controller, useFormContext } from 'react-hook-form';
import Input from 'components/form/elements/input-element';

const CostComponent = ({ placeholder, readOnly, type }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();

    return (
        <div>
            <Controller
                name="cost"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <Input
                        {...field}
                        label="Cost"
                        name="cost"
                        error={errors.cost}
                        disabled={readOnly}
                        placeholder={placeholder}
                        type={type}
                    />
                )}
            />
        </div>
    );
};

export default CostComponent;
