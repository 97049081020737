import { ComponentType, MouseEventHandler, useCallback, useMemo } from 'react';

import { CalendarItemProps } from './calendar-item';

interface DayProps {
    activeDates: [Date | undefined, Date | undefined];
    calendarItemComponent: ComponentType<CalendarItemProps>;
    constrain: 'future' | 'past' | 'unrestricted';
    day: number;
    month: number;
    onSelect: (date: Date) => void;
    year: number;
}

function Day({
    activeDates,
    calendarItemComponent: CalendarItemComponent,
    constrain,
    day,
    month,
    onSelect,
    year,
    ...restProps
}: DayProps) {
    const date = useMemo(() => new Date(year, month, day), [day, month, year]);

    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.stopPropagation();
            onSelect(new Date(year, month, day));
        },
        [day, month, onSelect, year]
    );

    const today = useMemo(() => {
        const result = new Date();
        result.setHours(0);
        result.setMinutes(0);
        result.setSeconds(0);
        result.setMilliseconds(0);
        return result;
    }, []);

    const active = activeDates.some(
        (item) => item?.getTime() === date.getTime()
    );

    // FIXME: this only works for day... move inside day/month/year component.
    const highlight: CalendarItemProps['highlight'] =
        !!activeDates && !!activeDates[0] && !!activeDates[1]
            ? activeDates[1].getTime() - activeDates[0].getTime() < 86400000
                ? undefined
                : date.getTime() === activeDates[0].getTime()
                ? 'right'
                : date.getTime() === activeDates[1].getTime()
                ? 'left'
                : activeDates[0] < date && date < activeDates[1]
                ? 'full'
                : undefined
            : undefined;

    return (
        <CalendarItemComponent
            {...restProps}
            className="w-[38px] h-[31px]"
            date={date}
            today={today}
            constrain={constrain}
            onClick={handleClick}
            active={active}
            highlight={highlight}
        >
            {day}
        </CalendarItemComponent>
    );
}

export default Day;
export type { DayProps };
