import {
    QueryFunction,
    UseQueryOptions,
    useQuery as useReactQuery
} from 'react-query';
import { QueryKey, QueryType } from '../hooks/queryKey';

type QueryKeyType =
    | [QueryKey, QueryType]
    | [QueryKey, QueryType, Record<string, unknown> | undefined];

function useQuery<TData>(
    queryKey: QueryKeyType,
    queryFn: QueryFunction<TData, QueryKeyType>,
    options?: Omit<
        UseQueryOptions<TData, unknown, TData, QueryKeyType>,
        'queryKey' | 'queryFn'
        >
) {
    return useReactQuery(queryKey, queryFn, options);
}

export default useQuery;
