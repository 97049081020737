import { MouseEventHandler, PropsWithChildren } from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import classNames from 'classnames';

interface HeaderButtonProps extends TailwindProps {
    onClick?: MouseEventHandler<HTMLDivElement>;
    variant?: 'primary' | 'default';
}

function HeaderButton({
    children,
    className,
    onClick,
    tailwindStyle,
    variant = 'default',
    ...restProps
}: PropsWithChildren<HeaderButtonProps>) {
    const cssClasses = classNames(
        className,
        tailwindClasses(
            {
                cursor: 'cursor-pointer',
                display: 'flex',
                gap: 'gap-2.5',
                height: 'h-14',
                items: 'items-center',
                justify: 'justify-center',
                padding: 'px-0',
                position: 'relative',
                textColor:
                    variant === 'default' ? 'text-primary' : 'text-white',
                transition: 'transition-all duration-300',
                variant: {
                    before: {
                        border:
                            variant === 'default'
                                ? 'before:rounded-xl'
                                : 'before:rounded-full',
                        bottom: 'before:-bottom-px',
                        left: 'before:-left-px',
                        opacity: 'before:opacity-0',
                        position: 'before:absolute',
                        right: 'before:-right-px',
                        top: 'before:-top-px',
                        transition:
                            'before:transition-opacity before:duration-300',
                        zIndex: 'before:-z-1'
                    },
                    hover: {
                        textColor: 'hover:text-utilisation-accent-blue',
                        variant: {
                            before: {
                                opacity: 'hover:before:opacity-100'
                            }
                        }
                    }
                },
                width: variant === 'default' ? 'w-14' : undefined,
                zIndex: 'z-1'
            },
            tailwindStyle
        )
    );

    return (
        <div className={cssClasses} onClick={onClick} {...restProps}>
            {children}
        </div>
    );
}

export default HeaderButton;
export type { HeaderButtonProps };
