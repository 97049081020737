import TeamsModal from './teams-modal';

function TeamsEditModal({ data, refetch }) {
    const allocations = data.allocations;
    const previous_week_allocations = data.previous_week_allocations;
    const summary = data.summary;

    const config = {
        allocations,
        previous_week_allocations,
        refetch,
        summary
    };

    return <TeamsModal config={config}></TeamsModal>;
}

export default TeamsEditModal;
