import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import ActivitySelector from './activity-selector';
import Button from 'components/button/button';
import Select from 'react-select';
import allocationPercentages from 'common/allocation-percentages';
import classNames from 'classnames';
import productColours from 'common/allocation-product-colours';

const AllocationFormSelection = ({
    activity,
    activityGroups,
    control,
    id,
    index,
    percentage,
    product,
    productsArray,
    remove
}) => {
    const [productObject, setProductObject] = useState(product);
    const { register, setValue } = useFormContext();

    const layoutClasses = classNames('grid gap-2', 'grid-cols-4');

    return (
        <li>
            <div className={layoutClasses}>
                <Controller
                    name={`allocations[${index}].product_uuid`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="relative">
                            <span
                                style={{
                                    background: productColours[index],
                                    borderRadius: '100%',
                                    height: '12px',
                                    left: 0,
                                    position: 'absolute',
                                    top: 'calc(50% - 6px)',
                                    width: '12px'
                                }}
                            ></span>
                            <Select
                                defaultValue={{
                                    is_activity: productObject
                                        ? productObject.is_activity
                                        : false,
                                    label: productObject
                                        ? productObject.name
                                        : 'Please Select',
                                    value: productObject
                                        ? productObject.uuid
                                        : ''
                                }}
                                className="ml-ncc-margin-24"
                                key={id}
                                {...register(
                                    `allocations[${index}].product_uuid`
                                )}
                                options={productsArray}
                                onChange={(selected, item) => {
                                    setProductObject(selected);

                                    setValue(
                                        `allocations[${index}].product_uuid`,
                                        selected?.value,
                                        {
                                            shouldDirty: true,
                                            shouldValidate: true
                                        }
                                    );
                                }}
                            />
                        </div>
                    )}
                />

                <ActivitySelector
                    index={index}
                    product={productObject}
                    activityGroups={activityGroups}
                    id={id}
                    activity={activity}
                ></ActivitySelector>

                <div className="w-[150px]">
                    <Controller
                        name={`allocations[${index}].percentage`}
                        control={control}
                        render={({ field: { value } }) => (
                            <>
                                <Select
                                    defaultValue={{
                                        label:
                                            percentage &&
                                            typeof percentage !== 'object'
                                                ? `${percentage}%`
                                                : 0,
                                        value:
                                            percentage &&
                                            typeof percentage !== 'object'
                                                ? percentage
                                                : '0'
                                    }}
                                    key={id} // important to include key with field's id
                                    {...register(
                                        `allocations[${index}].percentage`
                                    )}
                                    options={allocationPercentages}
                                    onChange={(selected, item) => {
                                        setValue(
                                            `allocations[${index}].percentage`,
                                            selected?.value,
                                            {
                                                shouldDirty: true,
                                                shouldValidate: true
                                            }
                                        );
                                    }}
                                />
                            </>
                        )}
                    />
                </div>

                <Button
                    variant="tertiary"
                    type="button"
                    className="ml-auto"
                    onClick={() => remove(index)}
                >
                    <FontAwesomeIcon icon={faTrash} /> Remove
                </Button>
            </div>
        </li>
    );
};

export default AllocationFormSelection;
