import { ComponentType, useMemo } from 'react';

import { CalendarItemProps } from './calendar-item';
import CalendarGrid from './calendar-grid';
import Day, { DayProps } from './day';

interface DayPickerProps {
    activeDates: [Date | undefined, Date | undefined];
    calendarItemComponent: ComponentType<CalendarItemProps>;
    constrain: 'future' | 'past' | 'unrestricted';
    locale: string;
    onSelect: DayProps['onSelect'];
    value: Date;
}

function DayPicker({
    activeDates,
    calendarItemComponent: CalendarItemComponent,
    constrain,
    locale,
    onSelect,
    value
}: DayPickerProps) {
    const weekDays = useMemo<string[]>(() => {
        const result: string[] = [];

        // It was a Monday
        const weekStart = new Date(1970, 1, 2);
        for (let day = 0; day < 7; day++) {
            result.push(weekStart.toLocaleString(locale, { weekday: 'short' }));
            weekStart.setDate(weekStart.getDate() + 1);
        }

        return result;
    }, [locale]);

    const days = useMemo<Pick<DayProps, 'day' | 'month' | 'year'>[]>(() => {
        let result: Pick<DayProps, 'day' | 'month' | 'year'>[] = [];

        const year = value.getFullYear();
        const month = value.getMonth();
        const day = new Date(year, month);

        // Shift week so it starts on Monday instead of Sunday
        const firstDayOfMonth = (day.getDay() || 7) - 1;

        // Add last months days from Monday till the first day of the month.
        // Last day of the month previous to "month"
        const previousMonthDay = new Date(year, month, 0);
        for (let i = 0; i < firstDayOfMonth; i++) {
            result = [
                {
                    day: previousMonthDay.getDate(),
                    month: previousMonthDay.getMonth(),
                    year: previousMonthDay.getFullYear()
                },
                ...result
            ];
            previousMonthDay.setDate(previousMonthDay.getDate() - 1);
        }

        // Add current and next month days.
        while (day.getMonth() === month || result.length % 7 !== 0) {
            result = [
                ...result,
                {
                    day: day.getDate(),
                    month: day.getMonth(),
                    year: day.getFullYear()
                }
            ];
            day.setDate(day.getDate() + 1);
        }

        return result;
    }, [value]);

    return (
        <CalendarGrid className="grid-cols-7">
            <div className="col-span-7 border-t-[1px] border-ncc-grey-30 border-b-[1px] flex gap-[19px] py-2">
                {weekDays.map((day) => (
                    <div
                        key={day}
                        className="cursor-default select-none text-center"
                    >
                        {day}
                    </div>
                ))}
            </div>

            {days.map((dayProps) => (
                <Day
                    key={`${dayProps.year}-${dayProps.month}-${dayProps.day}`}
                    {...dayProps}
                    onSelect={onSelect}
                    constrain={constrain}
                    activeDates={activeDates}
                    calendarItemComponent={CalendarItemComponent}
                />
            ))}
        </CalendarGrid>
    );
}

export default DayPicker;
export type { DayPickerProps };
