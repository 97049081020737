import { memo } from 'react';

function UsersTableStatusCellRenderer(props) {
    if (props.data[props.cell.row.id].deactivated_at === null) {
        return (
            <span className="inline-flex items-center rounded-full bg-utilisation-background-success text-utilisation-success px-2.5 py-0.5 text-xs font-medium">
                Active
            </span>
        );
    } else {
        return (
            <span className="inline-flex items-center rounded-full bg-utilisation-background-disabled text-utilisation-light-blue px-2.5 py-0.5 text-xs font-medium">
                Account disabled
            </span>
        );
    }
}

export default memo(UsersTableStatusCellRenderer);
