import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import ProductReportsTable from 'ui/admin/product-reports/product-reports-table';
import TotalHoursAndCost from './total-hours-and-cost';
import classNames from 'classnames';

interface ProductReportsWidgetProps extends CommonProps {
    index;
    onHandleDeleteReportItem;
    report;
}

function ProductReportWidget({
    className,
    index,
    onHandleDeleteReportItem,
    report
}: PropsWithChildren<ProductReportsWidgetProps>) {
    const [openWidget, setOpenWidget] = useState(false);

    const onHandleWidgetState = () => {
        setOpenWidget(true !== openWidget);
    };

    const cssClasses = classNames(className, 'flex-col p-[24px] mt-[20px]');

    return (
        <div className={cssClasses}>
            <div className="flex flex-row items-center">
                <FontAwesomeIcon
                    onClick={onHandleWidgetState}
                    icon={openWidget ? faChevronDown : faChevronRight}
                    className="mr-2 cursor-pointer"
                ></FontAwesomeIcon>
                <h1>{report.name}</h1>
                <div className="ml-auto flex items-center gap-2.5">
                    {!openWidget && (
                        <TotalHoursAndCost report={report}></TotalHoursAndCost>
                    )}
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="cursor-pointer text-ncc-grey-70"
                        onClick={() => onHandleDeleteReportItem(index)}
                    ></FontAwesomeIcon>
                </div>
            </div>

            {openWidget && (
                <>
                    <ProductReportsTable report={report}></ProductReportsTable>
                    <TotalHoursAndCost report={report}></TotalHoursAndCost>
                </>
            )}
        </div>
    );
}

export default ProductReportWidget;
