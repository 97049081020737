import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-solid-svg-icons';

const DataTablePagination = ({
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageCount,
    pageIndex,
    pageOptions,
    previousPage
}) => {
    return (
        <div className="pagination mt-ncc-margin-12 justify-center flex items-center gap-1 border-t border-b border-solid border-top-ncc-grey-30 py-ncc-padding-12">
            <button
                className="bg-white rounded-lg  text-ncc-grey-80 cursor-pointer w-[32px] h-[32px] border border-solid border-ncc-grey-30"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
            >
                {<FontAwesomeIcon icon={faChevronDoubleLeft} />}
            </button>{' '}
            <button
                className="bg-white rounded-lg  text-ncc-grey-80 cursor-pointer w-[32px] h-[32px] border border-solid border-ncc-grey-30"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
            >
                {<FontAwesomeIcon icon={faChevronLeft} />}
            </button>{' '}
            <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="bg-white rounded-lg  text-ncc-grey-80 cursor-pointer w-[32px] h-[32px] border border-solid border-ncc-grey-30"
            >
                {<FontAwesomeIcon icon={faChevronRight} />}
            </button>{' '}
            <button
                className="bg-white rounded-lg  text-ncc-grey-80 cursor-pointer w-[32px] h-[32px] border border-solid border-ncc-grey-30"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
            >
                {<FontAwesomeIcon icon={faChevronDoubleRight} />}
            </button>{' '}
            <span className=" text-ncc-grey-70 mx-ncc-margin-4 text-[12px]">
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </span>
        </div>
    );
};

export default DataTablePagination;
