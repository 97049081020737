import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';

const DateSelect = ({ label, name }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <label className="text-ncc-form-label text-utilisation-light-blue">
                    {label}
                    <DatePicker
                        className="mt-2 bg-white rounded-widget text-base py-4 border-solid border-[1px] border-utilisation-border-color-main relative dark:bg-ncc-portal-dark dark:text-white dark:border-ncc-portal-grey-100"
                        showMonthYearPicker
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        showIcon={true}
                    />
                </label>
            )}
        />
    );
};

export default DateSelect;
