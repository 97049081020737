import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

const ActionsCellRender = ({ data }) => {
    const { uuid } = data;

    return (
        <FontAwesomeIcon
            cursor="pointer"
            icon={faEllipsisH}
            onClick={() => console.log('Go to uuid:', uuid)}
        />
    );
};

export default ActionsCellRender;
