import FullscreenSpinner from '../fullscreen-spinner';
import React from 'react';

type LoaderType = 'instant' | 'fast' | 'medium';

interface PageLoaderProps {
    suppressBackground?: boolean;
    text: string;
    type: LoaderType;
}

function PageLoader({ suppressBackground, text, type }: PageLoaderProps) {
    switch (type) {
        case 'fast':
            return (
                <div className="flex flex-col gap-5">
                    <FullscreenSpinner
                        suppressBackground={suppressBackground}
                    />
                    <h1>{text}</h1>
                </div>
            );
        default:
            return null;
    }
}

export default PageLoader;
