import { FunctionComponent, useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import ActivityGroupItem from './activity-group-item';

import ActivityGroupsCrudForm from './activity-groups-crud-form';
import AddActivityGroupButton from './add-activity-group-button';
import AdminPageHeader from 'components/page-headers/admin-page-header';
import Alert from 'components/alert/alert';
import ModalContainer from 'components/modal/modal-container';
import PageLoader from 'components/loaders/page-loader';
import SearchBar from 'components/search-bar/search-bar';
import _ from 'lodash';
import useDayRates from 'core/api/hooks/cost-levels/use-day-rates';

interface ActivityGroupsGridProps {
    data;
    error;
    loading;
    setSearchParam;
}

const ActivityGroupsGrid: FunctionComponent<ActivityGroupsGridProps> = ({
    data: activityGroups,
    error,
    loading,
    setSearchParam
}) => {
    const { push } = useModals();
    const { data: dayRates } = useDayRates();

    const crudAction = useCallback(
        (group, action) => {
            return push(ModalContainer, {
                content: ActivityGroupsCrudForm,
                crudAction: action,
                data: group ? group : null,
                miscData: dayRates,
                title: `${action} activity group`
            });
        },
        [dayRates, push]
    );

    const onHandleSearch = (e) => {
        _.debounce(() => setSearchParam(e.target.value), 1000)();
    };

    if (error) {
        return <h2 className="error">{error}</h2>;
    }
    return (
        <div className="grid gap-6">
            <AdminPageHeader pageTitle="Activity Groups">
                <AddActivityGroupButton
                    disabled={false}
                    addNewActivityGroup={() => crudAction(null, 'create')}
                ></AddActivityGroupButton>
            </AdminPageHeader>

            <div className=" p-[24px]">
                <SearchBar
                    data-testid="input"
                    className="border border-solid rounded-md"
                    placeholder="Search on activity group name..."
                    onChange={onHandleSearch}
                    disabled={loading}
                />
            </div>
            <div>
                {loading ? (
                    <div className="flex items-center justify-center h-full">
                        <PageLoader
                            type="fast"
                            text={'Loading activity groups...'}
                        ></PageLoader>
                    </div>
                ) : activityGroups && activityGroups.length !== 0 ? (
                    <div className="grid grid-cols-3 gap-6 p-[24px]">
                        {activityGroups.map((activityGroup) => (
                            <ActivityGroupItem
                                deleteModal={() =>
                                    crudAction(activityGroup, 'delete')
                                }
                                editModal={() =>
                                    crudAction(activityGroup, 'update')
                                }
                                key={activityGroup.name}
                                activityGroup={activityGroup}
                            ></ActivityGroupItem>
                        ))}
                    </div>
                ) : (
                    <Alert
                        variant="warning"
                        className=" w-full rounded-t-[8px] mb-ncc-margin-12"
                    >
                        No activity groups to display
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default ActivityGroupsGrid;
