import { FormEventHandler, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Alert from 'components/alert/alert';
import AllocationFormSelection from './allocation-form-selection';
import Button from 'components/button/button';

type AllocatorFormProps = {
    activityGroups;
    allocationPercentages;
    allocations;
    append;
    control;
    copyLastWeeksAllocations;
    fields;
    productsArray;
    remove;
    replace;
};

const AllocatorForm = ({
    activityGroups,
    allocations,
    append,
    control,
    copyLastWeeksAllocations,
    fields,
    productsArray,
    remove,
    replace,
    trigger
}) => {
    const preventFormDefault = useCallback<FormEventHandler<HTMLFormElement>>(
        (event) => {
            event.preventDefault();
        },
        []
    );

    const { formState } = useFormContext();

    return (
        <form className="grid grid-cols-1 gap-6" onSubmit={preventFormDefault}>
            {fields.length > 0 ? (
                <ul>
                    {fields.map(
                        ({ activity, id, percentage, product }, index) => {
                            return (
                                <AllocationFormSelection
                                    key={id}
                                    control={control}
                                    id={id}
                                    index={index}
                                    activityGroups={activityGroups}
                                    activity={activity}
                                    percentage={percentage}
                                    product={product}
                                    productsArray={productsArray}
                                    remove={remove}
                                ></AllocationFormSelection>
                            );
                        }
                    )}
                </ul>
            ) : (
                <Alert variant="warning" className="w-full rounded-t-[8px]">
                    No allocations have been assigned!
                </Alert>
            )}

            <section>
                <Button
                    variant="secondary-special"
                    type="button"
                    className="w-full"
                    onClick={() => {
                        append({
                            product: null
                        });
                    }}
                >
                    + New Allocation
                </Button>
            </section>

            <div className="flex gap-6">
                <Button
                    variant="secondary"
                    onClick={(e) => copyLastWeeksAllocations(e)}
                >
                    Copy Last Weeks
                </Button>
                <div className="flex ml-auto">
                    <Button
                        variant="tertiary"
                        onClick={() => replace(allocations)}
                    >
                        Reset
                    </Button>
                    <Button
                        variant="primary"
                        onClick={trigger}
                        disabled={!formState.isValid}
                        data-testid="button-submit"
                    >
                        Submit
                    </Button>
                    {/* <input type="submit" /> */}
                </div>
            </div>
        </form>
    );
};

export default AllocatorForm;
export type { AllocatorFormProps };
