import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Drop({ action }) {
    const userObject = JSON.parse(localStorage.getItem('user') || '');

    if (!userObject) return null;

    const getUserInitials = () => {
        const { display_name, family_name, given_name } = userObject.user;

        if (given_name === null || family_name === null) {
            const initialsFromArray = display_name
                .split(' ')
                .map((word) => word[0])
                .join('');
            return initialsFromArray;
        } else {
            const firstInitial = given_name?.charAt(0) || '';
            const secondInitial = family_name?.charAt(0) || '';

            return `${firstInitial}${secondInitial}`;
        }
    };

    const userInitials = getUserInitials();

    return (
        <Menu
            as="div"
            className="relative inline-block text-left flex items-center"
        >
            <>
                <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <div className="bg-[#A3367C] text-white rounded-full text-sm p-2 flex">
                        {userInitials}
                    </div>
                </Menu.Button>
            </>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-20 top-12 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <span
                                    onClick={() => {
                                        action();
                                    }}
                                    className={classNames(
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer'
                                    )}
                                >
                                    Logout
                                </span>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
