/**
 * Converts a string date to a string using the current or specified locale.
 * @param locale A locale string. If you omit this parameter, the default locale of the JavaScript runtime is used.
 */

import moment from "moment";

function dateFormatter(
    value: string | Date | null | undefined,
        format?: string,
    locale?: string,
) {
    // TODO use moment + getDatePattern to format dates.
    if (!value) return '';

    if (typeof value === 'object') return moment(value).format(format);
    

    return new Date(value).toLocaleDateString(locale);
}

export default dateFormatter;
