import { AppController } from 'core/api/autogenerated/App';
import { UseQueryOptions, useQuery } from 'react-query';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

interface GetReportsResponse {
    filters?;
    report?;
}

function useProductReports<TError = unknown>(
    enabled: boolean,
    query: {
        end_date: string;
        product_uuids?: string[] | undefined;
        start_date: string;
        users?: string[];
        week?: string;
    },
    options?: Omit<
        UseQueryOptions<
            GetReportsResponse | undefined,
            ReactQueryError<TError>,
            GetReportsResponse | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const obj = {
        enabled: enabled,
        options
    };

    return useQuery(
        ['product-reports', 'list', { query }],

        async () => {
            const controller = new AppController({
                interceptors,
                securityWorker
            });

            // We don't want to catch any errors here since it will broke react query "error" status
            const response = await controller.appReporterDashboard(query);
            return response.data;
        },
        obj
    );
}

export default useProductReports;
