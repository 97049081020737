import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';
import { ModalBackdrop } from 'components';
import { ModalsRoot } from '@ncc-frontend/core';
import { UserContextProvider } from 'ui/user/user-context';
import AppLayout from 'app-layout/app-layout';
import MsadAuthProvider from './authLib/MsadAuthProvider';

import './fonts/Inter-Medium.ttf';
import './fonts/Inter-Regular.ttf';
import './fonts/Inter-SemiBold.ttf';

import './App.css';
import { ThemeProvider } from 'components/gradient-background/theme-context';
import ErrorBoundary from 'components/ErrorBoundary/error-boundary';

function App() {
    return (
        <ErrorBoundary>
            <ThemeProvider>
                <MsadAuthProvider
                    authenticationUrl={`${process.env.REACT_APP_OIDC_CALLBACK_PATH}`}
                    msdaUrl={`${process.env.REACT_APP_AUTHORITY}/oauth2/authorize?response_type=${process.env.REACT_APP_AUTHORITY_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_OIDC_CALLBACK_PATH}&scope=${process.env.REACT_APP_OAUTH_SCOPES}`}
                >
                    <CookiesProvider>
                        <ModalsRoot backdropComponent={ModalBackdrop}>
                            <UserContextProvider>
                                <AppLayout />
                            </UserContextProvider>
                        </ModalsRoot>
                    </CookiesProvider>
                </MsadAuthProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}
export default App;
