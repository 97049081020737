import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from "react";

const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
        ];

function weekToDate(year, week) {
    
        const days = 4 + 7 * (week - 1);
        const date = new Date(year, 0, days);

        const dayDate = date.getDate();
        const month = date.getMonth();

        return `${dayDate} - ${dayDate + 7} ${
            months[month]
        } ${date.getFullYear()}`;
}

const useStateWithCallback = <T>(initialState: T): [state: T, setState: (updatedState: React.SetStateAction<T>, callback?: (updatedState: T) => void) => void] => {
    const [state, setState] = useState<T>(initialState);
    const callbackRef = useRef<(updated: T) => void>();

    const handleSetState = (updatedState: React.SetStateAction<T>, callback?: (updatedState: T) => void) => {
        callbackRef.current = callback;
        setState(updatedState);
    };

    useEffect(() => {
        if (typeof callbackRef.current === "function") {
            callbackRef.current(state);
            callbackRef.current = undefined;
        }
    }, [state]);

    return [state, handleSetState];
}

const stringReplace = (str) => {
        const s = str?.slice(-2);
        return s;
};

const toastMessage = (message: string, status: string) => {
    return toast[status](message)
};

function returnFormattedDayRates(data) {
    return data?.map((dataItem) => {
        return {
            label: dataItem.name,
            value: dataItem.uuid
        };
    });

}

function returnActivityGroups(data) {
    return data?.map((group) => ({
        label: group.name,
        options: group.activities.map((act) => ({
            label: `${act.charAt(0).toUpperCase()}${act.slice(1)}`,
            value: act
        }))
    }));
}

function getDefaultRouteForUser(user) {
    // Try and set the default route in a sensible order dependent on the roles
    // the user possesses.  This will hopefully redirect to the most immediately
    // useful page for most cases
    //
    const roles = user?.roles ?? [];

    if (roles.includes('ALLOCATOR')) return '/allocations/personal';
    if (roles.includes('LINE_MANAGER')) return '/allocations/team';
    if (roles.includes('ADMINISTRATOR')) return '/admin';
    if (roles.includes('REPORTER')) return '/product-reports';

    // Catch-all
    return '/allocations/personal';
}

export {
    getDefaultRouteForUser,
    months,
    returnFormattedDayRates,
    stringReplace,
    toastMessage,
    useStateWithCallback,
    weekToDate,
    returnActivityGroups
};