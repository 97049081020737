import { ReactElement } from 'react';
import AdminPageContainer from '../admin-page-container';
import CostLevelsTable from 'ui/admin/cost-levels/cost-levels-table';
import useDayRates from 'core/api/hooks/cost-levels/use-day-rates';

interface CostLevelsPageProps {}

function CostLevelsPage({ collapsed }): ReactElement {
    const { data, isFetching, isLoading, refetch } = useDayRates();

    return (
        <AdminPageContainer>
            <CostLevelsTable
                data={data}
                refetch={refetch}
                loading={isLoading || isFetching}
            ></CostLevelsTable>
        </AdminPageContainer>
    );
}

export default CostLevelsPage;
export type { CostLevelsPageProps };
