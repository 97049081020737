import {
    ButtonGroup as BaseButtonGroup,
    ButtonGroupProps
} from '@ncc-frontend/core';
import { ComponentType, PropsWithChildren, memo } from 'react';
import { ForwardedComponent } from 'common/types';
import ButtonGroupButton, {
    ButtonGroupButtonProps
} from './button-group-button';

type ButtonGroupType = ComponentType<PropsWithChildren<ButtonGroupProps>> & {
    Button: ForwardedComponent<ButtonGroupButtonProps, HTMLButtonElement>;
};

const ButtonGroup = memo(BaseButtonGroup) as unknown as ButtonGroupType;
ButtonGroup.Button = ButtonGroupButton as ForwardedComponent<
    ButtonGroupButtonProps,
    HTMLButtonElement
>;

export default ButtonGroup;
export type { ButtonGroupButtonProps, ButtonGroupProps, ButtonGroupType };
