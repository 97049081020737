import {
    Button as CoreButton,
    ButtonProps as CoreButtonProps,
    TailwindStyle
} from '@ncc-frontend/core';
import {
    ForwardedRef,
    PropsWithChildren,
    forwardRef,
    memo,
    useMemo
} from 'react';
import { merge } from 'lodash';

interface ButtonProps extends Omit<CoreButtonProps, 'variant'> {
    variant?:
        | 'primary'
        | 'secondary'
        | 'secondary-special'
        | 'tertiary'
        | 'danger'
        | 'warning';
}

function Button(
    {
        children,
        disabled,
        tailwindStyle,
        variant = 'primary',
        ...restProps
    }: PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const mergedTailwindStyle = useMemo(() => {
        const baseStyle: TailwindStyle = {
            border: '',
            borderRadius: 'rounded-lg',
            textWeight: 'font-medium',
            truncate: ''
        };

        const variantStyle: TailwindStyle = {};
        switch (variant) {
            case 'primary':
                variantStyle.height = 'h-ncc-btn-height';
                variantStyle.background = 'bg-ncc-group-purple-100';
                variantStyle.border = 'border';
                variantStyle.borderColor = 'border-ncc-group-purple-100';
                variantStyle.textColor = 'text-white';
                variantStyle.textSize = 'text-p';
                variantStyle.textWeight = 'font-Medium';
                variantStyle.variant = {
                    active: {
                        background: 'active:bg-ncc-group-purple-110',
                        borderColor: 'active:border-ncc-group-purple-110',
                        textColor: 'active:text-white'
                    },
                    disabled: {
                        background: 'disabled:bg-ncc-grey-20',
                        borderColor: 'disabled:border-ncc-grey-20',
                        textColor: 'disabled:text-ncc-grey-70'
                    },
                    hover: {
                        background: 'ncc-group-purple-110',
                        borderColor: 'hover:border-ncc-group-purple-110',
                        textColor: 'hover:text-white'
                    }
                };

                break;
            case 'secondary':
                variantStyle.height = 'h-ncc-btn-height';
                variantStyle.background = 'bg-ncc-white';
                variantStyle.border = 'border';
                variantStyle.borderColor = 'border-ncc-grey-40';
                variantStyle.textColor = 'text-ncc-grey-90';
                variantStyle.textSize = 'text-p';
                variantStyle.textWeight = 'font-Medium';
                variantStyle.variant = {
                    active: {
                        borderColor: 'active:border-ncc-grey-30',
                        textColor: 'active:text-ncc-grey-70'
                    },
                    disabled: {
                        background: 'disabled:bg-ncc-grey-20',
                        borderColor: 'disabled:border-ncc-grey-20',
                        textColor: 'disabled:text-ncc-grey-70'
                    },
                    hover: {
                        borderColor: 'hover:border-ncc-grey-50',
                        textColor: 'hover:text-ncc-grey-100'
                    }
                };

                break;
            case 'secondary-special':
                variantStyle.height = 'h-ncc-btn-height';
                variantStyle.background = 'bg-ncc-white';
                variantStyle.border = 'border';
                variantStyle.borderColor = 'border-ncc-grey-40';
                variantStyle.textColor = 'text-ncc-portal-blue';
                variantStyle.textSize = 'text-p';
                variantStyle.textWeight = 'font-Medium';
                variantStyle.variant = {
                    active: {
                        borderColor: 'active:border-ncc-grey-20',
                        textColor: 'active:text-ncc-portal-blue'
                    },
                    disabled: {
                        background: 'disabled:bg-ncc-grey-20',
                        borderColor: 'disabled:border-ncc-grey-20',
                        textColor: 'disabled:text-ncc-grey-60'
                    },
                    hover: {
                        borderColor: 'hover:border-ncc-grey-20',
                        textColor: 'hover:text-ncc-group-purple-90'
                    }
                };

                break;
            case 'tertiary':
                variantStyle.height = 'h-ncc-btn-height';
                variantStyle.background = 'bg-transparent';
                variantStyle.textColor = 'text-ncc-grey-90';
                variantStyle.variant = {
                    active: {
                        background: 'active:bg-transparent',
                        textColor: 'active:text-ncc-grey-70'
                    },
                    disabled: {
                        background: 'disabled:bg-transparent',
                        textColor: 'disabled:text-ncc-grey-50'
                    },
                    hover: {
                        background: 'hover:bg-transparent',
                        textColor: 'hover:text-ncc-grey-50'
                    }
                };
                break;

            case 'warning':
                variantStyle.background = 'bg-utilisation-yellow-20';
                variantStyle.textColor = 'text-utilisation-yellow-150';
                variantStyle.variant = {
                    active: {
                        background: 'active:bg-utilisation-yellow-10',
                        textColor: 'active:!text-utilisation-yellow-100'
                    },
                    disabled: {
                        background: 'disabled:bg-ncc-grey-20',
                        textColor: 'disabled:!text-ncc-grey-50'
                    },
                    hover: {
                        background: 'hover:bg-utilisation-yellow-100',
                        textColor: 'hover:text-ncc-white'
                    }
                };
                break;

            case 'danger':
                variantStyle.background = 'bg-utilisation-red-100';
                variantStyle.textColor = 'text-ncc-white';
                variantStyle.variant = {
                    active: {
                        background: 'active:bg-utilisation-red-100',
                        textColor: 'active:!text-ncc-white'
                    },
                    disabled: {
                        background: 'disabled:bg-ncc-grey-20',
                        textColor: 'disabled:!text-ncc-grey-50'
                    },
                    hover: {
                        background: 'hover:bg-utilisation-red-100',
                        textColor: 'hover:text-ncc-white'
                    }
                };
                break;
        }

        return merge(baseStyle, variantStyle, tailwindStyle);
    }, [tailwindStyle, variant]);

    return (
        <CoreButton
            {...restProps}
            ref={ref}
            tailwindStyle={mergedTailwindStyle}
            disabled={disabled}
        >
            {!!children && (
                <span className="truncate flex items-center gap-2.5">
                    {children}
                </span>
            )}
        </CoreButton>
    );
}

export default memo(forwardRef(Button));
export type { ButtonProps };
