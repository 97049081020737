import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { CoreController } from 'core/api/autogenerated/Core';
import { DayRate, ProductType } from 'core/api/autogenerated/data-contracts';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type UpdateProductParams = {
    data: {
        is_activity: boolean;
        name: string;
        short_code: string;
        type?: ProductType;
        uuid: string;
    };
    model_uuid: string;
};

function useUpdateProduct<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<DayRate>,
            ReactQueryError<TError>,
            UpdateProductParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ data, model_uuid }: UpdateProductParams) => {
            const controller = new CoreController({
                interceptors,
                securityWorker
            });

            return controller.updateProduct(model_uuid, data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.refetchQueries('products');
                queryClient.invalidateQueries({ queryKey: ['products'] });
            }
        }
    );
}

export default useUpdateProduct;
export type { UpdateProductParams };
