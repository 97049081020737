/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';

interface AdminUsersContextValue {
    adminUsers;
    selectedAdminUser;
    setAdminUsers;
    setSelectedAdminUser;
}

const defaultValue = {
    adminUsers: [],
    selectedAdminUser: {},
    setAdminUsers: () => {},
    setSelectedAdminUser: () => {}
};

const AdminUsersContext = createContext<AdminUsersContextValue | undefined>(
    defaultValue
);

const AdminUsersProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [adminUsers, setAdminUsers] = useState([]);

    const [selectedAdminUser, setSelectedAdminUser] = useState({});

    return (
        <AdminUsersContext.Provider
            value={{
                adminUsers,
                selectedAdminUser,
                setAdminUsers,
                setSelectedAdminUser
            }}
        >
            {children}
        </AdminUsersContext.Provider>
    );
};

export const useAdminUsersContext = () => {
    const adminUsersContext = useContext(AdminUsersContext);

    if (!adminUsersContext)
        throw new Error('You need to use context inside of a provider');

    return adminUsersContext;
};

export { AdminUsersProvider, AdminUsersContext };
export type { AdminUsersContextValue };
