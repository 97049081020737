import { Controller, useFormContext } from 'react-hook-form';
import { PropsWithChildren } from 'react';
import Input from 'components/form/elements/input-element';

interface IControlledInput {
    children?;
    label;
    name;
    placeholder?;
    readOnly?;
    type;
}

const ControlledInput = ({
    children,
    label,
    name,
    placeholder,
    readOnly,
    type
}: PropsWithChildren<IControlledInput>) => {
    const {
        control,
        formState: { errors },
        register
    } = useFormContext();

    return (
        <Controller
            {...register(name)}
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { ref, value, ...field } }) => {
                return (
                    <>
                        <Input
                            {...field}
                            ref={ref}
                            label={label}
                            name={name}
                            error={errors[name]}
                            value={value}
                            disabled={readOnly}
                            placeholder={placeholder}
                            type={type}
                        />
                        {children}
                    </>
                );
            }}
        />
    );
};

export default ControlledInput;
