import React, { createContext } from 'react';
import useUser from './use-user';

const UserContext = createContext();

function UserContextProvider({ children }) {
    const {
        contract,
        setContract,
        setUser,
        setWeek,
        setYear,
        user,
        uuid,
        week
    } = useUser();

    return (
        <UserContext.Provider
            value={{
                contract,
                setContract,
                setUser,
                setWeek,
                setYear,
                user,
                uuid,
                week
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserContextProvider };
