import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Select from 'react-select';

type RolesDataProps = {
    label: string;
    name: string;
    value: string;
};

const RoleSelectComponent = ({ name, roles }) => {
    const { control, register, setValue } = useFormContext();

    const data: RolesDataProps[] = [
        {
            label: 'Allocator',
            name: 'Allocator',
            value: 'Allocator'
        },
        {
            label: 'Administrator',
            name: 'Administrator',
            value: 'Administrator'
        },
        {
            label: 'Reporter',
            name: 'Reporter',
            value: 'Reporter'
        }
    ];

    const value = useWatch({ name });

    const x = data.filter((el) => {
        return value.indexOf(el) < 0;
    });

    return (
        <div>
            <Controller
                name={name}
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <label>
                        Role(s)
                        <Select
                            {...register(name)}
                            isMulti
                            {...field}
                            ref={ref}
                            onChange={(selected, item) => {
                                setValue('roles', selected, {
                                    shouldDirty: true
                                });
                            }}
                            options={x}
                            isDisabled={true}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isDisabled
                                        ? '#F3F4F8'
                                        : '#ffffff'
                                }),
                                multiValueLabel: (base, props) => ({
                                    ...base,
                                    cursor: props.isDisabled
                                        ? 'not-allowed'
                                        : 'default'
                                })
                            }}
                        />
                    </label>
                )}
            />
        </div>
    );
};

export default RoleSelectComponent;
