import { Progress } from 'components/progress';
import TypographyHeader from 'components/typography/typography-header';

function DashboardSidePanelContent({ context }) {
    const { sideStats } = context;
    return (
        <>
            <TypographyHeader
                level={3}
                shadow
                className="border-b border-solid pb-ncc-padding-12 mb-ncc-margin-24"
            >
                Key information
                {/* <span
                    onClick={onHandleClose}
                    className="ml-auto cursor-pointer"
                >
                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                </span> */}
            </TypographyHeader>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                    <div className="flex-1">Total Developers</div>
                    <h1 className="font-SemiBold text-[32px]">
                        {sideStats.total_users}
                    </h1>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex">
                        <div className="flex-1">Utilisation Percentage</div>
                        <div>{sideStats.utilisation_percentage}%</div>
                    </div>
                    <Progress
                        bgColor="utilisation-success"
                        value={sideStats.utilisation_percentage}
                    ></Progress>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex">
                        <div className="flex-1">Activities</div>
                        <div>{sideStats.activity_percentage}%</div>
                    </div>
                    <Progress
                        bgColor="utilisation-success"
                        value={sideStats.activity_percentage}
                    ></Progress>
                </div>
            </div>
        </>
    );
}
export default DashboardSidePanelContent;
