import { FunctionComponent } from 'react';
import { ReportItem } from 'pages/admin/product-reports/product-reports-page';

interface TotalHoursAndCostProps {
    report: ReportItem;
}

const TotalHoursAndCost: FunctionComponent<TotalHoursAndCostProps> = ({
    report
}) => {
    const sumOfHours = report?.allocated_users?.reduce(
        (accumulator, object) => {
            return object.hours ? accumulator + object?.hours : 0;
        },
        0
    );

    const sumOfDevCost = report?.allocated_users?.reduce(
        (accumulator, object) => {
            return object.cost ? accumulator + object?.cost : 0;
        },
        0
    );

    return (
        <h4 className="font-semibold flex justify-end">
            Total: {sumOfHours} hrs ({`${(sumOfHours! / 7.5).toFixed(1)}`}) days
            = £{sumOfDevCost}
        </h4>
    );
};

export default TotalHoursAndCost;
