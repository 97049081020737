import { ReactElement, useState } from 'react';
import AdminPageContainer from '../admin-page-container';

import ActivityGroupsGrid from 'ui/admin/activity-groups/activity-groups-grid';
import useActivityGroups from 'core/api/hooks/activity-groups/use-activity-groups';

interface ActivityGroupsPageProps {}

function ActivityGroupsPage({ collapsed }): ReactElement {
    const [searchParam, setSearchParam] = useState<string | undefined>(
        undefined
    );

    const { data, error, isFetching, isLoading } = useActivityGroups({
        name: searchParam
    });

    return (
        <AdminPageContainer>
            <ActivityGroupsGrid
                setSearchParam={setSearchParam}
                data={data}
                error={error}
                loading={isLoading || isFetching}
            ></ActivityGroupsGrid>
        </AdminPageContainer>
    );
}

export default ActivityGroupsPage;
export type { ActivityGroupsPageProps };
