import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'components';
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import DropdownMenu from 'components/dropdown-menu/dropdown-menu';

function ActivityGroupItem({ activityGroup, deleteModal, editModal }) {
    const options = [
        {
            action: editModal,
            data: activityGroup,
            icon: (
                <FontAwesomeIcon
                    icon={faPencil}
                    className="ml-auto cursor-pointer"
                ></FontAwesomeIcon>
            ),
            label: 'Edit Group',
            styles: {}
        },
        {
            action: deleteModal,
            data: activityGroup,
            icon: (
                <FontAwesomeIcon
                    icon={faTrash}
                    className="ml-auto cursor-pointer text-utilisation-red"
                ></FontAwesomeIcon>
            ),
            label: 'Delete Group',
            styles: {
                icon: 'text-utilisation-red',
                text: 'text-utilisation-red'
            }
        }
    ];
    return (
        <div className="border border-1 rounded p-ncc-padding-24 grid gap-4 relative">
            <Typography.Header level={4} shadow={false}>
                {activityGroup.name}
                <DropdownMenu options={options}></DropdownMenu>
            </Typography.Header>
            <Typography.Header
                level={5}
                shadow={false}
                className="text-ncc-grey-70"
            >
                Activities
            </Typography.Header>
            <ul>
                {activityGroup.activities.map((activity) => (
                    <li key={activity}>{activity}</li>
                ))}
            </ul>
        </div>
    );
}

export default ActivityGroupItem;
