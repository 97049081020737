import { NavLink, Outlet } from 'react-router-dom';
import { ReactElement } from 'react';
import { getPermission } from 'common/permissions';
import ContentBody from 'app-layout/bodies/content-body';
import PageHeading from 'ui/page-headings/page-heading';

interface AllocationsPageProps {
    collapsed?: boolean;
}

function AllocationsPage({ collapsed }: AllocationsPageProps): ReactElement {
    return (
        <div className="flex flex-col flex-1">
            <PageHeading title="Allocations"></PageHeading>
            <div className="flex items-center pl-ncc-padding-24 gap-3">
                <NavLink
                    to="/allocations/personal"
                    className={({ isActive }) =>
                        isActive
                            ? 'pb-ncc-padding-12 font-bold border-b-[5px] border-solid border-utilisation-dark-blue '
                            : 'pb-[17px] inactive'
                    }
                >
                    Personal
                </NavLink>
                {getPermission('LINE_MANAGER') && (
                    <NavLink
                        to="/allocations/team"
                        className={({ isActive }) =>
                            isActive
                                ? 'pb-ncc-padding-12 font-bold border-b-[5px] border-solid border-utilisation-dark-blue'
                                : 'pb-[17px] inactive'
                        }
                    >
                        Team
                    </NavLink>
                )}
            </div>

            <ContentBody>
                <Outlet />
            </ContentBody>
        </div>
    );
}

export default AllocationsPage;
export type { AllocationsPageProps };
