import { MouseEventHandler, ReactNode, useMemo } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { isNull } from 'lodash';
import classNames from 'classnames';

interface SiderLinkProps {
    canView?: boolean;
    collapsed?: boolean;
    icon: ReactNode;
    onClick?: MouseEventHandler<HTMLElement>;
    suppressHover?: boolean;
    text?: string;
    to?: string;
}

function SiderLink({
    collapsed,
    icon,
    onClick,
    suppressHover,
    text,
    to
}: SiderLinkProps) {
    const match = useMatch(to ?? '');

    const cssClasses = classNames(
        `
        -ml-1 h-12 p-3 flex gap-4 items-center rounded-lg
        text-white truncate
        transition-color duration-300
        hover:bg-utilisation-light-blue/30
        `,
        {
            'bg-utilisation-light-blue/30': !isNull(match),
            'p-2.5 justify-center h-12 w-12': collapsed
        }
    );

    const content = useMemo(
        () => (
            <>
                <div
                    className="flex items-center justify-center"
                    style={{
                        fontSize: '16px',
                        height: '1.5rem',
                        width: '1.5rem'
                    }}
                >
                    {icon}
                </div>
                {!collapsed && text && <div>{text}</div>}
            </>
        ),
        [collapsed, icon, text]
    );

    return to ? (
        <NavLink data-testid="link" to={to} className={cssClasses}>
            {content}
        </NavLink>
    ) : (
        <div onClick={onClick} className={cssClasses}>
            {content}
        </div>
    );
}

export default SiderLink;
export type { SiderLinkProps };
