import React, { createContext, useContext } from 'react';
import useProductReports from './useProducts';

interface ProductsContextValue {
    products;
    push;
    setProducts;
}

export const ProductsContext = createContext<ProductsContextValue | null>(null);

const ProductsProvider: React.FC<React.ReactNode> = ({ children }) => {
    const { products, push, setProducts } = useProductReports();

    return (
        <ProductsContext.Provider
            value={{
                products,
                push,
                setProducts
            }}
        >
            {children}
        </ProductsContext.Provider>
    );
};

export const useProductsContext = () => {
    const productsContext = useContext(ProductsContext);

    if (!productsContext)
        throw new Error('You need to use context inside of a provider');

    return productsContext;
};

export { ProductsProvider };
export type { ProductsContextValue };
