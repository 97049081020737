import { createContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DataTableContext = createContext<any>(false);
// Provider
const DataTableProvider = ({ children }) => {
    const [showSidePanel, setShowSidePanel] = useState(false);

    const toggleSidePanelFunction = () => {
        setShowSidePanel(!showSidePanel);
    };
    return (
        <DataTableContext.Provider
            value={{
                showSidePanel,
                toggleSidePanelFunction
            }}
        >
            {children}
        </DataTableContext.Provider>
    );
};
export { DataTableContext, DataTableProvider };
