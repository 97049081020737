import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactNode } from 'react';

import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { useModals } from '@ncc-frontend/core';
import Modal from 'components/modal';
import ModalBody from 'components/modal/modal-body';
import Typography from 'components/typography';

interface ModalContainerProps {
    content;
    crudAction?: string | null;
    data;
    description?: ReactNode;
    miscData?;
    title?: ReactNode;
}

function ModalContainer({
    children,
    content: Content,
    crudAction,
    data,
    description,
    miscData,
    title
}: PropsWithChildren<ModalContainerProps>) {
    const { pop } = useModals();
    return (
        <Modal className="w-[480px] p-ncc-padding-24">
            <div className="flex items-center border-b-2 pb-ncc-padding-20">
                <Typography.Header level={3} shadow={false} className="mr-auto">
                    {title}
                </Typography.Header>
                <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => pop()}
                    className="cursor-pointer"
                />
            </div>
            <ModalBody className="flex flex-col text-center gap-2">
                <div>{description}</div>
                <Content
                    crudAction={crudAction}
                    data={data}
                    miscData={miscData}
                />
            </ModalBody>
        </Modal>
    );
}

export default ModalContainer;
export type { ModalContainerProps };
