import Drop from 'ui/account/actions/drop';
import useAuth from 'authLib/use-auth';

function Account() {
    const { logOut } = useAuth();

    return <Drop action={logOut}></Drop>;
}

export default Account;
