import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';
import { TailwindStyle } from '@ncc-frontend/core';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import HeaderButton from './header-button';

interface CollapseButtonProps {
    collapsed?: boolean;
    onToggle: MouseEventHandler<HTMLDivElement>;
}

function CollapseButton({ collapsed, onToggle }: CollapseButtonProps) {
    const tailwindStyle = useMemo<TailwindStyle>(
        () => ({
            background: collapsed ? 'bg-none' : 'bg-none',
            textColor: collapsed ? 'text-white' : 'text-white',
            variant: {
                before: {
                    background: collapsed
                        ? 'before:bg-utilisation-light-blue/30'
                        : 'before:bg-utilisation-light-blue/30'
                },
                hover: {
                    textColor: collapsed
                        ? 'hover:text-utilisation-turquoise'
                        : 'hover:text-white'
                }
            },
            width: collapsed ? 'w-auto' : 'w-14'
        }),
        [collapsed]
    );

    return (
        <HeaderButton
            data-testid="toggle"
            onClick={onToggle}
            tailwindStyle={tailwindStyle}
        >
            {!collapsed ? (
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-2xl border-l-2"
                />
            ) : (
                <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-2xl border-r-2"
                />
            )}
        </HeaderButton>
    );
}

export default CollapseButton;
export type { CollapseButtonProps };
