import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface AppBodyProps extends CommonProps {}

function AppBody({
    children,
    className,
    ...restProps
}: PropsWithChildren<AppBodyProps>) {
    const cssClasses = classNames(
        className,
        'mt-[60px] overflow-auto flex w-full app-body'
    );

    return (
        // all body sections including right hand sidebars and main pane content
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default AppBody;
export type { AppBodyProps };
