import './add-activity-group-button.css';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const AddActivityGroupButton = ({ addNewActivityGroup, disabled }) => {
    return (
        <Button
            variant="primary"
            onClick={addNewActivityGroup}
            data-testid="add-new-row"
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> New activity group
        </Button>
    );
};

export default AddActivityGroupButton;
