import { useCallback } from 'react';

import { AppController } from '../../autogenerated/App';
import interceptors from 'core/api/interceptors';
import securityWorker from '../../securityWorker';
import useQuery from 'core/api/react-query/useQuery';

function useAllocationsData(query?: {
    week?: string
}) {

    const request = useCallback(async () => {
        const controller = new AppController({
            interceptors,
            securityWorker
        });

        // We don't want to catch any errors here since it will broke react query "error" status
        const response =
            await controller.appUserAllocations(query);
            return response.data;
    }, [query]);

    return useQuery(['allocations', 'list', query], request);
}

export default useAllocationsData;