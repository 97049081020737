import { createContext, useContext } from 'react';

import { MultiSelectProps } from './interfaces';

export const MultiSelectContext = createContext<MultiSelectProps | undefined>(
    undefined
);

export function useMultiSelectContext() {
    const context = useContext(MultiSelectContext);

    if (context === undefined) {
        throw new Error(
            'useMultiSelectContext must be used with a MultiSelectContext'
        );
    }

    return context;
}
