import { ComponentType, useMemo } from 'react';

import { CalendarItemProps } from './calendar-item';
import CalendarGrid from './calendar-grid';
import Month, { MonthProps } from './month';

interface MonthPickerProps {
    activeDates: [Date | undefined, Date | undefined];
    calendarItemComponent: ComponentType<CalendarItemProps>;
    constrain: 'future' | 'past' | 'unrestricted';
    locale: string;
    onSelect: MonthProps['onSelect'];
    year: number;
}

function MonthPicker({
    activeDates,
    calendarItemComponent: CalendarItemComponent,
    constrain,
    locale,
    onSelect,
    year
}: MonthPickerProps) {
    const months = useMemo<Pick<MonthProps, 'year' | 'month'>[]>(
        () =>
            new Array(12)
                .fill(0, 0, 12)
                .map((_item, index) => ({ month: index, year })),
        [year]
    );

    return (
        <CalendarGrid className="grid-cols-3">
            {months.map((monthProps) => (
                <Month
                    key={monthProps.month}
                    {...monthProps}
                    onSelect={onSelect}
                    locale={locale}
                    constrain={constrain}
                    activeDates={activeDates}
                    calendarItemComponent={CalendarItemComponent}
                />
            ))}
        </CalendarGrid>
    );
}

export default MonthPicker;
export type { MonthPickerProps };
