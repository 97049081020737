import { Controller, useFormContext } from 'react-hook-form';
import SelectElement from 'components/form/elements/select-element';

const TypeComponent = ({ readOnly }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();

    const options = [
        {
            label: 'Software / Platforms',
            value: 'ncc-software'
        },
        {
            label: 'Apps',
            value: 'third-party-platform'
        },
        {
            label: 'GTS Services',
            value: 'gts-services'
        },
        {
            label: 'Finance',
            value: 'finance'
        }
    ];

    return (
        <div>
            <Controller
                name="type"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ...field } }) => (
                    <SelectElement
                        {...field}
                        options={options}
                        name="type"
                        disabled={readOnly}
                        error={errors.type}
                        label="Type"
                        changeHandler={(val) => onChange(val?.value)}
                        value={options.find((c) => {
                            return c.value === value;
                        })}
                    ></SelectElement>
                )}
            />
        </div>
    );
};

export default TypeComponent;
