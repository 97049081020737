import { ReactElement } from 'react';
import ContentBody from 'app-layout/bodies/content-body';

interface LoadingPageProps {}

function LoadingPage(): ReactElement {
    return (
        <ContentBody className="flex justify-center">
            <h1>Logging you into the system...</h1>
        </ContentBody>
    );
}

export default LoadingPage;
export type { LoadingPageProps };
