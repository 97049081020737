const allocationPercentages = [
        {
            id: '0',
            label: '5%',
            name: 'percentage',
            value: 5
        },
        {
            id: '1',
            label: '10%',
            name: 'percentage',
            value: 10
        },
        {
            id: '2',
            label: '15%',
            name: 'percentage',
            value: 15
        },
        {
            id: '3',
            label: '20%',
            name: 'percentage',
            value: 20
    },
        {
            id: '4',
            label: '25%',
            name: 'percentage',
            value: 25
        },
        {
            id: '5',
            label: '30%',
            name: 'percentage',
            value: 30
        },
        {
            id: '6',
            label: '35%',
            name: 'percentage',
            value: 35
        },
        {
            id: '7',
            label: '40%',
            name: 'percentage',
            value: 40
    },
        {
            id: '8',
            label: '45%',
            name: 'percentage',
            value: 45
        },
        {
            id: '9',
            label: '50%',
            name: 'percentage',
            value: 50
        },
        {
            id: '10',
            label: '55%',
            name: 'percentage',
            value: 55
        },
        {
            id: '11',
            label: '60%',
            name: 'percentage',
            value: 60
    },
        {
            id: '12',
            label: '65%',
            name: 'percentage',
            value: 65
        },
        {
            id: '13',
            label: '70%',
            name: 'percentage',
            value: 70
        },
        {
            id: '14',
            label: '75%',
            name: 'percentage',
            value: 75
        },
        {
            id: '15',
            label: '80%',
            name: 'percentage',
            value: 80
        },
        {
            id: '16',
            label: '85%',
            name: 'percentage',
            value: 85
        },
        {
            id: '17',
            label: '90%',
            name: 'percentage',
            value: 90
        },
        {
            id: '18',
            label: '95%',
            name: 'percentage',
            value: 95
    },
                {
            id: '19',
            label: '100%',
            name: 'percentage',
            value: 100
        }
];
    
export default allocationPercentages;
