import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { CommonProps } from '@ncc-frontend/core';

interface ContentBodyProps extends CommonProps {}

function ContentBody({
    children,
    ...restProps
}: PropsWithChildren<ContentBodyProps>) {
    const cssClasses = classNames(
        'transition-all flex flex-1 flex-col p-body bg-mainContent dark:bg-utilisation-page-dark'
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default ContentBody;
export type { ContentBodyProps };
