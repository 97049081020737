import { DataTableContext } from './data-table-context';
import { useContext } from 'react';
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from 'react-table';

import DataTable from './data-table';
import DataTableSidePanel from './data-table-side-panel';

interface IDataTableProps {
    actionButton?;
    columns;
    context;
    data;
    defaultColumn?;
    numFiltersSelected?;
    openFilters?;
    sidePanelContent?;
    tableId?;
    textSearchId?;
}

function DataTableContainer({
    columns,
    context,
    data,
    defaultColumn,
    numFiltersSelected,
    sidePanelContent: SidePanelContent
}: IDataTableProps) {
    const table = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                pageIndex: 0,
                pageSize: 10
            }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const {
        canNextPage,
        canPreviousPage,
        getTableBodyProps,
        getTableProps,
        gotoPage,
        headerGroups,
        nextPage,
        page,
        pageCount,
        pageOptions,
        prepareRow,
        previousPage,
        setFilter,
        setGlobalFilter,
        state: { pageIndex }
    } = table;

    const { showSidePanel } = useContext(DataTableContext);

    return (
        <div className="flex gap-5">
            <div className="flex flex-col flex-1">
                <DataTable
                    setGlobalFilter={setGlobalFilter}
                    numFiltersSelected={numFiltersSelected}
                    context={context}
                    columns={columns}
                    data={data ? data : []}
                    prepareRow={prepareRow}
                    headerGroups={headerGroups}
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    setFilter={setFilter}
                    page={page}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                />
            </div>

            {showSidePanel && (
                <DataTableSidePanel {...table}>
                    {SidePanelContent}
                </DataTableSidePanel>
            )}
        </div>
    );
}

export default DataTableContainer;
