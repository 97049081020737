import {
    PropsWithChildren,
    ReactElement,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ContentType } from 'core/api/autogenerated/http-client';
import { LogoutController } from 'core/api/autogenerated/Logout';
import { Oauth2Controller } from 'core/api/autogenerated/Oauth2';
import { UserBase } from '../core/api/autogenerated/data-contracts';
import { getDefaultRouteForUser } from 'helpers/helpers';
import interceptors from '../core/api/interceptors';

type LogInFn = () => void;
type LogOutFn = () => void;

enum LocalStorageKey {
    accessToken = 'access_token',
    idToken = 'id_token',
    refreshToken = 'refresh_token',
    userInfo = 'user'
}

type AuthStatus = 'authorized' | 'authorizing' | 'expired' | 'not-authorized';

interface MsadAuthService<User = unknown> {
    logIn: LogInFn;
    logOut: LogOutFn;
    status: AuthStatus;
    user: User;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MsadAuthContext = createContext<MsadAuthService<any> | undefined>(
    undefined
);

interface MsadAuthProviderProps {
    authenticationUrl: string;
    msdaUrl: string;
}

let isFirst = true;

function MsadAuthProvider({
    authenticationUrl,
    children,
    msdaUrl
}: PropsWithChildren<MsadAuthProviderProps>): ReactElement {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);
    const msadCode = params.get('code');
    const [user, setUser] = useState<UserBase | null>();

    const [status, setStatus] = useState<AuthStatus>(
        localStorage.getItem(LocalStorageKey.accessToken)
            ? 'authorized'
            : 'not-authorized'
    );

    const logIn = useCallback(() => {
        window.location.href = msdaUrl;
    }, [msdaUrl]);

    useEffect(() => {
        if (isFirst) {
            isFirst = false;
            if (pathname === authenticationUrl) {
                setStatus('authorizing');
                const oauth2Controller = new Oauth2Controller({
                    interceptors
                });
                oauth2Controller
                    .oauthAccessToken({
                        headers: {
                            Authorization: msadCode
                        }
                    })
                    .then(({ data }) => {
                        setStatus('authorized');
                        setUser(data?.user);

                        localStorage.setItem('user', JSON.stringify(data));

                        const defaultRoute = getDefaultRouteForUser(data?.user);
                        navigate(defaultRoute, { replace: true });
                    })
                    .catch((err) => {
                        setUser(null);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logOut = useCallback(() => {
        const params = new URLSearchParams();
        params.append(
            'redirect_uri',
            `${process.env.REACT_APP_BASE_URL}/logout`
        );

        const logoutController = new LogoutController({
            interceptors
        });

        logoutController
            .oauthLogout({
                headers: {
                    Authorization: msadCode,
                    type: ContentType.UrlEncoded
                }
            })
            .then(({ data }) => {
                localStorage.removeItem(LocalStorageKey.accessToken);
                localStorage.removeItem(LocalStorageKey.refreshToken);
                localStorage.setItem(LocalStorageKey.userInfo, '');
                setStatus('not-authorized');
                setUser(null);
                navigate('/login', { replace: true });
            })
            .catch((err) => {
                setUser(null);
            });
    }, [msadCode, navigate]);

    const value = useMemo<MsadAuthService>(
        () => ({
            logIn,
            logOut,
            status,
            user
        }),
        [logIn, logOut, status, user]
    );

    return (
        <MsadAuthContext.Provider value={value}>
            {children}
        </MsadAuthContext.Provider>
    );
}

export default MsadAuthProvider;
export { MsadAuthContext, LocalStorageKey };
export type { MsadAuthProviderProps, MsadAuthService };
