import * as yup from 'yup';
import { Button, Modal } from 'components';
import { CSVLink } from 'react-csv';
import {
    Controller,
    FormProvider,
    Path,
    SubmitHandler,
    useForm
} from 'react-hook-form';
import { MultiSelectOption, MultiSelectProps } from 'ui/MultiSelect/interfaces';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { yupResolver } from '@hookform/resolvers/yup';
import DateSelect from './date-select';
import MultiSelect from 'ui/MultiSelect';
import TypographyHeader from 'components/typography/typography-header';
import TypographyParagraph from 'components/typography/typography-paragraph';
import moment from 'moment';
import useDownloadAllocationsCSV from 'core/api/hooks/dashboard/use-download-csv';
import useProjectListData from 'core/api/hooks/products/use-project-list-data';

type FormValues = {
    end_date: string;
    product_uuids: string[] | undefined;
    start_date: string;
};

interface DownloadCSVModalProps {}

function DownloadCSVModal({}: DownloadCSVModalProps): ReactElement | null {
    const [csvData, setCSVData] = useState<FormValues | undefined>(undefined);
    const [gettingFileDownloadModal, setGettingFileDownloadModal] =
        useState<boolean>(false);
    const { pop } = useModals();
    const columnCssClasses = 'grid items-center gap-3 py-4 text-ncc-grey-100';

    const schema = yup.object({
        end_date: yup.string().required(),
        product_uuids: yup.array().required(),
        start_date: yup.string().required()
    });

    const csvLinkRef = useRef<{ link: HTMLAnchorElement }>(null);

    const methods = useForm<FormValues>({
        defaultValues: {
            end_date: undefined,
            product_uuids: [],
            start_date: undefined
        },
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const [multiSelectData] = useState<MultiSelectProps>({
        isExpanded: false,
        labelledBy: 'Select Projects',
        options: [] as MultiSelectOption[],
        selectPrompt: 'All projects'
    });

    const { data: products, isLoading } = useProjectListData();

    multiSelectData.options = useMemo(
        () =>
            products?.map((product) => ({
                label: product.name as string,
                selected: false,
                value: product.uuid as string
            })) ?? [],
        [products]
    );

    const showDownloadModal = () => {
        setTimeout(() => {
            setGettingFileDownloadModal(false);
            if (csvLinkRef?.current) {
                pop();
                return csvLinkRef.current.link.click();
            }
        }, 1000);
    };

    const { mutate: downloadCSV } = useDownloadAllocationsCSV<{
        errors: Record<Path<FormValues>, string[]>;
    }>({
        onError: () => {
            return;
        },
        onSettled: () => {
            showDownloadModal();
        },
        onSuccess: ({ data }) => {
            setGettingFileDownloadModal(true);
            setCSVData(data);
        }
    });

    const submit = useCallback<SubmitHandler<FormValues>>(
        (formData) => {
            const data = {
                end_date: moment(formData.end_date).utc().format('YYYY-MM-DD'),
                product_uuids:
                    multiSelectData.options
                        .filter((o) => o.selected)
                        .map((o) => {
                            return o.value as string;
                        }) ?? [],
                start_date: moment(formData.start_date)
                    .utc()
                    .format('YYYY-MM-DD')
            };
            downloadCSV(data);
        },
        [downloadCSV, multiSelectData]
    );

    const cancelDownload = () => {
        pop();
    };

    return (
        <Modal className="p-ncc-padding-24 w-[480px]">
            <Modal.Header>
                <TypographyHeader level={3} shadow={false}>
                    Download allocations
                </TypographyHeader>
            </Modal.Header>

            <Modal.Body className="flex flex-col overflow-visible">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(submit)}>
                        <p className="text-ncc-form-label text-utilisation-light-blue">
                            Please select a start date and end date
                        </p>
                        <div className={columnCssClasses}>
                            <div className="grid grid-cols-2">
                                <DateSelect
                                    name="start_date"
                                    label="From Date"
                                ></DateSelect>
                                <DateSelect
                                    name="end_date"
                                    label="To Date"
                                ></DateSelect>
                            </div>
                        </div>

                        <div className="my-3">
                            {isLoading ? (
                                <TypographyParagraph>
                                    Loading ...
                                </TypographyParagraph>
                            ) : (
                                products && (
                                    <label className="text-ncc-form-label text-utilisation-light-blue">
                                        <div className="mt-2">
                                            <Controller
                                                name="product_uuids"
                                                render={({
                                                    field: { onChange, value }
                                                }) => (
                                                    <MultiSelect
                                                        props={multiSelectData}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </label>
                                )
                            )}
                        </div>

                        <div className="flex justify-end">
                            <Button
                                onClick={cancelDownload}
                                variant="primary"
                                className="col-span-2 m-2"
                                loading={false}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="col-span-2 ml-2 mt-2"
                                loading={false}
                                disabled={
                                    !methods.formState.isDirty ||
                                    !methods.formState.isValid ||
                                    gettingFileDownloadModal
                                }
                            >
                                Confirm
                            </Button>

                            {csvData && (
                                <CSVLink
                                    data={csvData}
                                    filename="data.csv"
                                    className="hidden"
                                    ref={csvLinkRef}
                                    target="_blank"
                                />
                            )}
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default DownloadCSVModal;
export type { FormValues, DownloadCSVModalProps };
