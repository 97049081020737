import * as yup from 'yup';
import { FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import { Product } from 'core/api/autogenerated/data-contracts';
import { ResultModal } from 'components';
import { useEffect, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button/button';
import ChargeableComponent from './form-field-controllers/chargeable-component';
import NameComponent from './form-field-controllers/name-component';
import PageLoader from 'components/loaders/page-loader';
import ShortCodeComponent from './form-field-controllers/short-code-component';
import TypeComponent from './form-field-controllers/type-component';
import useCreateProduct from 'core/api/hooks/products/use-create-product';
import useDeleteProduct from 'core/api/hooks/products/use-delete-product';
import useUpdateProduct from 'core/api/hooks/products/use-update-product';

interface IProductsFormFormValues extends Product {}

interface IProductsFormProps {
    crudAction?: string | null;
    data?: IProductsFormFormValues;
}

const schema = yup.object().shape({
    is_activity: yup.boolean().required('Is activity is required'),
    name: yup.string().required('Name is required'),
    short_code: yup.string().required('Short code is required'),
    type: yup.string().required('Type field is required')
});

const ProductCrudForm = ({ crudAction, data }: IProductsFormProps) => {
    const { pop, push } = useModals();
    const [readOnly, setReadOnly] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);

    const methods = useForm<IProductsFormFormValues>({
        defaultValues: {
            is_activity: data ? data.is_activity : false,
            name: data ? data.name : '',
            short_code: data ? data.short_code : '',
            type: data ? data.type : undefined
        },
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        methods.reset(data);
        if (crudAction === 'delete') setReadOnly(true);
    }, [crudAction, data, methods]);

    // Return an appropriate success message should the api response be 200 or 204

    const getSuccessMessage = (action) => {
        switch (action) {
            case 'create':
                return 'Your Product was created successfully';
            case 'update':
                return 'Your Product was updated successfully';
            case 'delete':
                return 'Your Product was deleted successfully';
            default:
                break;
        }
        return;
    };

    // onHandleApiError Fn

    const onHandleApiError = (err) => {
        if (err.response.status !== 400) {
            pop();
            return push(ResultModal, {
                description: 'Something went wrong. Close and try again.',
                title: 'Changes didnt happen',
                variant: 'error'
            });
        }
    };

    // onHandleApiSuccess

    const onHandleApiSuccess = () => {
        pop();
        methods.reset();
        return push(ResultModal, {
            description: getSuccessMessage(crudAction),
            title: `Action successful`,
            variant: 'success'
        });
    };

    // Create a new Product

    const { isLoading, mutate: createProduct } = useCreateProduct<{
        errors: Record<Path<IProductsFormFormValues> | 'invalid', string[]>;
    }>({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    // Update a Product

    const { mutate: updateProduct } = useUpdateProduct<{
        errors: Record<Path<IProductsFormFormValues> | 'invalid', string[]>;
    }>({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    // Delete a Product

    const { mutate: deleteProduct } = useDeleteProduct({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    useEffect(() => {
        isLoading ? setApiLoading(true) : setApiLoading(false);
    }, [isLoading, methods.formState.isValid]);

    const formSubmitHandler: SubmitHandler<IProductsFormFormValues> = (
        data: IProductsFormFormValues
    ) => {
        switch (crudAction) {
            case 'create':
                createProduct({ data });
                break;
            case 'update':
                updateProduct({
                    data: {
                        is_activity: data.is_activity,
                        name: data.name,
                        short_code: data.short_code,
                        type: data.type,
                        uuid: data.uuid
                    },
                    model_uuid: data.uuid as string
                });
                break;
            case 'delete':
                deleteProduct({ query: { uuid: data?.uuid as string } });
                break;
            default:
                break;
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(formSubmitHandler)} noValidate>
                {apiLoading ? (
                    <PageLoader
                        suppressBackground={true}
                        type="fast"
                        text={'Submitting request...'}
                    ></PageLoader>
                ) : (
                    <>
                        <div className="flex flex-col gap-6 text-left">
                            <NameComponent
                                readOnly={readOnly}
                                type="text"
                                placeholder={'Please enter a name...'}
                            ></NameComponent>
                            <TypeComponent readOnly={readOnly}></TypeComponent>
                            <div className="flex gap-6">
                                <ShortCodeComponent
                                    readOnly={readOnly}
                                    type="text"
                                    placeholder={'Please enter a short code...'}
                                ></ShortCodeComponent>

                                <ChargeableComponent
                                    readOnly={readOnly}
                                ></ChargeableComponent>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="flex gap-2 justify-end">
                                <Button onClick={pop} variant="tertiary">
                                    Cancel
                                </Button>
                                {crudAction === 'delete' ? (
                                    <Button variant="danger">Delete</Button>
                                ) : (
                                    <Button
                                        variant="primary"
                                        // disabled={!methods.formState.isValid}
                                    >
                                        Save
                                    </Button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </form>
        </FormProvider>
    );
};

export default ProductCrudForm;
export type { IProductsFormFormValues };
