const DataTableCustomFilters = ({ getTableProps, headerGroups }) => {
    return (
        <table {...getTableProps()} className="text-sm w-full">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                <>
                                    {column.canFilter &&
                                    column.showInMainFilters
                                        ? column.render('Filter')
                                        : null}
                                </>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
        </table>
    );
};

export default DataTableCustomFilters;
