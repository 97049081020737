import { PropsWithChildren, ReactElement } from 'react';

function ModalFooter({
    children
}: PropsWithChildren<unknown>): ReactElement | null {
    return (
        <div className="flex items-center border-t-[1px] border-solid pt-ncc-padding-24">
            {children}
        </div>
    );
}

export default ModalFooter;
