import { useState } from 'react';

const useTheme = (initialState) => {
    const [theme, setTheme] = useState(initialState);

    return {
        setTheme,
        theme
    };
};

export default useTheme;
