import { merge } from 'lodash';

import { TailwindStyle } from './tailwind-props';

function buildCssClasses(
    style: TailwindStyle | boolean | string | object | undefined,
    propertyName?: string,
    variant?: string
): string | undefined {
    if (!style) return;

    if (propertyName) {
        if (propertyName !== 'variant') {
            switch (typeof style) {
                case 'boolean':
                    return propertyName;
                case 'string':
                    return style;
                case 'object':
                    return Object.entries(style)
                        .map(([_key, value]) => `${value}`)
                        .join(' ');
            }
        } else {
            return Object.entries(style)
                .map(([key, value]) => buildCssClasses(value, undefined, key))
                .join(' ');
        }
    } else {
        return Object.entries(style)
            .map(([key, value]) => buildCssClasses(value, key, variant))
            .join(' ');
    }
}

function tailwindClasses(
    base: TailwindStyle | undefined,
    custom?: TailwindStyle
) {
    return buildCssClasses(
        merge<TailwindStyle | undefined, TailwindStyle | undefined>(
            base,
            custom
        )
    );
}

export default tailwindClasses;
