import SiderLink, { SiderLinkProps } from './sider-link';

interface SiderSectionProps {
    collapsed?: boolean;
    links: Omit<SiderLinkProps, 'collapsed'>[];
}

function SiderSection({ collapsed, links }: SiderSectionProps) {
    return (
        <div className="my-1">
            {links.map((linkProps, index) =>
                linkProps.canView ? (
                    <SiderLink
                        key={index}
                        {...linkProps}
                        collapsed={collapsed}
                    />
                ) : null
            )}
        </div>
    );
}

export default SiderSection;
export type { SiderSectionProps };
