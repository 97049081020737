import { Fragment, useContext, useMemo } from 'react';
import { Progress } from 'components/progress';
import { TeamsTableContext } from './teams-table-context';
import { useAllocationsContext } from 'ui/allocations/allocations-context';
import { useModals } from '@ncc-frontend/core';
import DataTableContainer from 'components/data-table/data-table-container';
// import DataTableSelectFilter from 'ui/admin/teams/teams-table-filters';
import { DataTableContext } from 'components/data-table/data-table-context';
import TeamsActionRenderer from './teams-action-renderer';
import TeamsSidePanelContent from './teams-side-panel-content';
import TeamsTableFilters from 'ui/admin/teams/teams-table-filters';

interface TeamUser {
    allocations;
    previous_week_allocations;
    summary;
}

interface FilterProps {
    column;
}

export const MultipleFilter = (rows, {}, filterValue) => {
    const arr: string[] = [];
    rows.forEach((val) => {
        const dayRateObj = val.original.summary.user.day_rate;
        if (
            dayRateObj &&
            filterValue.includes(val.original.summary.user.day_rate.name)
        )
            arr.push(val);
    });

    return arr;
};

function setFilteredParams(filterArr, val) {
    if (filterArr.includes(val)) {
        filterArr = filterArr.filter((n) => {
            return n !== val;
        });
    } else filterArr.push(val);

    if (filterArr.length === 0) filterArr = undefined;
    return filterArr;
}

function SelectColumnFilter({
    column: { filterValue = [], id, preFilteredRows, setFilter }
}: FilterProps) {
    const { setNumFiltersSelected } = useAllocationsContext();

    setNumFiltersSelected(filterValue.length);

    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            const dayRateObj = row.original.summary.user.day_rate;
            if (dayRateObj) options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <Fragment>
            <div className="block">
                <h3 className="pb-ncc-padding-8 mb-ncc-margin-12 border-b border-solid border-ncc-grey-20 text-[16px] font-SemiBold capitalize">
                    {id}
                </h3>
                {options.map((option, i) => {
                    return (
                        <Fragment key={i}>
                            <div className="flex items-center mb-ncc-margin-8">
                                <input
                                    type="checkbox"
                                    checked={
                                        filterValue.includes(option)
                                            ? true
                                            : false
                                    }
                                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    id={option as string}
                                    name={option as string}
                                    value={option as string}
                                    onChange={(e) => {
                                        setFilter(
                                            setFilteredParams(
                                                filterValue,
                                                e.target.value
                                            )
                                        );
                                    }}
                                ></input>
                                <label
                                    htmlFor={option as string}
                                    className="ml-1.5 font-medium text-gray-700"
                                >
                                    {option as string}
                                </label>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </Fragment>
    );
}

function TeamsTable({ users }) {
    const {
        month,
        numFiltersSelected,
        setMonth,
        setWeek,
        setYear,
        week,
        year
    } = useAllocationsContext();
    const { toggleSidePanelFunction } = useContext(DataTableContext);
    const { push } = useModals();
    const pickerType = 'week';

    const tableContext = useMemo<TeamsTableContext>(
        () => ({
            month,
            pickerType,
            push,
            setMonth,
            setWeek,
            setYear,
            toggleSidePanelFunction,
            week,
            year
        }),
        [
            push,
            month,
            setMonth,
            setWeek,
            setYear,
            week,
            year,
            pickerType,
            toggleSidePanelFunction
        ]
    );

    const columns = useMemo(
        () => [
            {
                Cell: ({ value }) => {
                    return (
                        <div className="flex items-center gap-2 font-SemiBold">
                            {`${value}`}
                        </div>
                    );
                },
                Header: 'Name',
                accessor: (prop) => {
                    return (
                        prop.summary.user.given_name +
                        ' ' +
                        prop.summary.user.family_name
                    );
                },

                disableFilters: true,
                filter: 'includesString',
                id: 'name'
            },
            {
                Filter: SelectColumnFilter,
                Header: 'Role',
                accessor: 'summary.user.day_rate.name',
                enableGlobalFilter: true,
                filter: MultipleFilter,
                id: 'role',
                showInSideFilters: true
            },
            {
                // Filter: DataTableSelectFilter,
                Header: 'Reports to',
                accessor: (prop) => {
                    if (prop.summary.user.line_manager !== null) {
                        return (
                            prop.summary.user.line_manager.given_name +
                            ' ' +
                            prop.summary.user.line_manager.family_name
                        );
                    }
                    return null;
                },

                id: 'line_manager',
                showInMainFilters: true
            },
            {
                Cell: ({ value }) => {
                    const totalPercentage = value.reduce(
                        (accumulator, object) => {
                            return object.percentage
                                ? accumulator + object?.percentage
                                : 0;
                        },
                        0
                    );

                    return (
                        <div className="flex items-center gap-2">
                            {`${totalPercentage}%`}
                            <Progress
                                value={totalPercentage}
                                bgColor="utilisation-success"
                            ></Progress>
                        </div>
                    );
                },
                Header: 'Allocation',
                accessor: 'allocations'
            },
            {
                Cell: (props) => {
                    return (
                        <TeamsActionRenderer
                            context={tableContext}
                            {...props}
                        />
                    );
                },
                Header: 'Actions'
            }
        ],

        [tableContext]
    );

    const defaultColumn = useMemo(
        () => ({
            // Set up our default Filter UI
            Filter: TeamsTableFilters,
            context: tableContext
        }),
        [tableContext]
    );

    return (
        <DataTableContainer
            numFiltersSelected={numFiltersSelected}
            context={tableContext}
            columns={columns}
            data={users ? users : []}
            textSearchId="name"
            defaultColumn={defaultColumn}
            sidePanelContent={<TeamsSidePanelContent />}
        />
    );
}

export default TeamsTable;
export type { TeamUser };
