import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

const DayRateSelectComponent = ({
    data,
    loading
}: {
    data;
    loading: boolean;
}) => {
    const { control, formState, register, setValue } = useFormContext();
    const { errors } = formState;

    return (
        <div>
            {!loading && (
                <Controller
                    name={'day_rate'}
                    defaultValue={''}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                        <label>
                            Title
                            <Select
                                {...register('day_rate')}
                                {...field}
                                className="day-rate-select"
                                name="day_rate"
                                ref={ref}
                                onChange={(selected, item) => {
                                    setValue('day_rate', selected, {
                                        shouldDirty: true
                                    });
                                }}
                                options={data}
                            ></Select>
                        </label>
                    )}
                ></Controller>
            )}

            {loading && <>Loading...</>}
            <div className="invalid-feedback">{errors.day_rate?.message}</div>
        </div>
    );
};

export default DayRateSelectComponent;
