import { FC, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';

interface ISelectProps {
    changeHandler?;
    disabled?: boolean;
    error;
    isMulti?;
    label: string;
    name: string;
    options;
    value;
}

const labelClasses = 'flex flex-col text-[12px] text-utilisation-light-blue';

const SelectElement: FC<ISelectProps> = (
    {
        changeHandler,
        disabled,
        error,
        isMulti,
        label,
        name,
        options,
        value
    }: ISelectProps,
    ref
) => {
    return (
        <div>
            <label htmlFor={name} className={labelClasses}>
                {label}
                <Select
                    ref={ref}
                    isMulti={isMulti}
                    isDisabled={disabled}
                    value={value}
                    name="type"
                    options={options}
                    onChange={changeHandler}
                    required
                />
            </label>

            {error && (
                <span className="text-utilisation-error">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-2"
                    />
                    {error[name] ? error[name].message : null}
                </span>
            )}
        </div>
    );
};

export default forwardRef(SelectElement);
