import { PropsWithChildren, useMemo } from 'react';
import classNames from 'classnames';

interface ProgressBarProps {
    bgColor?: string;
    size?: string;
    value?: number;
}

function Progress({
    bgColor,
    size = 'sm',
    value = 0
}: PropsWithChildren<ProgressBarProps>) {
    const cssClasses = useMemo(
        () =>
            classNames(
                `transition-all duration-500 text-${size} bg-${bgColor} h-[8px] font-medium text-blue-100 text-center p-0.5 leading-none rounded-full border-solid border-1 border-utilisation-border-color-main`
            ),
        [bgColor, size]
    );

    return (
        <div className="w-full rounded-full bg-progress border overflow-hidden">
            <div
                className={cssClasses}
                style={{ backgroundColor: `${bgColor}`, width: `${value}%` }}
            ></div>
        </div>
    );
}

export default Progress;
