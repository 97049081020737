import { Controller, useFormContext } from 'react-hook-form';
import Input from 'components/form/elements/input-element';

const NameComponent = ({ placeholder, readOnly, type }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();

    return (
        <div>
            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { value, ...field } }) => {
                    return (
                        <Input
                            {...field}
                            label="Name"
                            name="name"
                            error={errors.name}
                            value={value}
                            disabled={readOnly}
                            placeholder={placeholder}
                            type={type}
                        />
                    );
                }}
            />
        </div>
    );
};

export default NameComponent;
