import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { useEffect } from 'react';

function useBackendValidation<TFieldValues extends FieldValues = FieldValues>(
    error: Record<Path<TFieldValues>, string[]> | undefined,
    setError: UseFormSetError<TFieldValues>,
    excludeFields?: string[]
) {
    useEffect(() => {
        if (error) {
            Object.entries<string[]>(error).forEach(([field, messages]) => {
                if (excludeFields?.includes(field)) return;

                setError(field as Path<TFieldValues>, {
                    message: messages[0]
                });
            });
        }
    }, [error, excludeFields, setError]);
}

export default useBackendValidation;
