import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ProgressMultItemComponent } from 'components/progress';
import { ResultModal } from 'components';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import { useAllocationsContext } from './allocations-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModals } from '@ncc-frontend/core';

import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import AllocatorForm from 'ui/allocations-allocator/allocator-form';
import PageLoader from 'components/loaders/page-loader';
import PercentageComponent from 'components/percentage';
import TypographyParagraph from 'components/typography/typography-paragraph';
// import WeekPicker from 'lib-ui/week-picker-old/week-picker-old';
import classNames from 'classnames';

import { returnActivityGroups } from 'helpers/helpers';
import Alert from 'components/alert/alert';
import OnOutsideClick from 'common/use-outside-click';
import WeekMonthPicker from 'components/week-month-picker/week-month-picker';
import usePostAllocation from 'core/api/hooks/allocations/use-post-new-allocation';

// let renderCount = 0;

interface IAllocation {
    activity;
    contract?;
    contract_uuid: string;
    percentage: number;
    product?;
    product_uuid: string;
}

interface AllocationsFormValues {
    allocations: IAllocation[];
    user_uuid: string;
    week: string;
}

function AllocationsPersonal({ allocations, isFetching, isLoading, refetch }) {
    /**
     * Define our hooks
     */

    const { isError, isSuccess, mutate: postAllocation } = usePostAllocation();
    const [fullDayBasedOnContract, setFullDayBasedOnContract] = useState();
    const [allocationHelperVisibility, setAllocationHelperVisibility] =
        useState<boolean>(false);
    const [totalPercentage, setTotalPercentage] = useState<number | undefined>(
        0
    );
    const [activityGroups, setActivityGroups] = useState([]);

    const { month, setMonth, setWeek, setYear, week, year } =
        useAllocationsContext();
    /**
     * Define our locale hook
     */

    const { t } = useTranslation();

    /**
     * Destructure methods from the custom useModals hook
     */

    const { push } = useModals();

    /**
     * Form config
     */

    const schema = yup.object({
        allocations: yup.array(),
        user_uuid: yup.string(),
        week: yup.string()
    });

    const {
        control,
        formState,
        handleSubmit: allocationsFormHandleSubmit,
        setError,
        setValue,
        ...methods
    } = useForm<AllocationsFormValues>({
        defaultValues: {
            allocations: allocations.allocations,
            user_uuid: allocations.contract.user.uuid,
            week: allocations.week
        },
        mode: 'onTouched',
        resetOptions: {},
        resolver: yupResolver(schema)
    });

    /**
     * Configure the useFieldArray hook
     */

    const { append, fields, remove, replace } = useFieldArray({
        control,
        name: 'allocations'
    });

    /**
     * Calculates and returns a percentage based on users contract
     */

    useEffect(() => {
        let fullDay;
        if (
            allocations?.contract !== undefined ||
            allocations?.contract !== null
        ) {
            if (allocations?.contract?.contracted_days) {
                fullDay = 100 / allocations.contract.contracted_days;
                setFullDayBasedOnContract(fullDay);
            }
        } else {
            setFullDayBasedOnContract(undefined);
        }
    }, [allocations.contract.contracted_days, allocations.contract]);

    useEffect(() => {
        const optionGroups = returnActivityGroups(allocations.contract.day_rate.activity_groups)
        setActivityGroups(optionGroups);
    }, [allocations.contract.day_rate.activity_groups]);

    /**
     * Calculates and returns a percentage based on users currently assigned allocations
     */

    useEffect(() => {
        let total = 0;
        allocations?.allocations.forEach((f) => {
            total = total + f.percentage!;
            return setTotalPercentage(total);
        });
        if (allocations?.allocations.length === 0) setTotalPercentage(0);
    }, [allocations, totalPercentage]);

    /**
     * CopyLastWeeksAllocations
     */

    const copyLastWeeksAllocations = useCallback(
        (e) => {
            e.preventDefault();
            replace(allocations.previous_week_allocations);
        },
        [allocations.previous_week_allocations, replace]
    );

    /**
     * Success method that returns a promise that resolves a fetchResultModal
     */

    const success = async () => {
        return await new Promise((resolve) => resolve(fetchResultModal()));
    };

    /**
     * Returns a new Result Modal instance
     */

    const fetchResultModal = () => {
        return push(ResultModal, {
            description: 'Your changes have been successfully saved',
            title: 'Changes updated',
            variant: 'success'
        });
    };

    useEffect(() => {
        if (isSuccess) {
            success();
        }
        if (isError) {
            throw new Error('Need to show a  better error here');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    /**
     * React hook form submit
     */

    const submit = useCallback(
        (formData: AllocationsFormValues) => {
            const allocationsArray = formData.allocations.map((data) => {
                return {
                    activity: data.activity,
                    contract_uuid: allocations.contract.uuid,
                    percentage: data.percentage,
                    product_uuid:
                        data.product_uuid === undefined
                            ? data.product
                                ? data.product.uuid
                                : data.product_uuid
                            : data.product_uuid
                };
            });

            const payload = {
                allocations: allocationsArray,
                user_uuid: formData.user_uuid,
                week: formData.week
            };

            postAllocation({
                body: payload
            });
        },
        [allocations.contract.uuid, postAllocation]
    );

    const showError = (data) => {
        console.log('Errors in the showError:', data);
    };

    const triggerSubmit = useCallback(() => {
        allocationsFormHandleSubmit(submit, showError)();
    }, [allocationsFormHandleSubmit, submit]);

    /**
     * Calculates and returns an array of products from our allocations objects
     */

    const productsArray = allocations?.products?.map((product) => {
        return {
            is_activity: product.is_activity,
            label: product.name,
            value: product.uuid
        };
    });

    const layoutClasses = classNames('grid gap-2 mb-2 relative grid-cols-4');

    const pickerType = 'week';

    const context = useMemo(
        () => ({
            month,
            pickerType,
            push,
            setMonth,
            setWeek,
            setYear,
            week,
            year
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [push, setWeek, week, year, setMonth, setYear, month, pickerType]
    );

    const toggleAllocationHelper = () => {
        setAllocationHelperVisibility(!allocationHelperVisibility);
    };

    // renderCount++;

    return isFetching || isLoading ? (
        <div className="flex items-center justify-center h-full">
            <PageLoader
                suppressBackground={true}
                type="fast"
                text={'Loading your allocations...'}
            ></PageLoader>
        </div>
    ) : (
        <div>
            <WeekMonthPicker context={context}></WeekMonthPicker>
            <div className="flex-col p-ncc-padding-24 relative bg-white mt-ncc-margin-12">
                {totalPercentage && totalPercentage > 100 ? (
                    <Alert
                        variant="warning"
                        className="absolute left-0 top-0 w-full rounded-t-[8px]"
                    >
                        Your allocated time exceeds 100%
                    </Alert>
                ) : null}

                <div
                    className="flex flex-row justify-between items-center"
                    style={{
                        marginTop:
                            totalPercentage && totalPercentage > 100
                                ? '30px'
                                : '0px'
                    }}
                >
                    <TypographyParagraph>
                        {`${t(
                            'widgets.allocations.overall-time-logged-this-week'
                        )}`}
                    </TypographyParagraph>
                    <PercentageComponent
                        value={totalPercentage}
                        size={'h3'}
                    ></PercentageComponent>
                </div>
                <ProgressMultItemComponent
                    totalPercentage={totalPercentage}
                    data={allocations}
                ></ProgressMultItemComponent>

                <TypographyParagraph
                    tailwindStyle={{
                        border: 'border-b',
                        margin: 'mb-ncc-margin-32',
                        padding: 'pb-ncc-padding-20',
                        textColor: 'text-text-blue-secondary',
                        textSize: 'text-[12px]'
                    }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />

                    {`${fullDayBasedOnContract}% ${t(
                        'widgets.allocations.progress.helper'
                    )}`}
                </TypographyParagraph>

                <div className={layoutClasses}>
                    <TypographyParagraph
                        tailwindStyle={{
                            textColor: 'text-utilisation-light-blue',
                            textSize: 'text-ncc-form-label'
                        }}
                    >
                        Product
                    </TypographyParagraph>

                    
                        <TypographyParagraph
                            tailwindStyle={{
                                textColor: 'text-utilisation-light-blue',
                                textSize: 'text-ncc-form-label'
                            }}
                        >
                            Activity
                        </TypographyParagraph>
                

                    <div>
                        {allocationHelperVisibility && (
                            <OnOutsideClick
                                onOutsideClick={() =>
                                    setAllocationHelperVisibility(false)
                                }
                            >
                                <div className="absolute bottom-[25px]">
                                    <ul className="tooltip bg-black rounded-xl z-20 text-[12px] text-white px-ncc-padding-16 py-ncc-padding-12 shadow-md">
                                        <li>
                                            {`${fullDayBasedOnContract}%`} - 1
                                            Day
                                        </li>
                                        <li>
                                            {`${
                                                (fullDayBasedOnContract! / 4) *
                                                3
                                            }%`}{' '}
                                            - 0.75 Day
                                        </li>
                                        <li>
                                            {`${fullDayBasedOnContract! / 2}%`}{' '}
                                            - 0.5 Day
                                        </li>
                                        <li>
                                            {`${fullDayBasedOnContract! / 4}%`}{' '}
                                            - 0.25 Day
                                        </li>
                                    </ul>
                                </div>
                            </OnOutsideClick>
                        )}

                        <TypographyParagraph
                            tailwindStyle={{
                                position: 'relative',
                                textColor: 'text-utilisation-light-blue',
                                textSize: 'text-ncc-form-label'
                            }}
                        >
                            Percentage{' '}
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="cursor-pointer"
                                onClick={() => toggleAllocationHelper()}
                            />
                        </TypographyParagraph>
                    </div>
                </div>

                <FormProvider
                    {...methods}
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    formState={formState}
                    handleSubmit={allocationsFormHandleSubmit}
                >
                    <AllocatorForm
                        trigger={triggerSubmit}
                        allocations={allocations?.allocations}
                        replace={replace}
                        control={control}
                        copyLastWeeksAllocations={copyLastWeeksAllocations}
                        fields={fields}
                        activityGroups={activityGroups}
                        append={append}
                        productsArray={productsArray}
                        remove={remove}
                    ></AllocatorForm>
                </FormProvider>
            </div>
        </div>
    );
}

export default AllocationsPersonal;
export type { IAllocation, AllocationsFormValues };
