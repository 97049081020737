import {
    ButtonGroup as CoreButtonGroup,
    ButtonGroupButtonProps as CoreButtonGroupButtonProps,
    TailwindStyle,
    useButtonGroup
} from '@ncc-frontend/core';
import { ForwardedRef, PropsWithChildren, forwardRef, useMemo } from 'react';
import { merge } from 'lodash';

interface ButtonGroupButtonProps extends CoreButtonGroupButtonProps {}

function ButtonGroupButton(
    {
        children,
        id,
        tailwindStyle,
        ...restProps
    }: PropsWithChildren<ButtonGroupButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const { activeId } = useButtonGroup();
    const active = activeId === id;

    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background: active ? 'bg-ncc-white' : 'bg-ncc-grey-10',
                    borderColor: active
                        ? 'border-ncc-grey-40'
                        : 'border-ncc-grey-10',
                    textColor: active ? 'text-ncc-black' : 'text-ncc-grey-70'
                },
                tailwindStyle
            ),
        [active, tailwindStyle]
    );

    return (
        <CoreButtonGroup.Button
            {...restProps}
            ref={ref}
            id={id}
            tailwindStyle={mergedTailwindStyle}
        >
            {children}
        </CoreButtonGroup.Button>
    );
}

export default forwardRef(ButtonGroupButton);
export type { ButtonGroupButtonProps };
