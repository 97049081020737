import deDE from './de-DE.json';
import enGB from './en-GB.json';
import esES from './en-ES.json';

enum Lang {
    de = 'de',
    deDE = 'de-DE',
    en = 'en',
    enGB = 'en-GB',
    es = 'es',
    esES = 'es-ES'
}

const resources = {
    [Lang.de]: { translation: deDE },
    [Lang.deDE]: { translation: deDE },
    [Lang.en]: { translation: enGB },
    [Lang.enGB]: { translation: enGB },
    [Lang.es]: { translation: esES },
    [Lang.esES]: { translation: esES }
};

const LANGUAGES: { lang: Lang; name: string }[] = [
    { lang: Lang.deDE, name: 'Deutsch (Deutschland)' },
    { lang: Lang.enGB, name: 'English (United Kingdom)' },
    { lang: Lang.esES, name: "Spanish (Espana)" }
];

export default resources;
export { Lang, LANGUAGES };
