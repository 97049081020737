import { PropsWithChildren } from 'react';
import React from 'react';
import classNames from 'classnames';

interface DataTableSidePanelProps {
    canNextPage;
    canPreviousPage;
    children;
    className;
    getTableBodyProps;
    getTableProps;
    gotoPage;
    headerGroups;
    nextPage;
    page;
    pageCount;
    pageOptions;
    prepareRow;
    previousPage;
    setFilter;
    setGlobalFilter;
}

function DataTableSidePanel({
    children,
    className
}: PropsWithChildren<DataTableSidePanelProps>) {
    const cssClasses = classNames(
        className,
        'p-ncc-padding-24 bg-white min-w-[336px] rounded-[8px] border border-solid border-ncc-grey-30'
    );

    const childrenWithProps = React.Children?.map(children, (child) => {
        return React.cloneElement(child);
    });

    return <div className={cssClasses}>{childrenWithProps}</div>;
}

export default DataTableSidePanel;
export type { DataTableSidePanelProps };
