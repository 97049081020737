import { ChangeEvent, useRef, useState } from 'react';

import { Arrow } from './Arrow';

import { useMultiSelectContext } from './context';

export function Dropdown() {
    const { isDisabled, isExpanded, labelledBy, options, selectPrompt } =
        useMultiSelectContext();

    const [expanded, setExpanded] = useState(isExpanded);
    const [expanding, setExpanding] = useState(false);
    const [selectedText, setSelectedText] = useState(
        selectPrompt ?? 'Select...'
    );

    const ref = useRef<HTMLInputElement>(null);

    const onCheckChoice = (event: ChangeEvent<HTMLInputElement>) => {
        if (!expanded) return;

        if (expanding) {
            // Ignore the first input event when expanding as React seems to
            // trigger a change event before it's even possible for the user
            // to click anything
            //
            setExpanding(false);
        } else {
            const option = options.find((o) => o.value === event.target.id);
            if (option) option.selected = event.currentTarget.checked;

            updateSelectedText();
        }
    };

    const onClearSelection = (event: React.MouseEvent<HTMLElement>) => {
        options.forEach((o) => (o.selected = false));
        updateSelectedText();
    };

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        const newExpanded = !expanded;
        setExpanded(newExpanded);
        setExpanding(newExpanded);
    };

    const onSelectAll = (event: React.MouseEvent<HTMLElement>) => {
        options.forEach((o) => (o.selected = true));
        updateSelectedText();
    };

    const updateSelectedText = () => {
        const selectedCount = options.filter((o) => o.selected).length;
        setSelectedText(
            selectedCount > 0
                ? `${selectedCount} Selected...`
                : selectPrompt ?? 'Select...'
        );
    };

    return (
        <div className="text-base pl-1 pr-1">
            <div
                aria-disabled={isDisabled}
                aria-expanded={expanded}
                aria-labelledby={labelledBy}
                aria-readonly={true}
                className="pl-1 border border-solid rounded flex"
                ref={ref}
                tabIndex={0}
                onClick={onClick}
            >
                <div className="grow">{selectedText}</div>
                <Arrow expanded={expanded} />
            </div>
            {expanded && (
                <div>
                    <div className="h-48 overflow-y-auto">
                        <ul>
                            {options.map((option) => {
                                return (
                                    <li key={option.value}>
                                        <input
                                            type="checkbox"
                                            id={option.value}
                                            onChange={onCheckChoice}
                                            checked={option.selected}
                                        />
                                        &nbsp;
                                        <span>{option.label}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <hr />
                    <div className="flex">
                        <button
                            className="flex-1 text-left"
                            onClick={onSelectAll}
                        >
                            Select All
                        </button>
                        <button
                            className="flex-1 text-left"
                            onClick={onClearSelection}
                        >
                            Clear Selection
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
