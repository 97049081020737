import { getWeek, getYear } from 'date-fns';
import { useState } from 'react';

const useAllocations = () => {
    const today = new Date();
    const [numFiltersSelected, setNumFiltersSelected] = useState(null);
    const [week, setWeek] = useState(getWeek(new Date()));
    const [year, setYear] = useState(getYear(new Date()));
    const [month, setMonth] = useState(
        today.getMonth() + 1 < 10
            ? '0' + (today.getMonth() + 1)
            : today.getMonth() + 1
    );

    const getPermission = () => {
        const userObject = JSON.parse(localStorage.getItem('user') || '');
        const roles = userObject?.user.roles;
        if (roles.indexOf('ADMINISTRATOR') > -1) {
            return true;
        } else {
            return false;
        }
    };

    return {
        getPermission,
        month,
        numFiltersSelected,
        setMonth,
        setNumFiltersSelected,
        setWeek,
        setYear,
        week,
        year
    };
};

export default useAllocations;
