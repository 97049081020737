import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

const DataTableColumnHeaderSection = ({ headerGroups }) => {
    return (
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="border-b border-solid border-ncc-grey-20"
                >
                    {headerGroup.headers.map((column) => (
                        <th
                            {...column.getHeaderProps(
                                column.getSortByToggleProps()
                            )}
                            className="text-ncc-grey-70 text-[12px] text-left py-ncc-padding-12"
                        >
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                                {column.isSorted ? (
                                    column.isSortedDesc ? (
                                        <FontAwesomeIcon
                                            className="ml-ncc-margin-4"
                                            icon={faChevronDown}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className="ml-ncc-margin-4"
                                            icon={faChevronUp}
                                        />
                                    )
                                ) : (
                                    ''
                                )}
                            </span>
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export default DataTableColumnHeaderSection;
