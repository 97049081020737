import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-solid-svg-icons';

const MonthPicker = ({ currentFormattedDate, getNext, getPrevious }) => {
    // console.log('current formatted date:', currentFormattedDate);
    return (
        <>
            <div className="py-2 px-[24px] rounded-[8px] bg-white">
                <p>
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                    <strong>{currentFormattedDate('long')}</strong>
                </p>
            </div>
            <div className="py-2 px-1 ml-2 bg-white">
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="border-r px-2 cursor-pointer"
                    onClick={() => getPrevious()}
                    id="scrollBackwards"
                />
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className="px-2 cursor-pointer"
                    onClick={() => getNext()}
                    id="scrollForwards"
                />
            </div>
        </>
    );
};

export default MonthPicker;
