const DataTableBodySection = ({ getTableBodyProps, page, prepareRow }) => {
    return (
        <tbody {...getTableBodyProps()}>
            {page.map((row) => {
                prepareRow(row);
                return (
                    <tr
                        {...row.getRowProps()}
                        className="leading-6 border-b border-solid border-ncc-grey-20 last:border-b-0 text-[12px]"
                    >
                        {row.cells.map((cell) => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                    className="py-ncc-padding-8"
                                >
                                    {cell.render('Cell')}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export default DataTableBodySection;
