import { MsadAuthContext } from './MsadAuthProvider';
import { useContext } from 'react';

function useMsadAuth() {
    const context = useContext(
        MsadAuthContext
    );

    if (!context) throw new Error('No MSAD context!');

    return context;
}

export default useMsadAuth;