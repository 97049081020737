import { ReactElement } from 'react';
import AdminPageContainer from '../admin-page-container';
import ProductsTable from 'ui/admin/products/products-table';
import useProducts from 'core/api/hooks/products/use-products';

function ProductsPage({ collapsed }): ReactElement {
    const { data, isFetching, isLoading, refetch } = useProducts();

    return (
        <AdminPageContainer>
            <ProductsTable
                products={data}
                refetch={refetch}
                loading={isLoading || isFetching}
            ></ProductsTable>
        </AdminPageContainer>
    );
}

export default ProductsPage;
