import React, { createContext, useContext } from 'react';
import useDashboard from './useDashboard';

interface DashboardContextValue {
    currentFormattedDate;
    dashboardData;
    getNext;
    getPrevious;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
}

export const DashboardFormContext = createContext<DashboardContextValue | null>(
    null
);

const DashboardProvider: React.FC<React.ReactNode> = ({ children }) => {
    const {
        currentFormattedDate,
        dashboardData,
        getNext,
        getPrevious,
        isError,
        isFetching,
        isLoading
    } = useDashboard();

    return (
        <DashboardFormContext.Provider
            value={{
                currentFormattedDate,
                dashboardData,
                getNext,
                getPrevious,
                isError,
                isFetching,
                isLoading
            }}
        >
            {children}
        </DashboardFormContext.Provider>
    );
};

export const useDashboardContext = () => {
    const dashboardContext = useContext(DashboardFormContext);

    if (!dashboardContext)
        throw new Error('You need to use context inside of a provider');

    return dashboardContext;
};

export { DashboardProvider };
export type { DashboardContextValue };
