import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faEllipsisStroke } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
// import './Dropdown.css';

// A custom dropdown menu component that takes an array of options as props
function DropdownMenu({ options }) {
    // A state variable to store the selected option
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const [selected, setSelected] = useState<any>(null);

    // A state variable to toggle the menu visibility
    const [showMenu, setShowMenu] = useState(false);

    // A function to handle the click event on the toggle button
    const handleToggle = () => {
        setShowMenu(!showMenu);
    };

    // A function to handle the click event on an option
    const handleSelect = (option) => {
        // setSelected(option);
        option.action(option);
        setShowMenu(false);
    };

    return (
        <div className="dropdown ml-auto">
            <button className="dropdown-toggle" onClick={handleToggle}>
                <span className="dropdown-arrow">
                    {showMenu ? (
                        <FontAwesomeIcon
                            icon={faEllipsisStroke}
                            className="ml-auto cursor-pointer"
                        ></FontAwesomeIcon>
                    ) : (
                        <FontAwesomeIcon
                            icon={faEllipsis}
                            className="ml-auto cursor-pointer"
                        ></FontAwesomeIcon>
                    )}
                </span>
            </button>
            {showMenu && (
                <ul className="dropdown-menu absolute right-[12px] bg-white border border-1 rounded-[10px] drop-shadow-md">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className={`dropdown-item px-ncc-padding-12 p-ncc-padding-12 border-b last:border-b-0 cursor-pointer ${option.styles.text}`}
                            onClick={() => handleSelect(option)}
                        >
                            <span
                                className={`mr-ncc-margin-8 ${option.styles.icon}`}
                            >
                                {option.icon}
                            </span>{' '}
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default DropdownMenu;
