import { PropsWithChildren, useMemo } from 'react';
import React from 'react';
import _ from 'lodash';

import {
    TypographyParagraphProps as CoreTypographyParagraphProps,
    TailwindStyle,
    Typography
} from '@ncc-frontend/core';

interface TypographyParagraphProps extends CoreTypographyParagraphProps {}

function TypographyParagraph({
    children,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TypographyParagraphProps>) {
    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            _.merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    textSize: 'text-p',
                    textWeight: 'font-normal'
                },
                tailwindStyle
            ),
        [tailwindStyle]
    );

    return (
        <Typography.Paragraph
            tailwindStyle={mergedTailwindStyle}
            {...restProps}
        >
            {children}
        </Typography.Paragraph>
    );
}

export default TypographyParagraph;
export type { TypographyParagraphProps };
