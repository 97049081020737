/* eslint-disable sort-keys-fix/sort-keys-fix */
import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';

interface ModalProps extends CommonProps {
    size?: 's' | 'm' | 'l' | 'xl';
}

function Modal({
    children,
    className,
    size,
    ...restProps
}: PropsWithChildren<ModalProps>): ReactElement | null {
    const cssClasses = classNames(
        'max-h-4/5',
        'max-w-full',
        'flex flex-col',
        'bg-white rounded-modal shadow text-utilisation-grey-70',
        'animate-modal-in',
        {
            'w-96': size === 's',
            'w-120': size === 'm',
            'w-162': size === 'l',
            'w-[640px]': size === 'xl'
        },
        className
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default Modal;
export type { ModalProps };
