import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import useLineManagersList from 'core/api/hooks/teams/use-line-managers-list';

interface ISelectProps {
    label: string;
    value: string;
}

const LineManagerSelector = ({ name, val }) => {
    const [managers, setManagers] = useState<ISelectProps[] | undefined>(
        undefined
    );
    const { control, register, setValue } = useFormContext();
    const {
        data: lineManagers,
        isFetching,
        isLoading
    } = useLineManagersList({
        role: 'LINE_MANAGER'
    });

    useEffect(() => {
        const formattedLineManagers = lineManagers?.map((mgr) => {
            return {
                label: `${mgr.given_name} ${mgr.family_name}`,
                value: mgr.uuid
            };
        });
        setManagers(formattedLineManagers);
    }, [lineManagers]);

    return (
        <div>
            {isLoading || isFetching ? (
                <p>Loading...</p>
            ) : (
                <Controller
                    defaultValue={''}
                    name={name}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, value, ...field } }) => (
                        <>
                            <label>
                                Line Manager
                                <Select
                                    {...register(name)}
                                    {...field}
                                    ref={ref}
                                    onChange={(selected, item) => {
                                        setValue('line_manager', selected, {
                                            shouldDirty: true
                                        });
                                    }}
                                    options={managers}
                                    defaultValue={{
                                        label: value
                                            ? `${value.given_name} ${value.family_name}`
                                            : '',
                                        value: value ? value.uuid : ''
                                    }}
                                    isDisabled={false}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isDisabled
                                                ? '#F3F4F8'
                                                : '#ffffff'
                                        }),
                                        multiValueLabel: (base, props) => ({
                                            ...base,
                                            cursor: props.isDisabled
                                                ? 'not-allowed'
                                                : 'default'
                                        })
                                    }}
                                />
                            </label>
                        </>
                    )}
                />
            )}
        </div>
    );
};

export default LineManagerSelector;
