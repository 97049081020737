import {
    DetailedHTMLProps,
    ForwardedRef,
    InputHTMLAttributes,
    forwardRef
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

interface SearchBarProps
    extends DetailedHTMLProps<
            InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >,
        TailwindProps {
    onChange;
}

function SearchBar(
    {
        className,
        onChange,
        placeholder,
        tailwindStyle,
        ...restProps
    }: SearchBarProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    const cssClasses = classNames(
        tailwindClasses(
            {
                height: 'h-10',
                position: 'relative',
                textSize: 'text-sm',
                width: 'max-w-xl'
            },
            tailwindStyle
        ),
        className
    );

    return (
        <div className={cssClasses}>
            <input
                className="h-full w-full px-6 rounded-lg pl-9"
                type="search"
                onChange={onChange}
                placeholder={placeholder ? placeholder : 'Search ...'}
            />
            <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 -translate-y-1/2 text-escrow-grey-60"
            />
        </div>
    );
}

export default forwardRef(SearchBar);
export type { SearchBarProps };
