import {
    ButtonHTMLAttributes,
    DetailedHTMLProps,
    PropsWithChildren
} from 'react';
import {
    TailwindProps,
    TailwindStyle
} from '../../../components/tailwind-props';
import classNames from 'classnames';
import tailwindClasses from '../../../components/tailwind-classes';

interface CalendarItemProps
    extends Omit<
            DetailedHTMLProps<
                ButtonHTMLAttributes<HTMLButtonElement>,
                HTMLButtonElement
            >,
            'disabled'
        >,
        TailwindProps {
    active?: boolean;
    constrain: 'future' | 'past' | 'unrestricted';
    date: Date;
    highlight: 'full' | 'left' | 'right' | undefined;
    highlightClassName?: string;
    highlightTailwindStyle?: TailwindStyle;
    today: Date;
}

function CalendarItem({
    active,
    children,
    className,
    constrain,
    date,
    highlight,
    highlightClassName,
    highlightTailwindStyle,
    style,
    tailwindStyle,
    today,
    ...restProps
}: PropsWithChildren<CalendarItemProps>) {
    const disabled =
        constrain === 'unrestricted'
            ? false
            : constrain === 'future'
            ? date < today
            : date > today;

    const cssClasses = classNames(
        tailwindClasses(
            {
                background: active && 'bg-utilisation-tertiary-blue',
                borderRadius: 'rounded-lg',
                display: 'flex',
                items: 'items-center',
                justify: 'justify-center',
                padding: 'p-1',
                position: 'relative',
                select: 'select-none',
                textColor: active && 'text-utilisation-primary-blue',
                transition: 'transition-colors',
                variant: {
                    disabled: {
                        background: 'disabled:bg-ncc-grey-20',
                        textColor: 'disabled:text-ncc-grey-70'
                    },
                    hover: {
                        background: 'hover:bg-utilisation-accent-blue',
                        textColor: 'hover:text-ncc-white'
                    }
                },
                zIndex: 'z-1'
            },
            tailwindStyle
        ),
        className
    );

    const highlightCssClasses = classNames(
        tailwindClasses(
            {
                background: !!highlight
                    ? 'bg-utilisation-tertiary-blue'
                    : 'bg-transparent',
                display: 'absolute',
                height: 'h-6',
                left: highlight === 'right' ? 'left-1/2' : 'left-0',
                right: highlight === 'left' ? 'right-1/2' : 'right-0',
                top: 'top-1/2',
                translateY: '-translate-y-1/2',
                zIndex: 'z-0'
            },
            highlightTailwindStyle
        ),
        highlightClassName
    );

    return (
        <div className="relative flex items-center justify-center px-0.5">
            <div className={highlightCssClasses} data-testid="highlight" />
            <button className={cssClasses} {...restProps} disabled={disabled}>
                {children}
            </button>
        </div>
    );
}

export default CalendarItem;
export type { CalendarItemProps };
