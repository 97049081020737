import { ReactElement } from 'react';
import PageLoader from 'components/loaders/page-loader';

import { getWeek, getYear } from 'date-fns';
import { useAllocationsContext } from 'ui/allocations/allocations-context';
import TeamsTable from 'ui/admin/teams/teams-table';
import useTeamsData from 'core/api/hooks/teams/use-teams-data';

interface TeamsPageProps {}

function TeamsPage({}): ReactElement {
    const { week } = useAllocationsContext();
    const y = getYear(new Date());
    const dateParam = `${y}-${week ? week : getWeek(new Date())}`;

    const {
        data: teamsList,
        isFetching,
        isLoading
    } = useTeamsData({
        only_incomplete: false,
        week: dateParam
    });

    return isFetching || isLoading ? (
        <div className="flex items-center justify-center h-full">
            <PageLoader
                suppressBackground={true}
                type="fast"
                text={'Loading teams...'}
            ></PageLoader>
        </div>
    ) : (
        <TeamsTable users={teamsList}></TeamsTable>
    );
}

export default TeamsPage;
export type { TeamsPageProps };
