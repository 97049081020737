import { ComponentType, useMemo } from 'react';

import { CalendarItemProps } from './calendar-item';
import CalendarGrid from './calendar-grid';
import Year, { YearProps } from './year';

const RANGE_SIZE = 12;

function getYearRange(year: number) {
    const rangeStart = Math.floor(year / RANGE_SIZE) * RANGE_SIZE;
    return [...new Array(RANGE_SIZE).keys()].map((item) => item + rangeStart);
}

interface YearPickerProps {
    activeDates: [Date | undefined, Date | undefined];
    calendarItemComponent: ComponentType<CalendarItemProps>;
    constrain: 'future' | 'past' | 'unrestricted';
    onSelect: YearProps['onSelect'];
    value: number;
}

function YearPicker({
    activeDates,
    calendarItemComponent: CalendarItemComponent,
    constrain,
    onSelect,
    value
}: YearPickerProps) {
    const years = useMemo<Pick<YearProps, 'value'>[]>(
        () => getYearRange(value).map((year) => ({ value: year })),
        [value]
    );

    return (
        <CalendarGrid className="grid-cols-3">
            {years.map((yearProps) => (
                <Year
                    key={yearProps.value}
                    {...yearProps}
                    onSelect={onSelect}
                    constrain={constrain}
                    activeDates={activeDates}
                    calendarItemComponent={CalendarItemComponent}
                />
            ))}
        </CalendarGrid>
    );
}

export default YearPicker;
export { RANGE_SIZE, getYearRange };
export type { YearPickerProps };
