import Alert from 'components/alert/alert';
import DataTableBodySection from './data-table-body-section';
import DataTableColumnHeaderSection from './data-table-column-header-section';
import DataTableCustomFilters from './data-table-custom-filters';
import DataTablePagination from './data-table-pagination';
import DataTableResultsTotal from './data-table-results-total';

interface IDataTableProps {
    canNextPage;
    canPreviousPage;
    columns;
    context;
    data;
    getTableBodyProps;
    getTableProps;
    gotoPage;
    headerGroups;
    nextPage;
    numFiltersSelected?;
    page;
    pageCount;
    pageIndex;
    pageOptions;
    prepareRow;
    previousPage;
    setFilter;
    setGlobalFilter;
}

export default function DataTable({
    canNextPage,
    canPreviousPage,
    data,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage
}: IDataTableProps) {
    return (
        <div className="w-full">
            <div className="flex items-center flex-1 mb-ncc-margin-12">
                <DataTableCustomFilters
                    getTableProps={getTableProps}
                    headerGroups={headerGroups}
                ></DataTableCustomFilters>
            </div>

            <div className="flex justify-end items-center mb-ncc-margin-12">
                <DataTableResultsTotal
                    total={data.length}
                ></DataTableResultsTotal>
            </div>

            <div className="bg-white p-[24px] rounded-[8px]  w-full">
                <table {...getTableProps()} className="w-full">
                    <DataTableColumnHeaderSection
                        headerGroups={headerGroups}
                    ></DataTableColumnHeaderSection>

                    <DataTableBodySection
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                    />
                </table>
                {page.length === 0 && (
                    <Alert
                        variant="warning"
                        className="w-full rounded-[8px] my-ncc-margin-12"
                    >
                        No results to show
                    </Alert>
                )}
            </div>
            <DataTablePagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
            />
        </div>
    );
}
