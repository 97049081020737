/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UserAuthentication } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Oauth2Controller<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags auth
     * @name OauthAccessToken
     * @summary Exchanges a valid oauth2 auth code for an API access token and refresh token, which are set into cookies int he response. (see BER-121)
     * @request GET:/oauth2/token
     */
    oauthAccessToken = (params: RequestParams = {}) =>
        this.request<UserAuthentication, any>({
            path: `/oauth2/token?redirect_uri=${process.env.REACT_APP_BASE_URL}/oauth2/auth`,
            method: 'GET',
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags auth
     * @name OauthRefreshToken
     * @summary Exchanges a valid oauth2 API refresh token for a _new_ API access token and refresh token, which are set into cookies int he response. (see BER-121)
     * @request GET:/oauth2/refresh
     */
    oauthRefreshToken = (params: RequestParams = {}) =>
        this.request<UserAuthentication, any>({
            path: `/oauth2/refresh`,
            method: 'GET',
            format: 'json',
            ...params
        });
}
