import { PropsWithChildren } from 'react';

interface ErrorPanelProps {
    code: number;
}

const MESSAGES = {
    401: "Unfortunately it looks like you don't have permission to access this resource. Please contact your administrator.",
    403: 'Access to this resource is forbidden.',
    404: 'The page you are looking for could not be found.',
    500: 'Server error.'
};

const TITLES = {
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Page not found',
    500: 'Server error'
};

function getErrorMessage(code) {
    if (code === 500) {
        return {
            message: MESSAGES[500],
            title: TITLES[500]
        };
    } else if (code === 401) {
        return {
            message: MESSAGES[401],
            title: TITLES[401]
        };
    } else if (code === 403) {
        return {
            message: MESSAGES[403],
            title: TITLES[403]
        };
    } else {
        return {
            message: MESSAGES[404],
            title: TITLES[404]
        };
    }
}

function ErrorPanel({ children, code }: PropsWithChildren<ErrorPanelProps>) {
    const errorObject = getErrorMessage(code);

    return (
        <div className="error-panel h-full w-full">
            <div className=" w-full h-full flex items-center justify-center">
                <div className="flex flex-col gap-2.5 items-start">
                    <h2 className="text-8xl font-bold text-ncc-group-purple-100">
                        {code}
                    </h2>
                    <h4 className="text-3xl font-semibold text-ncc-group-purple-100">
                        {errorObject.title}
                    </h4>
                    <p>{errorObject.message}</p>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ErrorPanel;
export type { ErrorPanelProps };
