import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import TeamsEditModal from './teams-edit-modal';

function TeamsActionRenderer({ context: { push, refetch }, ...props }) {
    const viewUser = useCallback(() => {
        push(TeamsEditModal, {
            data: props['row'].original,
            refetch
        });
    }, [props, push, refetch]);

    return (
        <div className="flex h-full items-center gap-2.5">
            <Button onClick={viewUser} variant="tertiary">
                <FontAwesomeIcon icon={faPencil} />
                Edit
            </Button>
        </div>
    );
}

export default TeamsActionRenderer;
