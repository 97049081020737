import { useQuery } from 'react-query';

import { AppController } from 'core/api/autogenerated/App';
import { Product } from 'core/api/autogenerated/data-contracts';
import interceptors from 'core/api/interceptors';

interface DashboardListResponseViewModel {
    end_date: string;
    products: Product[];
    start_date: string;
}

function useDashboardData(query?) {
    return useQuery(['allocations', 'list', { query }], async () => {
        const controller = new AppController({
            interceptors
        });

        const response = await controller.getAdminDashboard(query);
        return response.data;
    });
}

export default useDashboardData;
export type { DashboardListResponseViewModel };
