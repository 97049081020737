import { useMemo } from 'react';
import classNames from 'classnames';

interface PercentageComponentProps {
    size?: string;
    value?: number;
}

function PercentageComponent({
    size = 'p',
    value = 0,
    ...restProps
}: PercentageComponentProps) {
    const cssClasses = useMemo(
        () => classNames(`text-${size} ml-auto`, { ...restProps }),
        [restProps, size]
    );
    return <div className={cssClasses}>{`${value}%`}</div>;
}

export default PercentageComponent;
