import * as yup from 'yup';
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './modal.css';
import { AllocationsProvider } from 'ui/allocations/allocations-context';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Modal, ResultModal } from 'components';
import { isUndefined } from 'lodash';
import { returnActivityGroups } from 'helpers/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { yupResolver } from '@hookform/resolvers/yup';
import AllocatorForm from 'ui/allocations-allocator/allocator-form';
import PageLoader from 'components/loaders/page-loader';
import TypographyHeader from 'components/typography/typography-header';
import allocationPercentages from 'common/allocation-percentages';
import usePostAllocation from 'core/api/hooks/teams/use-post-new-admin-allocation';
import useProducts from 'core/api/hooks/products/use-products';
import useUserDetails from 'core/api/hooks/admin/use-user-details';

let renderCount = 0;

function TeamsModal({ config }) {
    const [activityGroups, setActivityGroups] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const {
        data: user,
        isFetching: isFetchingUser,
        isLoading: isLoadingUser
    } = useUserDetails(config.summary.user?.uuid);

    useEffect(() => {
        const optionGroups = returnActivityGroups(user?.contract.day_rate.activity_groups)
        setActivityGroups(optionGroups);
    }, [user?.contract.day_rate.activity_groups]);

    const { isError, mutate: postAllocation } = usePostAllocation({
        onError: (err) => {
            // console.log('error is:', err.response.data.detail);
            setErrorMessages(err?.response?.data?.detail);
        },
        onSuccess: () => {
            pop();
            push(ResultModal, {
                description: 'Your changes have been successfully saved',
                title: 'Changes updated',
                variant: 'success'
            });
        }
    });

    const { pop, push } = useModals();
    const schema = yup.object({
        allocations: yup.array(),
        user_uuid: yup.string(),
        week: yup.string()
    });

    const {
        control,
        formState,
        handleSubmit: allocationsFormHandleSubmit,
        setError,
        setValue,
        ...methods
    } = useForm({
        defaultValues: {
            allocations: config.allocations
        },
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const { append, fields, prepend, remove, replace } = useFieldArray({
        control,
        name: 'allocations'
    });

    const copyLastWeeksAllocations = useCallback(
        (e) => {
            e.preventDefault();
            replace(config.previous_week_allocations);
        },
        [config.previous_week_allocations, replace]
    );

    const submit = useCallback(
        (data) => {
            const filteredAllocations = data.allocations.map((a, i) => {
                if (a.product !== null || a.percentage !== undefined) {
                    return {
                        activity: a.activity,
                        contract_uuid: user?.contract?.uuid,
                        percentage: isNaN(a.percentage)
                            ? a.percentage.value
                            : a.percentage,
                        product_uuid:
                            a.product && a.product.uuid
                                ? a.product.uuid
                                : a.product_uuid
                    };
                } else {
                    setErrorMessages('Please check your selections');
                    return false;
                }
            });

            const payload = {
                allocations: filteredAllocations,
                user_uuid: config.summary.user.uuid,
                week: config.summary.week
            };

            postAllocation({
                body: payload
            });
        },
        [
            config.summary.user.uuid,
            config.summary.week,
            postAllocation,
            user?.contract?.uuid
        ]
    );

    const { data: products } = useProducts();

    const handleDiscard = useCallback(
        (event) => {
            event.preventDefault();
            pop();
        },
        [pop]
    );

    const showError = (data) => {
        console.log('Errors in the showError:', data);
    };

    const triggerSubmit = useCallback(() => {
        allocationsFormHandleSubmit(submit, showError)();
    }, [allocationsFormHandleSubmit, submit]);

    const productsArray = products?.map((product) => {
        return {
            label: product.name,
            value: product.uuid
        };
    });

    renderCount++;

    return isFetchingUser || isLoadingUser ? (
        <div className="flex items-center justify-center h-full">
            <PageLoader
                suppressBackground={true}
                type="fast"
                text={'Loading allocations for user...'}
            ></PageLoader>
        </div>
    ) : (
        <AllocationsProvider>
            <Modal className="p-ncc-padding-24" size="xl">
                <Modal.Header>
                    <TypographyHeader level={3} shadow={false}>
                        {`${config.summary.user.given_name} ${config.summary.user.family_name}`}
                    </TypographyHeader>
                </Modal.Header>
                {isError &&
                    errorMessages.map((err, ind) => {
                        return (
                            <p>Alert component in here</p>
                            // <Alert
                            //     index={ind}
                            //     variant="error"
                            //     className="w-full rounded-[8px] my-ncc-margin-12"
                            // >
                            //     {/* {err.msg} */}
                            //     Please check your selections
                            // </Alert>
                        );
                    })}
                <Modal.Body className="flex flex-col overflow-visible">
                    <FormProvider
                        {...methods}
                        control={control}
                        setValue={setValue}
                        setError={setError}
                        formState={formState}
                        handleSubmit={allocationsFormHandleSubmit}
                    >
                        <AllocatorForm
                            activityGroups={activityGroups}
                            append={append}
                            allocationList={allocationPercentages}
                            control={control}
                            replace={replace}
                            copyLastWeeksAllocations={copyLastWeeksAllocations}
                            fields={fields}
                            handleDiscard={handleDiscard}
                            trigger={triggerSubmit}
                            prepend={prepend}
                            productsArray={productsArray}
                            remove={remove}
                        ></AllocatorForm>
                    </FormProvider>
                </Modal.Body>
            </Modal>
        </AllocationsProvider>
    );
}

export default TeamsModal;
