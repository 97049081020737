import { UserWithContractAndDayRate } from 'core/api/autogenerated/data-contracts';
import ActionsCellRender from './action-cell-renderer';
import AdminPageHeader from 'components/page-headers/admin-page-header';
import DataTableContainer from 'components/data-table/data-table-container';
import PageLoader from 'components/loaders/page-loader';
import UserNameCellRender from './user-name-renderer';
import UsersTableStatusCellRenderer from './users-table-status-cell-renderer';
interface UsersTableProps {
    data: UserWithContractAndDayRate[] | undefined;
    loading: boolean;
}

const UsersTable = ({ data, loading }: UsersTableProps) => {
    const columns = [
        {
            Cell: (props) => {
                return <UserNameCellRender context={null} {...props} />;
            },
            Header: 'User',
            accessor: 'username',
            id: 'username'
        },
        {
            Header: 'Role',
            accessor: 'roles',
            id: 'roles'
        },
        {
            Header: 'Title',
            accessor: 'contract.day_rate.name',
            id: 'title'
        },
        {
            Header: 'Contract hours',
            accessor: 'contract.contracted_hours',
            id: 'contracted_hours'
        },
        {
            Cell: (props) => {
                return (
                    <UsersTableStatusCellRenderer context={null} {...props} />
                );
            },
            Header: 'Status'
        },

        {
            Cell: (props) => {
                return <ActionsCellRender context={null} {...props} />;
            },
            Header: 'Actions'
        }
    ];

    return (
        <>
            {loading ? (
                <div className="flex items-center justify-center h-full">
                    <PageLoader
                        suppressBackground={true}
                        type="fast"
                        text={'Loading users...'}
                    ></PageLoader>
                </div>
            ) : (
                <div>
                    <AdminPageHeader pageTitle="User Management"></AdminPageHeader>
                    <DataTableContainer
                        context={null}
                        columns={columns}
                        data={data ? data : []}
                    />
                </div>
            )}
        </>
    );
};

export default UsersTable;
