import {
    ComponentType,
    MouseEventHandler,
    useCallback,
    useMemo,
    useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailwindProps } from '@ncc-frontend/core';
import { cloneDeep } from 'lodash';
import {
    faLongArrowLeft,
    faLongArrowRight
} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Button, { ButtonProps } from 'components/button/button';
import ButtonGroup, {
    ButtonGroupType
} from 'components/button-group/button-group';
import CalendarItem, { CalendarItemProps } from './calendar-item';
import DayPicker, { DayPickerProps } from './day-picker';
import MonthPicker, { MonthPickerProps } from './month-picker';
import YearPicker, {
    RANGE_SIZE,
    YearPickerProps,
    getYearRange
} from './year-picker';
import dateFormatter from 'common/formatter/date/date-formatter';
import tailwindClasses from 'components/tailwind-classes';

interface DatePickerWizardProps<TButtonProps extends ButtonProps = ButtonProps>
    extends TailwindProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buttonAllDatesProps?: any;
    buttonComponent?: ComponentType<TButtonProps>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buttonConfirmProps?: any;
    buttonGroupComponent?: ButtonGroupType;
    calendarItemComponent?: ComponentType<CalendarItemProps>;
    close?;
    constrain?: 'future' | 'past' | 'unrestricted';
    locale?: string;
    nextClassName?: string;
    onSelect: (date: Date | [Date, Date] | undefined) => void;
    previousClassName?: string;
    range?: boolean;
    readOnly?: boolean;
    /** TODO: implement */
    resolution?: 'day' | 'month' | 'year';
    setParentTitle?;
    setSelected?;
    suppressAllDates?: boolean;
    translations?: {
        allDates: string;
        confirm: string;
        confirmRange: string;
        day: string;
        fromDate: string;
        month: string;
        readonlyConfirm: string;
        toDate: string;
        year: string;
    };
    /** Timestamp format  */
    value?: string | readonly string[];
}

function DatePickerWizard<TButtonProps extends ButtonProps = ButtonProps>({
    buttonAllDatesProps,
    buttonComponent: ButtonComponent = Button as ComponentType<TButtonProps>,
    buttonConfirmProps,
    buttonGroupComponent: ButtonGroupComponent = ButtonGroup,
    calendarItemComponent: CalendarItemComponent = CalendarItem,
    className,
    close,
    constrain = 'unrestricted',
    locale = 'en-GB',
    nextClassName,
    onSelect,
    previousClassName,
    range = false,
    readOnly = false,
    resolution = 'day',
    setParentTitle,
    setSelected,
    suppressAllDates,
    tailwindStyle,
    translations = {
        allDates: 'All Dates',
        confirm: 'Confirm',
        confirmRange: 'Confirm Date Range',
        day: 'Day',
        fromDate: 'Start Date',
        month: 'Month',
        readonlyConfirm: 'Close',
        toDate: 'End Date',
        year: 'Year'
    },
    value,
    ...restProps
}: DatePickerWizardProps<TButtonProps>) {
    const cssClasses = classNames(
        tailwindClasses(
            {
                background: 'bg-ncc-white',
                border: 'border',
                borderRadius: 'rounded-lg',
                display: 'flex',
                flexDirection: 'flex-col',
                gap: 'gap-4',
                padding: 'p-4',
                textColor: 'text-ncc-grey-70',
                textSize: 'text-[12px]'
            },
            tailwindStyle
        ),
        className
    );

    const arrowsCssClasses = classNames('disabled:cursor-not-allowed');
    const mergedPreviousCssClasses = classNames(
        arrowsCssClasses,
        previousClassName
    );
    const mergedNextCssClasses = classNames(arrowsCssClasses, nextClassName);

    const [rangeStep, setRangeStep] = useState<0 | 1>(0);
    const [selectedDates, setSelectedDates] = useState<
        [Date | undefined, Date | undefined]
    >([
        !!value && range ? new Date(value[0]) : undefined,
        !!value && range ? new Date(value[1]) : undefined
    ]);
    const [currentDate, setCurrentDate] = useState(
        selectedDates[rangeStep] ?? !value
            ? new Date()
            : range
            ? new Date(value[0])
            : new Date(value as string)
    );

    /** Controls view page resolution */
    const [viewResolution, setViewResolution] = useState<
        'day' | 'month' | 'year'
    >('day');

    const title = useMemo(() => {
        if (viewResolution === 'day') {
            const str = currentDate.toLocaleString(locale, {
                month: 'long',
                year: 'numeric'
            });
            setParentTitle(str);

            return str;
        } else if (viewResolution === 'month') {
            return currentDate.getFullYear();
        } else {
            const range = getYearRange(currentDate.getFullYear());
            return `${range[0]} - ${range[range.length - 1]}`;
        }
    }, [currentDate, locale, setParentTitle, viewResolution]);

    const getNextMonth = useCallback(
        (date: Date) =>
            new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()),
        []
    );

    const nextMonth = useCallback(() => {
        setCurrentDate((prev) => getNextMonth(prev));
    }, [getNextMonth]);

    const getPreviousMonth = useCallback(
        (date: Date) =>
            new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()),
        []
    );

    const previousMonth = useCallback(() => {
        setCurrentDate((prev) => getPreviousMonth(prev));
    }, [getPreviousMonth]);

    const getNextYear = useCallback(
        (date: Date) =>
            new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()),
        []
    );

    const nextYear = useCallback(() => {
        setCurrentDate((prev) => getNextYear(prev));
    }, [getNextYear]);

    const getPreviousYear = useCallback(
        (date: Date) =>
            new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()),
        []
    );

    const previousYear = useCallback(() => {
        setCurrentDate((prev) => getPreviousYear(prev));
    }, [getPreviousYear]);

    const getNextYearRange = useCallback(
        (date: Date) =>
            new Date(
                date.getFullYear() + RANGE_SIZE,
                date.getMonth(),
                date.getDate()
            ),
        []
    );

    const nextYearRange = useCallback(() => {
        setCurrentDate((prev) => getNextYearRange(prev));
    }, [getNextYearRange]);

    const getPreviousYearRange = useCallback(
        (date: Date) =>
            new Date(
                date.getFullYear() - RANGE_SIZE,
                date.getMonth(),
                date.getDate()
            ),
        []
    );

    const previousYearRange = useCallback(() => {
        setCurrentDate((prev) => getPreviousYearRange(prev));
    }, [getPreviousYearRange]);

    const goPrevious = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        switch (viewResolution) {
            case 'day':
                previousMonth();
                break;
            case 'month':
                previousYear();
                break;
            case 'year':
                previousYearRange();
                break;
        }
    }, [previousMonth, previousYear, previousYearRange, viewResolution]);

    const goNext = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        switch (viewResolution) {
            case 'day':
                nextMonth();
                break;
            case 'month':
                nextYear();
                break;
            case 'year':
                nextYearRange();
                break;
        }
    }, [nextMonth, nextYear, nextYearRange, viewResolution]);

    const getPreviousDisabled = useCallback(() => {
        if (constrain === 'unrestricted' || constrain === 'past') return false;

        const today = new Date();
        switch (viewResolution) {
            case 'day':
                today.setDate(1);
                return getPreviousMonth(currentDate) <= today;
            case 'month':
                today.setDate(1);
                today.setMonth(0);
                return getPreviousYear(currentDate) <= today;
            case 'year': {
                const previousRange = getYearRange(
                    getPreviousYearRange(currentDate).getFullYear()
                );
                return (
                    previousRange[previousRange.length - 1] <=
                    today.getFullYear()
                );
            }
        }
    }, [
        constrain,
        currentDate,
        getPreviousMonth,
        getPreviousYear,
        getPreviousYearRange,
        viewResolution
    ]);

    const getNextDisabled = useCallback(() => {
        if (constrain === 'unrestricted' || constrain === 'future')
            return false;

        const today = new Date();
        switch (viewResolution) {
            case 'day':
                today.setDate(currentDate.getDate());
                return getNextMonth(currentDate) >= today;
            case 'month':
                today.setDate(currentDate.getDate());
                today.setMonth(currentDate.getMonth());
                return getNextYear(currentDate) >= today;
            case 'year': {
                const nextRange = getYearRange(
                    getNextYearRange(currentDate).getFullYear()
                );
                return nextRange[0] >= today.getFullYear();
            }
        }
    }, [
        constrain,
        currentDate,
        getNextMonth,
        getNextYear,
        getNextYearRange,
        viewResolution
    ]);

    const setViewResolutionDay = useCallback<
        MouseEventHandler<HTMLButtonElement>
    >(() => {
        setViewResolution('day');
    }, []);

    const setViewResolutionMonth = useCallback<
        MouseEventHandler<HTMLButtonElement>
    >(() => {
        setViewResolution('month');
    }, []);

    const setViewResolutionYear = useCallback<
        MouseEventHandler<HTMLButtonElement>
    >(() => {
        setViewResolution('year');
    }, []);

    const handleDaySelected = useCallback<DayPickerProps['onSelect']>(
        (selectedDate) => {
            if (readOnly) return;

            setSelectedDates((prev) => {
                const next: [Date | undefined, Date | undefined] = cloneDeep([
                    ...prev
                ]);

                if (rangeStep === 0) {
                    next[0] = selectedDate;
                    next[1] = undefined;
                    if (range) setRangeStep(1);
                } else if (selectedDate < (next[0] as Date)) {
                    // End date is before start date
                    // so set it as start date instead.
                    next[0] = selectedDate;
                    next[1] = undefined;
                } else {
                    next[1] = selectedDate;
                    setRangeStep(0);
                }

                return next;
            });
        },
        [range, rangeStep, readOnly]
    );

    const handleMonthSelected = useCallback<MonthPickerProps['onSelect']>(
        (selectedMonth) => {
            setCurrentDate(
                (prev) => new Date(prev.getFullYear(), selectedMonth)
            );
            setViewResolution('day');
        },
        []
    );

    const handleYearSelected = useCallback<YearPickerProps['onSelect']>(
        (selectedYear) => {
            setCurrentDate(() => new Date(selectedYear, 0));
            setViewResolution('month');
        },
        []
    );

    // const rangeButtonTailwindStyle = useMemo<TailwindStyle>(
    //     () => ({
    //         display: 'grid',
    //         height: '',
    //         items: 'items-end',
    //         padding: 'px-3 py-1',
    //         rows: 'grid-rows-2'
    //     }),
    //     []
    // );

    const confirm = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.stopPropagation();

            if (range) {
                onSelect(selectedDates as [Date, Date]);
            } else {
                onSelect(selectedDates[0] as Date);
            }

            const startDate = dateFormatter(selectedDates[0], 'DD-MMMM-YYYY');
            const endDate = dateFormatter(selectedDates[1], 'DD-MMMM-YYYY');

            setParentTitle(`${startDate} - ${endDate}`);

            close(false);
        },
        [close, onSelect, range, selectedDates, setParentTitle]
    );

    return (
        <div {...restProps} className={cssClasses}>
            <div className="flex items-center justify-center gap-4 text-[14px] text-ncc-grey-100">
                <button
                    className={mergedPreviousCssClasses}
                    onClick={goPrevious}
                    disabled={getPreviousDisabled()}
                >
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                </button>
                {title}
                <button
                    className={mergedNextCssClasses}
                    onClick={goNext}
                    disabled={getNextDisabled()}
                >
                    <FontAwesomeIcon icon={faLongArrowRight} />
                </button>
            </div>
            <div className="ml-auto mr-auto">
                {viewResolution === 'day' ? (
                    <DayPicker
                        value={currentDate}
                        locale={locale}
                        onSelect={handleDaySelected}
                        constrain={'past'}
                        activeDates={selectedDates}
                        calendarItemComponent={CalendarItemComponent}
                    />
                ) : viewResolution === 'month' ? (
                    <MonthPicker
                        year={currentDate.getFullYear()}
                        locale={locale}
                        onSelect={handleMonthSelected}
                        constrain={constrain}
                        activeDates={selectedDates}
                        calendarItemComponent={CalendarItemComponent}
                    />
                ) : (
                    <YearPicker
                        value={currentDate.getFullYear()}
                        onSelect={handleYearSelected}
                        constrain={constrain}
                        activeDates={selectedDates}
                        calendarItemComponent={CalendarItemComponent}
                    />
                )}
            </div>
            <div className="flex">
                <ButtonGroupComponent activeId={viewResolution}>
                    <ButtonGroupComponent.Button
                        id="day"
                        onClick={setViewResolutionDay}
                        className="flex-1"
                    >
                        {translations.day}
                    </ButtonGroupComponent.Button>
                    <ButtonGroupComponent.Button
                        id="month"
                        onClick={setViewResolutionMonth}
                        className="flex-1"
                    >
                        {translations.month}
                    </ButtonGroupComponent.Button>
                    <ButtonGroupComponent.Button
                        id="year"
                        onClick={setViewResolutionYear}
                        className="flex-1"
                    >
                        {translations.year}
                    </ButtonGroupComponent.Button>
                </ButtonGroupComponent>
            </div>
            <div className="flex">
                <ButtonComponent
                    {...buttonConfirmProps}
                    onClick={confirm}
                    disabled={
                        range
                            ? !selectedDates[0] ||
                              !selectedDates[1] ||
                              selectedDates[1].getTime() -
                                  selectedDates[0].getTime() <
                                  0
                            : !selectedDates[0]
                    }
                    className="flex-1"
                >
                    {readOnly
                        ? translations.readonlyConfirm
                        : range
                        ? translations.confirmRange
                        : translations.confirm}
                </ButtonComponent>
            </div>
        </div>
    );
}

export default DatePickerWizard;
export type { DatePickerWizardProps };
