import React, { createContext, useContext } from 'react';
import useAllocations from './useAllocations';
interface AllocationsContextValue {
    getPermission;
    month;
    numFiltersSelected;
    setMonth;
    setNumFiltersSelected;
    setWeek;
    setYear;
    week;
    year;
}

export const AllocationsContext = createContext<AllocationsContextValue | null>(
    null
);

const AllocationsProvider: React.FC<React.ReactNode> = ({ children }) => {
    const {
        getPermission,
        month,
        numFiltersSelected,
        setMonth,
        setNumFiltersSelected,
        setWeek,
        setYear,
        week,
        year
    } = useAllocations();

    return (
        <AllocationsContext.Provider
            value={{
                getPermission,
                month,
                numFiltersSelected,
                setMonth,
                setNumFiltersSelected,
                setWeek,
                setYear,
                week,
                year
            }}
        >
            {children}
        </AllocationsContext.Provider>
    );
};

export const useAllocationsContext = () => {
    const allocationsContext = useContext(AllocationsContext);

    if (!allocationsContext)
        throw new Error('You need to use context inside of a provider');

    return allocationsContext;
};

export { AllocationsProvider };
export type { AllocationsContextValue };
