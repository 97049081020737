import { Typography } from 'components';
import SiderSection from './sider-section';

export interface INavSectionProps {
    headline: string;
    links: {
        canView: boolean | undefined;
        icon: JSX.Element;
        text: string;
        to: string;
    }[];
}

export interface INavItemProps {
    collapsed: boolean | undefined;
    section: INavSectionProps;
}

export function SiderNavItem({ collapsed, section }: INavItemProps) {
    return (
        <div>
            {!collapsed && (
                <Typography.Header
                    level={6}
                    shadow={false}
                    className="text-[10px] uppercase text-utilisation-turquoise-light mt-ncc-margin-24"
                >
                    {section.headline}
                </Typography.Header>
            )}

            <SiderSection links={section.links} />
        </div>
    );
}

export default SiderNavItem;
