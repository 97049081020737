import { useModals } from '@ncc-frontend/core';
import { useState } from 'react';

const useProductReports = () => {
    const { push } = useModals();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reportData, setReportData] = useState();
    const [noReportsAvailable, setNoReportsAvailable] = useState(false);

    return {
        endDate,
        noReportsAvailable,
        push,
        reportData,
        setEndDate,
        setNoReportsAvailable,
        setReportData,
        setStartDate,
        startDate
    };
};

export default useProductReports;
