import { ForwardedRef, PropsWithChildren, forwardRef, useMemo } from 'react';
import { TailwindStyle } from '@ncc-frontend/core';
import { merge } from 'lodash';

import {
    default as CoreAlert,
    IAlertProps as CoreAlertProps
} from './alert-core';

interface IAlertProps extends Omit<CoreAlertProps, 'variant'> {
    variant?:
        | CoreAlertProps['variant']
        | 'success'
        | 'warning'
        | 'info'
        | 'error';
}

function Alert(
    {
        children,
        tailwindStyle,
        variant,
        ...restProps
    }: PropsWithChildren<IAlertProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background:
                        variant === 'success'
                            ? 'bg-utilisation-success'
                            : variant === 'warning'
                            ? 'bg-utilisation-warning/10'
                            : variant === 'info'
                            ? 'bg-utilisation-info/10'
                            : variant === 'error'
                            ? 'bg-utilisation-error/10'
                            : '',
                    padding: 'p-3',
                    textColor:
                        variant === 'success'
                            ? 'text-utilisation-success'
                            : variant === 'warning'
                            ? 'text-utilisation-warning'
                            : variant === 'info'
                            ? 'text-utilisation-info'
                            : variant === 'error'
                            ? 'text-utilisation-red-100'
                            : '',
                    textSize: 'text-[14px]'
                },
                tailwindStyle
            ),
        [tailwindStyle, variant]
    );

    return (
        <CoreAlert {...restProps} ref={ref} tailwindStyle={mergedTailwindStyle}>
            {children}
        </CoreAlert>
    );
}

export default forwardRef(Alert);
export type { IAlertProps };
