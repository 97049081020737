import { useModals } from '@ncc-frontend/core';
import { useState } from 'react';

const useProducts = () => {
    const { push } = useModals();
    const [products, setProducts] = useState('');

    return {
        products,
        push,
        setProducts
    };
};

export default useProducts;
