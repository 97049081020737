import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import productColours from 'common/allocation-product-colours';

const UserNameCellRender = (props) => {
    const formatInitials = (firstName, lastName) => {
        const firstInitial = firstName.charAt(0);
        const secondInitial = lastName.charAt(0);

        return firstInitial + secondInitial;
    };

    const navigate = useNavigate();

    const setSelected = useMemo(
        () => (userUuid) => {
            navigate(`/admin/users/${userUuid}`);
            return props.data.filter((user) => {
                return userUuid === user.uuid;
            });
        },
        [navigate, props.data]
    );

    return (
        <div
            className="flex leading-snug items-center"
            onClick={() => setSelected(props.data[props.cell.row.id].uuid)}
            style={{ cursor: 'pointer' }}
        >
            <div
                className="text-white rounded-full text-sm px-2 py-2 flex mr-4"
                style={{
                    background: productColours[(Math.random() * 4) | 0]
                }}
            >
                {formatInitials(
                    props.data[props.cell.row.id].given_name,
                    props.data[props.cell.row.id].family_name
                )}
            </div>
            <div className="flex-grow">
                <p className="font-SemiBold text-black">{props.cell.value}</p>
                <p>{props.data[props.cell.row.id].email}</p>
            </div>
        </div>
    );
};

export default UserNameCellRender;
