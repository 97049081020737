import { Outlet } from 'react-router-dom';
import AdminNavigation from './navigation/admin-navigation';

const AdminHolderPage = ({ collapsed }) => {
    return (
        <>
            <AdminNavigation collapsed={collapsed}></AdminNavigation>
            <Outlet />
        </>
    );
};

export default AdminHolderPage;
