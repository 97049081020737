import { DayRate } from 'core/api/autogenerated/data-contracts';
import { RolesType } from '../user-management/user-details-form';
import ControlledInput from 'components/form/components/controlled-input';
import DayRateSelectComponent from '../user-management/day-rate-select';
import RoleSelectComponent from '../user-management/role-select';
import useDayRates from 'core/api/hooks/cost-levels/use-day-rates';

interface JobDataProps {
    contracted_days: number | undefined;
    contracted_hours: number | undefined;
    day_rate: DayRate | undefined;
    roles: RolesType[] | undefined;
}
interface JobAndRoleProps {
    className?: string;
    data: JobDataProps;
    loading: boolean;
}

const JobAndRoleInformation = ({
    className,
    data,
    loading
}: JobAndRoleProps) => {
    const { contracted_days, contracted_hours, roles } = data;
    const { data: dayRates } = useDayRates();

    const formatted = dayRates?.map((r) => {
        return {
            label: r.name,
            value: r.uuid
        };
    });

    return (
        <>
            <h2 className="font-bold pb-[20px]">Job & role information</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className={className}>
                    <div className="grid grid-cols-3 gap-3.5">
                        <DayRateSelectComponent
                            data={formatted}
                            loading={loading}
                        ></DayRateSelectComponent>

                        <ControlledInput
                            placeholder={contracted_hours?.toString()}
                            readOnly={false}
                            name="contracted_hours"
                            label="Contracted hours"
                            type="text"
                        ></ControlledInput>

                        <ControlledInput
                            placeholder={contracted_days?.toString()}
                            readOnly={false}
                            name="contracted_days"
                            label="Contracted days"
                            type="text"
                        ></ControlledInput>
                    </div>
                    <div className="grid grid-cols-3 gap-3.5">
                        <RoleSelectComponent
                            name="roles"
                            roles={roles}
                        ></RoleSelectComponent>

                        <ControlledInput
                            placeholder={contracted_days?.toString()}
                            readOnly={true}
                            name="created_at"
                            label="Created"
                            type="text"
                        ></ControlledInput>
                    </div>
                </div>
            )}
        </>
    );
};

export default JobAndRoleInformation;
export type { JobAndRoleProps, JobDataProps };
