import React, { createContext, useContext } from 'react';
import useProductReports from './useProductReports';

interface ProductReportsContextValue {
    endDate;
    noReportsAvailable;
    push;
    reportData;
    setEndDate;
    setNoReportsAvailable;
    setReportData;
    setStartDate;
    startDate;
}

export const ProductReportsContext =
    createContext<ProductReportsContextValue | null>(null);

const ProductReportsProvider: React.FC<React.ReactNode> = ({ children }) => {
    const {
        endDate,
        noReportsAvailable,
        push,
        reportData,
        setEndDate,
        setNoReportsAvailable,
        setReportData,
        setStartDate,
        startDate
    } = useProductReports();

    return (
        <ProductReportsContext.Provider
            value={{
                endDate,
                noReportsAvailable,
                push,
                reportData,
                setEndDate,
                setNoReportsAvailable,
                setReportData,
                setStartDate,
                startDate
            }}
        >
            {children}
        </ProductReportsContext.Provider>
    );
};

export const useProductReportsContext = () => {
    const productReportsContext = useContext(ProductReportsContext);

    if (!productReportsContext)
        throw new Error('You need to use context inside of a provider');

    return productReportsContext;
};

export { ProductReportsProvider };
export type { ProductReportsContextValue };
