import { getPermission } from 'common/permissions';
import ControlledInput from 'components/form/components/controlled-input';
import LineManagerSelector from './line-manager-selector';

type AccountInformationProps = {
    username: string;
};

const AccountInformation = ({ className, line_manager, loading, username }) => {
    return (
        <>
            <h2 className="font-bold pb-[20px]">Account Information</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className={className}>
                    <div className="grid grid-cols-4 gap-3.5">
                        <ControlledInput
                            readOnly={true}
                            name="username"
                            label="Username"
                            type="text"
                        ></ControlledInput>

                        {getPermission('LINE_MANAGER') && (
                            <LineManagerSelector
                                name={'line_manager'}
                                val={line_manager}
                            ></LineManagerSelector>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default AccountInformation;
export type { AccountInformationProps };
