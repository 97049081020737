import { Component, ReactNode } from 'react';

import ErrorPanel from 'components/error/error-panel';

interface Props {
    children?: ReactNode;
}

interface State {
    error: undefined;
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        error: undefined,
        hasError: false
    };

    public static getDerivedStateFromError(error): State {
        return { error: error, hasError: true };
    }

    // defines what to do when an error gets caught
    componentDidCatch(error, errorInfo) {
        // log the error
        console.log('Error caught in Boundary!');
        console.error('Error reported in boundary:', error);
        console.error('Error boundary info :', errorInfo);

        // record the error in an APM tool...
    }

    public render() {
        if (this.state.hasError) {
            return (
                <ErrorPanel code={403}>Please try logging in again</ErrorPanel>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
