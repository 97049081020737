/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client';

export class LogoutController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags auth
     * @name OauthLogout
     * @summary Invalidate the user's access and refresh tokens.
     * @request GET:/logout
     */
    oauthLogout = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/logout`,
            method: 'GET',
            ...params
        });
}
