import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface CalendarGridProps {
    className?: string;
}

function CalendarGrid({
    children,
    className
}: PropsWithChildren<CalendarGridProps>) {
    const cssClasses = classNames(
        'grid place-items-stretch gap-y-1',
        className
    );
    return <div className={cssClasses}>{children}</div>;
}

export default CalendarGrid;
export type { CalendarGridProps };
