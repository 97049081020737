import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { AppController } from 'core/api/autogenerated/App';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type RolesType = 'ALLOCATOR' | 'REPORTER' | 'ADMINISTRATOR' | 'LINE_MANAGER';

function useLineManagersList(query?: { role?: RolesType }) {
    const request = useCallback(async () => {
        const controller = new AppController({
            interceptors,
            securityWorker
        });

        // We don't want to catch any errors here since it will broke react query "error" status
        const response = await controller.getAdminUsers(query);
        return response.data;
    }, [query]);

    return useQuery(['users', 'list'], request);
}

export default useLineManagersList;
