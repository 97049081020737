import { months } from 'helpers/helpers';
import { useCallback, useEffect, useState } from 'react';
import useDashboardData from 'core/api/hooks/dashboard/use-dashboard-data';

const useDashboard = () => {
    const today = new Date();
    const month = today.toLocaleString('default', { month: '2-digit' });
    const year = today.toLocaleString('default', { year: 'numeric' });

    const [currentMonth, setCurrentMonth] = useState<string | number>(month);
    const [currentYear, setCurrentYear] = useState<string | number>(year);

    useEffect(() => {
        setCurrentMonth(month);
        setCurrentYear(year);
    }, [month, year]);

    const {
        data: dashboardData,
        isError,
        isFetching,
        isLoading
    } = useDashboardData({ month: `${currentYear}-${currentMonth}` });

    const currentFormattedDate = useCallback(
        (format) => {
            return `${
                months[Number(currentMonth) - 1]
            }-${currentYear}` as string;
        },
        [currentMonth, currentYear]
    );

    const getPrevious = useCallback(() => {
        const curMonthAsNumber = Number(currentMonth);
        const curYearAsNumber = Number(currentYear);
        const incrementYear = curYearAsNumber - 1;
        let incrementMonth = curMonthAsNumber - 1;

        if (incrementMonth < 1) {
            setCurrentYear(incrementYear.toString());
            incrementMonth = 12;
        }

        if (incrementMonth < 10) {
            setCurrentMonth('0' + incrementMonth.toString());
        } else {
            setCurrentMonth(incrementMonth.toString());
        }
    }, [currentMonth, currentYear]);

    const getNext = useCallback(() => {
        const curMonthAsNumber = Number(currentMonth);
        const curYearAsNumber = Number(currentYear);
        let incrementMonth = curMonthAsNumber + 1;
        const incrementYear = curYearAsNumber + 1;

        if (incrementMonth > 12) {
            setCurrentYear(incrementYear.toString());
            incrementMonth = 1;
        }

        if (incrementMonth < 10 && incrementMonth > 0) {
            setCurrentMonth('0' + incrementMonth.toString());
        } else {
            setCurrentMonth(incrementMonth.toString());
        }
    }, [currentMonth, currentYear]);

    return {
        currentFormattedDate,
        dashboardData,
        getNext,
        getPrevious,
        isError,
        isFetching,
        isLoading,
        setCurrentMonth,
        setCurrentYear
    };
};

export default useDashboard;
