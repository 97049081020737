import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { CoreController } from 'core/api/autogenerated/Core';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type DeleteCostLevelsParams = { query: { uuid: string } };

function useDeleteCostLevels<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void>,
            ReactQueryError<TError>,
            DeleteCostLevelsParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ query }: DeleteCostLevelsParams) => {
            const controller = new CoreController({
                interceptors,
                securityWorker
            });

            return controller.deleteDayRate(query.uuid);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);

                queryClient.invalidateQueries({ queryKey: ['day-rates'] });
            }
        }
    );
}

export default useDeleteCostLevels;
export type { DeleteCostLevelsParams };
