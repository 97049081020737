import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';

interface ModalBodyProps extends CommonProps {}

function ModalBody({
    children,
    className,
    ...restProps
}: PropsWithChildren<ModalBodyProps>): ReactElement | null {
    const cssClasses = classNames(
        className,
        'overflow-visible pt-ncc-padding-16'
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default ModalBody;
export type { ModalBodyProps };
