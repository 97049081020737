import { ComponentType, PropsWithChildren } from 'react';
import TypographyHeader, { TypographyHeaderProps } from './typography-header';
import TypographyLink, { TypographyLinkProps } from './typography-link';
import TypographyParagraph, {
    TypographyParagraphProps
} from './typography-paragraph';

type TypographyType = {
    Header: ComponentType<PropsWithChildren<TypographyHeaderProps>>;
    Link: ComponentType<PropsWithChildren<TypographyLinkProps>>;
    Paragraph: ComponentType<PropsWithChildren<TypographyParagraphProps>>;
};

const Typography: TypographyType = {
    Header: TypographyHeader,
    Link: TypographyLink,
    Paragraph: TypographyParagraph
};

export default Typography;
export type {
    TypographyLinkProps,
    TypographyType,
    TypographyHeaderProps,
    TypographyParagraphProps
};
