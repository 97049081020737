import { useCallback } from 'react';

import { CoreController } from 'core/api/autogenerated/Core';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';
import useQuery from 'core/api/react-query/useQuery';

function useProjectListData() {
    const request = useCallback(async () => {
        // if (process.env.REACT_APP_API_MOCK) {
        //     return data;
        // }

        const controller = new CoreController({
            interceptors,
            securityWorker
        });

        // We don't want to catch any errors here since it will broke react query "error" status
        const response =
            await controller.getProducts();
        return response.data;
    }, []);

    return useQuery(['products-data', 'list'], request);
}

export default useProjectListData;