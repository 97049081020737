import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactNode } from 'react';
import {
    faCircleCheck,
    faTimesCircle
} from '@fortawesome/pro-regular-svg-icons';
import { useModals } from '@ncc-frontend/core';

import Button from 'components/button/button';
import Modal from './modal';
import ModalBody from './modal-body';

interface ResultModalProps {
    description?: ReactNode;
    title?: ReactNode;
    variant: 'success' | 'error';
}

function ResultModal({
    children,
    description,
    title,
    variant
}: PropsWithChildren<ResultModalProps>) {
    const { pop } = useModals();

    const icon =
        variant === 'success' ? (
            <FontAwesomeIcon
                className="text-3xl text-utilisation-accent-blue"
                icon={faCircleCheck}
            />
        ) : (
            <FontAwesomeIcon
                className="text-3xl text-utilisation-red-100"
                icon={faTimesCircle}
            />
        );

    return (
        <Modal className="w-[480px] p-ncc-padding-24">
            <ModalBody className="flex flex-col text-center gap-2">
                {icon}
                <div className="font-medium">{title}</div>
                <div>{description}</div>
                {children}
                <Button onClick={pop} variant="primary">
                    Close
                </Button>
            </ModalBody>
        </Modal>
    );
}

export default ResultModal;
export type { ResultModalProps };
