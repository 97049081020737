import { Outlet } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import AppBody, { AppBodyProps } from 'app-layout/app-body';
import classNames from 'classnames';

interface MainBodyProps extends AppBodyProps {
    collapsed?: boolean;
    showFilters?: boolean;
}

function MainBody({
    children,
    collapsed,
    ...restProps
}: PropsWithChildren<MainBodyProps>) {
    const cssClasses = classNames('transition-all', {
        'ml-sider': !collapsed,
        'ml-sider-collapsed': collapsed
    });

    return (
        <AppBody {...restProps} className={cssClasses}>
            <Outlet />
        </AppBody>
    );
}

export default MainBody;
export type { MainBodyProps };
