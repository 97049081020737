import { ReactElement } from 'react';
import AdminPageContainer from 'pages/admin/admin-page-container';
import UserDetails from './user-details';

interface AdminUserDetailsPageProps {}

function AdminUserDetailsPage(): ReactElement {
    return (
        <AdminPageContainer>
            <UserDetails />
        </AdminPageContainer>
    );
}

export default AdminUserDetailsPage;
export type { AdminUserDetailsPageProps };
