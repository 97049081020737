import { ReactComponent as AppLogo } from '../../logo.svg';
import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';
import { getPermission } from 'common/permissions';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { faFileChartColumn } from '@fortawesome/pro-solid-svg-icons';
import {
    faGrid2,
    faTimer,
    faUserAlt
} from '@fortawesome/pro-regular-svg-icons';
import AppSider from '../app-sider';
import CollapseButton from '../headers/collapse-button';
import SiderNavItem from './sider-nav-item';

interface MainSiderProps extends CommonProps {
    collapsed?: boolean;
    onToggleClick: MouseEventHandler<HTMLDivElement>;
}

function MainSider({ className, collapsed, onToggleClick }: MainSiderProps) {
    const { t } = useTranslation();

    const cssClasses = classNames(className, {
        'w-[240px]': !collapsed,
        'w-[72px]': collapsed
    });

    const navigationSections = [
        {
            headline: 'Main',
            links: [
                {
                    canView: true,
                    icon: <FontAwesomeIcon icon={faTimer} />,
                    text: t('sider.allocations-link'),
                    to: 'allocations/personal'
                }
            ]
        },
        {
            headline: 'Analyse',
            links: [
                {
                    canView: getPermission('ADMINISTRATOR'),
                    icon: <FontAwesomeIcon icon={faGrid2} />,
                    text: t('sider.dashboard-link'),
                    to: '/dashboard'
                },
                {
                    canView: getPermission('REPORTER'),
                    icon: <FontAwesomeIcon icon={faFileChartColumn} />,
                    text: 'Product Reports',
                    to: 'product-reports'
                }
            ]
        },
        {
            headline: 'Manage',
            links: [
                {
                    canView: getPermission('ADMINISTRATOR'),
                    icon: <FontAwesomeIcon icon={faUserAlt} />,
                    text: t('sider.admin-link'),
                    to: 'admin'
                }
            ]
        }
    ];

    return (
        <AppSider className={cssClasses}>
            {!collapsed ? (
                <div className="absolute top-0 pl-[22px] left-0 flex items-center bg-utilisation-dark-blue h-[60px] w-full dark:bg-ncc-portal-dark">
                    <div className="border-r border-solid ml-[5px] pr-3">
                        <AppLogo data-testid="logo" />
                    </div>
                    <h1 className="text-white ml-3 font-SemiBold">
                        Utilisation
                    </h1>
                </div>
            ) : (
                <div className="absolute top-0 pl-[22px] left-0 flex items-center bg-utilisation-dark-blue h-[60px] w-full dark:bg-ncc-portal-dark">
                    <div className="ml-[5px]">
                        <AppLogo data-testid="logo" />
                    </div>
                </div>
            )}

            {navigationSections.map((section, i) => (
                <SiderNavItem
                    collapsed={collapsed}
                    section={section}
                    key={Math.random()}
                ></SiderNavItem>
            ))}

            <div className="mt-auto">
                {!collapsed ? (
                    <div className="flex border-t-[1px] border-white/30 pt-1">
                        <CollapseButton
                            collapsed={collapsed}
                            onToggle={onToggleClick}
                        />
                        {/* <ThemeSwitch collapsed={collapsed} /> */}
                    </div>
                ) : (
                    <div className="flex-col justify-items-center border-t-[1px] border-white/30 pt-1">
                        {/* <ThemeSwitch collapsed={collapsed} /> */}
                        <CollapseButton
                            collapsed={collapsed}
                            onToggle={onToggleClick}
                        />
                    </div>
                )}
            </div>
        </AppSider>
    );
}

export default MainSider;
export type { MainSiderProps };
