import { Fragment, useEffect, useState } from 'react';
import SearchBar from 'components/search-bar/search-bar';
import Select from 'react-select';
import WeekMonthPicker from 'components/week-month-picker/week-month-picker';
import useLineManagersList from 'core/api/hooks/teams/use-line-managers-list';

interface ISelectProps {
    label: string;
    value: string;
}

function TeamsTableFilters({
    column: {
        filter,
        filterValue = [],
        id,
        numFiltersSelected,
        preFilteredRows,
        setFilter
    },
    ...props
}) {
    const {
        data: lineManagers,
        isFetching,
        isLoading
    } = useLineManagersList({
        role: 'LINE_MANAGER'
    });

    // const { toggleSidePanelFunction } = useContext(DataTableContext);

    const [managers, setManagers] = useState<ISelectProps[] | undefined>(
        undefined
    );

    const [defaultFilterValue] = useState(
        localStorage.getItem('teamFilterSelect')
            ? localStorage.getItem('teamFilterSelect')
            : null
    );

    const { context } = props.defaultColumn;

    // console.log(localStorage.getItem('teamFilterSelect'));

    useEffect(() => {
        const formattedLineManagers = lineManagers?.map((mgr) => {
            return {
                label: `${mgr.given_name} ${mgr.family_name}`,
                value: `${mgr.given_name} ${mgr.family_name}`
            };
        });
        setManagers(formattedLineManagers);
    }, [lineManagers]);

    useEffect(() => {
        setFilter(setFilteredParams([], defaultFilterValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setFilteredParams(filterArr, val) {
        // clear out the array each time. May want to change this for merging filters
        filterArr = [];
        filterArr.push(val);

        if (filterArr.length === 0) filterArr = undefined;
        return filterArr;
    }

    const styles = {
        control: (base, state) => ({
            ...base,
            borderRadius: '8px',
            height: '40px',
            minWidth: '200px',
            textAlign: 'left'
        }),
        option: (base) => ({
            ...base,
            textAlign: 'left'
        })
    };

    return (
        <Fragment>
            {isLoading || isFetching ? (
                <p>Loading...</p>
            ) : (
                <div className="flex gap-2 items-center">
                    <div className="flex flex-1">
                        <WeekMonthPicker context={context}></WeekMonthPicker>
                    </div>
                    {/* Line Manager Select search */}

                    <div>
                        <Select
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(e: any) => {
                                setFilter(
                                    setFilteredParams(filterValue, e.value)
                                );
                                localStorage.setItem(
                                    'teamFilterSelect',
                                    e.value
                                );
                            }}
                            styles={styles}
                            closeMenuOnSelect={true}
                            options={
                                managers
                                    ? [
                                          { label: 'Show all', value: '' },
                                          ...(managers as ISelectProps[])
                                      ]
                                    : []
                            }
                            defaultValue={{
                                label:
                                    defaultFilterValue !== null
                                        ? defaultFilterValue
                                        : 'Show All',
                                value:
                                    defaultFilterValue !== null
                                        ? defaultFilterValue
                                        : ''
                            }}
                        />
                    </div>

                    {/* Text Search */}

                    <div>
                        <SearchBar
                            onChange={(e) =>
                                props.setGlobalFilter(e.target.value)
                            }
                        ></SearchBar>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default TeamsTableFilters;
