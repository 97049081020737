import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import React from 'react';
import classNames from 'classnames';

interface FullscreenSpinnerProps {
    suppressBackground?: boolean;
}

function FullscreenSpinner({ suppressBackground }: FullscreenSpinnerProps) {
    const cssClasses = useMemo(
        () =>
            classNames('flex items-center justify-center bg-opacity-50', {
                'bg-white': !suppressBackground
            }),
        [suppressBackground]
    );
    return (
        <div className={cssClasses}>
            <FontAwesomeIcon spin icon={faSpinner} size="2x" />
        </div>
    );
}

export default FullscreenSpinner;
