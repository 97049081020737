import './multiItem.css';
import { FunctionComponent, useEffect, useState } from 'react';
import productColours from 'common/allocation-product-colours';

interface ProgressMultComponentProps {
    data;
    totalPercentage;
}

interface IItems {
    color: string;
    name: string;
    value: string;
}

const ProgressMultItemComponent: FunctionComponent<
    ProgressMultComponentProps
> = ({ data, totalPercentage }) => {
    const [items, setItems] = useState<IItems[] | undefined>(undefined);

    useEffect(() => {
        const myArray: IItems[] = [];
        if (data.allocations.length > 0) {
            data?.allocations.map((alloc, i) => {
                return myArray.push({
                    color: productColours[i],
                    name: alloc.product.name,
                    value: alloc.percentage
                });
            });
        }
        setItems(myArray);
    }, [data]);

    const percentageOfTotal = (value) => {
        if (totalPercentage > 100) {
            return (value * 100) / totalPercentage + '%';
        } else {
            return value + '%';
        }
    };

    const bars = () => {
        return (
            items &&
            items.length &&
            items.map((item, i) => {
                return item.value ? (
                    <div
                        className="bar"
                        style={{
                            backgroundColor: item.color,
                            width: percentageOfTotal(item.value)
                        }}
                        key={i}
                    ></div>
                ) : null;
            })
        );
    };

    return (
        <div className="multicolor-bar">
            <div className="bars">{bars()}</div>
        </div>
    );
};

export default ProgressMultItemComponent;
