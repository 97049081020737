import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    FormEventHandler,
    MouseEventHandler,
    useCallback,
    useMemo
} from 'react';
import { Modal } from 'components';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button/button';
import Select from 'react-select';
import TypographyHeader from 'components/typography/typography-header';
import TypographyParagraph from 'components/typography/typography-paragraph';
import useBackendValidation from 'common/use-backend-validation';

import useGetReporterProducts from 'core/api/hooks/product-reports/use-get-reporter-products';

function ProductReportsModal({ config, loading }) {
    const { pop } = useModals();

    const { data, isLoading } = useGetReporterProducts({
        end_date: config.endDate,
        start_date: config.startDate
    });

    const productIdsOptions = useMemo(
        () =>
            data?.filters.products?.map((product) => ({
                label: product.name as string,
                value: product.uuid as string
            })),
        [data?.filters.products]
    );

    const formSchema = yup.object({
        product_uuids: yup.array().of(yup.string())
    });

    const {
        handleSubmit: rhfHandleSubmit,
        setError,
        ...methods
    } = useForm({
        defaultValues: {
            end_date: '',
            product_uuids: [],
            start_date: ''
        },
        mode: 'onSubmit',
        resolver: yupResolver(formSchema)
    });

    const triggerSubmit = useCallback(() => {
        rhfHandleSubmit(config.submitFn)();
    }, [rhfHandleSubmit, config.submitFn]);

    const handleDiscard = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.preventDefault();
            pop();
        },
        [pop]
    );

    // const preventButtonDefault = useCallback<
    //     MouseEventHandler<HTMLButtonElement>
    // >((event) => {
    //     event.preventDefault();
    // }, []);

    const preventFormDefault = useCallback<FormEventHandler<HTMLFormElement>>(
        (event) => {
            event.preventDefault();
        },
        []
    );

    useBackendValidation(config.error?.response?.data?.errors, setError, [
        'invalid'
    ]);

    return (
        <Modal className="w-[480px] p-ncc-padding-24">
            <Modal.Header>
                {' '}
                <TypographyHeader level={3} shadow={false}>
                    {'Add product report'}
                </TypographyHeader>
            </Modal.Header>
            <Modal.Body className="flex flex-col gap-2.5 overflow-visible">
                <FormProvider
                    {...methods}
                    setError={setError}
                    handleSubmit={rhfHandleSubmit}
                >
                    <form
                        className="flex flex-col gap-2.5"
                        onSubmit={preventFormDefault}
                    >
                        <label className="text-ncc-form-label text-utilisation-light-blue">
                            Select Product(s)
                            <div className="mt-ncc-margin-4">
                                {isLoading ? (
                                    <TypographyParagraph>
                                        Loading ...
                                    </TypographyParagraph>
                                ) : (
                                    <Controller
                                        name="product_uuids"
                                        render={({
                                            field: { onChange, value }
                                        }) => (
                                            <Select
                                                className="text-base"
                                                isMulti
                                                name="product_uuids"
                                                options={productIdsOptions}
                                                value={productIdsOptions?.filter(
                                                    (el) =>
                                                        value?.includes(
                                                            el.value
                                                        )
                                                )}
                                                onChange={(option) => {
                                                    if (option === null) {
                                                        onChange(null);
                                                        return;
                                                    }

                                                    onChange(
                                                        option.map(
                                                            (el) => el.value
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </label>

                        <div className="flex gap-6">
                            <div className="flex ml-auto gap-2">
                                <Button
                                    variant="danger"
                                    onClick={handleDiscard}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant={
                                        config.actionFailed
                                            ? 'danger'
                                            : 'primary'
                                    }
                                    loading={loading}
                                    disabled={
                                        !methods.formState.isDirty || loading
                                    }
                                    onClick={triggerSubmit}
                                    className="w-full"
                                >
                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                    {config.actionFailed ? 'Try again' : 'Save'}
                                </Button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default ProductReportsModal;
