import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback } from 'react';
import { faDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { useAllocationsContext } from 'ui/allocations/allocations-context';
import { useModals } from '@ncc-frontend/core';
import Button from 'components/button/button';
import ContentBody from 'app-layout/bodies/content-body';
import DashboardTable from 'ui/dashboard/dashboard-table';
import DownloadCSVModal from 'ui/dashboard/download/download-csv-modal';
import PageHeading from 'ui/page-headings/page-heading';
import PageLoader from 'components/loaders/page-loader';
import useDashboardData from 'core/api/hooks/dashboard/use-dashboard-data';

interface MainDashboardPageProps {}

function MainDashboardPage({ collapsed }): ReactElement {
    const { month, year } = useAllocationsContext();

    const { push } = useModals();

    const {
        data: dashboardData,
        isFetching,
        isLoading,
        refetch
    } = useDashboardData({ month: `${year}-${month}` });

    const downloadCSV = useCallback(() => {
        push(DownloadCSVModal, {});
    }, [push]);

    return isFetching || isLoading ? (
        <div className="flex items-center justify-center h-full flex-1">
            <PageLoader
                suppressBackground={true}
                type="fast"
                text={'Loading dashboard...'}
            ></PageLoader>
        </div>
    ) : (
        <div className="flex flex-col flex-1" data-testid="base-user-page">
            <PageHeading title="Dashboard">
                <div className="ml-auto mx-ncc-margin-24">
                    <Button
                        onClick={downloadCSV}
                        variant="secondary"
                        icon={
                            <FontAwesomeIcon
                                icon={faDownToLine}
                                className="text"
                            />
                        }
                    >
                        Download allocations
                    </Button>
                </div>
            </PageHeading>
            <ContentBody>
                <DashboardTable
                    dashboardData={dashboardData}
                    refetch={refetch}
                ></DashboardTable>
            </ContentBody>
        </div>
    );
}

export default MainDashboardPage;
export type { MainDashboardPageProps };
