import * as yup from 'yup';
import { DayRate } from 'core/api/autogenerated/data-contracts';
import { FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import { ResultModal } from 'components';
import { useEffect, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button/button';
import CostComponent from './form-field-controllers/cost-component';
import NameComponent from './form-field-controllers/name-component';
import PageLoader from 'components/loaders/page-loader';
import useDayRateCreate from 'core/api/hooks/cost-levels/use-post-new-day-rate';
import useDeleteCostLevels from 'core/api/hooks/cost-levels/use-delete-cost-levels';
import useUpdateCostLevel from 'core/api/hooks/cost-levels/use-update-cost-level';

interface ICostLevelFormValues extends DayRate {}

interface ICostLevelsFormProps {
    crudAction?: string | null;
    data?: ICostLevelFormValues;
}

const schema = yup.object().shape({
    cost: yup.number().min(2).required(),
    name: yup.string().required()
});

const CostLevelsCrudForm = ({ crudAction, data }: ICostLevelsFormProps) => {
    const { pop, push } = useModals();
    const [readOnly, setReadOnly] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);

    const methods = useForm<ICostLevelFormValues>({
        defaultValues: {
            cost: data ? data?.cost : undefined,
            name: data ? data?.name : ''
        },
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        methods.reset(data);
        if (crudAction === 'delete') setReadOnly(true);
    }, [crudAction, data, methods]);

    // Return an appropriate success message should the api response be 200 or 204

    const getSuccessMessage = (action) => {
        switch (action) {
            case 'create':
                return 'Your Cost Level was created successfully';
            case 'udpdate':
                return 'Your Cost Level was updated successfully';
            case 'delete':
                return 'Your Cost Level was deleted successfully';
            default:
                break;
        }
        return;
    };

    // onHandleApiError Fn

    const onHandleApiError = (err) => {
        if (err.response.status !== 400) {
            pop();
            return push(ResultModal, {
                description: 'Something went wrong. Close and try again.',
                title: 'Changes didnt happen',
                variant: 'error'
            });
        }
    };

    // onHandleApiSuccess

    const onHandleApiSuccess = () => {
        pop();
        methods.reset();
        return push(ResultModal, {
            description: getSuccessMessage(crudAction),
            title: 'Day rate created',
            variant: 'success'
        });
    };

    // Create a new Cost Level

    const { isLoading, mutate: createCostLevel } = useDayRateCreate<{
        errors: Record<Path<ICostLevelFormValues> | 'invalid', string[]>;
    }>({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    // Update a cost level

    const { mutate: updateCostLevel } = useUpdateCostLevel<{
        errors: Record<Path<ICostLevelFormValues> | 'invalid', string[]>;
    }>({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    // Delete a cost level

    const { mutate: deleteCostLevel } = useDeleteCostLevels({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    useEffect(() => {
        isLoading ? setApiLoading(true) : setApiLoading(false);
    }, [isLoading]);

    const formSubmitHandler: SubmitHandler<ICostLevelFormValues> = (
        data: ICostLevelFormValues
    ) => {
        switch (crudAction) {
            case 'create':
                createCostLevel({ data });
                break;
            case 'update':
                updateCostLevel({
                    data: {
                        cost: data.cost,
                        name: data.name,
                        uuid: data.uuid
                    },
                    dayrate_uuid: data.uuid
                });
                break;
            case 'delete':
                deleteCostLevel({ query: { uuid: data?.uuid as string } });
                break;
            default:
                break;
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(formSubmitHandler)} noValidate>
                {apiLoading ? (
                    <PageLoader
                        suppressBackground={true}
                        type="fast"
                        text={'Submitting request...'}
                    ></PageLoader>
                ) : (
                    <>
                        <div className="flex flex-col gap-6 text-left">
                            <NameComponent
                                readOnly={readOnly}
                                type="text"
                                placeholder={'Please enter a name...'}
                            ></NameComponent>
                            <CostComponent
                                readOnly={readOnly}
                                type="number"
                                placeholder={'Please enter a cost...'}
                            ></CostComponent>
                        </div>
                        <div className="mt-5">
                            <div className="flex gap-2 justify-end">
                                <Button onClick={pop} variant="tertiary">
                                    Cancel
                                </Button>
                                {crudAction === 'delete' ? (
                                    <Button variant="danger">Delete</Button>
                                ) : (
                                    <Button variant="primary">Save</Button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </form>
        </FormProvider>
    );
};

export default CostLevelsCrudForm;
export type { ICostLevelFormValues };
