import { Typography } from '@ncc-frontend/core';
import Account from 'ui/account/account';
import classNames from 'classnames';

import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import AppHeader, { AppHeaderProps } from 'app-layout/app-header';

interface MainHeaderProps extends AppHeaderProps {
    collapsed?: boolean;
    onToggleClick: MouseEventHandler<HTMLDivElement>;
}

function MainHeader({
    className,
    collapsed,
    onToggleClick,
    ...restProps
}: MainHeaderProps) {
    const { t } = useTranslation();
    const cssClasses = classNames(
        className,
        'transition-all border-b-[1px] flex',
        {
            'left-[240px]': !collapsed,
            'left-[72px]': collapsed
        }
    );

    return (
        <AppHeader {...restProps} className={cssClasses}>
            <div className="flex-inline flex items-center w-full px-6">
                <Typography.Header
                    level={3}
                    tailwindStyle={{
                        border: 'border-r pr-[24px] dark:text-white'
                    }}
                >
                    {collapsed && t('application.app-title')}
                </Typography.Header>
                <span className="ml-ncc-margin-24 px-2 py-1 text-[11px] rounded-md bg-ncc-grey-20 text-grey-100 block w-fit">
                    Version: {process.env.REACT_APP_VERSION}
                </span>
                <div className="flex ml-auto">
                    <Account></Account>
                </div>
            </div>
        </AppHeader>
    );
}

export default MainHeader;
export type { MainHeaderProps };
