import { ReportItem } from 'pages/admin/product-reports/product-reports-page';
import DataTableContainer from 'components/data-table/data-table-container';

interface ProductReportsTableProps {
    report: ReportItem;
}

const ProductReportsTable = ({ report }: ProductReportsTableProps) => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'user.email',
            id: 'user.email'
        },
        {
            Header: 'Title',
            accessor: 'day_rate.name',
            id: 'day_rate.name'
        },
        {
            Header: 'Time Spend',
            accessor: 'hours',
            id: 'hours'
        },
        {
            Header: 'Development Cost',
            accessor: 'cost',
            id: 'cost'
        }
    ];

    return (
        <DataTableContainer
            context={null}
            columns={columns}
            data={report.allocated_users ? report.allocated_users : []}
        />
    );
};

export default ProductReportsTable;
export type { ProductReportsTableProps };
