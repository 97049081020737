import { MsadAuthContext } from './MsadAuthProvider';
import { useContext } from 'react';

function useAuth() {
    const context = useContext(MsadAuthContext);

    if (!context) throw new Error('No auth root!');

    return context;
}

export default useAuth;
