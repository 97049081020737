import useMsadAuth from '../../authLib/useMsadAuth';

import { ReactComponent as Illustration } from './utilisation-login-illustration.svg';
import { ReactElement } from 'react';
import Button from 'components/button/button';
import GradientBackground from 'components/gradient-background/gradient-background';
import TypographyHeader from 'components/typography/typography-header';
import utilisationLogo from './utilisation-logo-white.png';

interface LoginPageProps {}

function LoginPage(): ReactElement {
    const sectionCSSClasses = 'flex flex-col justify-center ';
    const { logIn } = useMsadAuth();

    localStorage.setItem('user', JSON.stringify(null));

    return (
        <GradientBackground isLoginPage>
            <div className="h-full w-full flex">
                <div
                    className={`${sectionCSSClasses} items-left flex-1 gap-6 ml-[161px]`}
                >
                    <div>
                        <img src={utilisationLogo} alt="utilisation logo" />
                    </div>

                    <TypographyHeader
                        className="text-primary shadow-none pl-0 text-white"
                        level={2}
                        shadow={false}
                    >
                        Beyond Time Management
                    </TypographyHeader>
                    <p className="text-white">Please sign in with NCC SSO</p>

                    <Button
                        variant={'primary'}
                        onClick={logIn}
                        className="w-[78px]"
                        data-testid="button-login"
                    >
                        Login
                    </Button>
                </div>
                <div className={`${sectionCSSClasses} items-center`}>
                    <Illustration />
                </div>
            </div>
        </GradientBackground>
    );
}

export default LoginPage;
export type { LoginPageProps };
