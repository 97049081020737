import { ErrorPanel } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { getWeek } from 'date-fns';
import { useAllocationsContext } from './allocations-context';
import AllocationsPersonal from './allocations-personal';
import Button from 'components/button/button';
import PageLoader from 'components/loaders/page-loader';
import useAllocationsData from 'core/api/hooks/allocations/use-allocations-data';
import useAuth from 'authLib/use-auth';

function AllocationsPersonalContainer() {
    const { week, year } = useAllocationsContext();
    const { logOut } = useAuth();
    const dateParam = `${year}-${week ? week : getWeek(new Date())}`;

    const {
        data: allocations,
        isError,
        isFetching,
        isLoading
    } = useAllocationsData({
        week: dateParam
    });

    if (isError || allocations?.contract === null) {
        return (
            <ErrorPanel code={403}>
                {allocations?.contract === null && (
                    <p>It appears that you may not have a valid contract.</p>
                )}
                <Button
                    icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    onClick={() => logOut()}
                >
                    Back to login
                </Button>
            </ErrorPanel>
        );
    }

    return isFetching || isLoading ? (
        <div className="flex items-center justify-center h-full">
            <PageLoader
                suppressBackground={true}
                type="fast"
                text={'Loading allocations...'}
            ></PageLoader>
        </div>
    ) : (
        <AllocationsPersonal
            allocations={allocations}
            isFetching={isFetching}
            isLoading={isLoading}
            refetch
        ></AllocationsPersonal>
    );
}

export default AllocationsPersonalContainer;
