import { ReactElement } from 'react';
import AdminPageContainer from '../admin-page-container';
import UsersTable from 'ui/admin/user-management/users-table';
import useUsersList from 'core/api/hooks/admin/use-users-list';

interface AdminUsersPageProps {}

function AdminUsersPage(): ReactElement {
    const { data: users, isError, isFetching, isLoading } = useUsersList();

    return (
        <AdminPageContainer>
            {!isError && (
                <UsersTable
                    data={users}
                    loading={isLoading || isFetching}
                ></UsersTable>
            )}
            {isError && <>There was an error</>}
        </AdminPageContainer>
    );
}

export default AdminUsersPage;
export type { AdminUsersPageProps };
