import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import DataHeaderButton from 'ui/datepicker/date-picker/data-header-button/data-header-button';
import DatePickerWizard from 'ui/datepicker/date-picker/date-picker-wizard';
import moment from 'moment';

function DatePickerButton({ context }) {
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [, setSelected] = useState('');

    const { setEndDate, setStartDate, setTitle, title } = context;

    const handleSelect = (e) => {
        const start_date = moment(e[0]).format('YYYY-MM-DD');
        const end_date = moment(e[1]).format('YYYY-MM-DD');

        setStartDate(start_date);
        setEndDate(end_date);
    };

    const onHandleClick = () => {
        setOpenDatePicker(true !== openDatePicker);
    };

    return (
        <div className="grid relative">
            <DataHeaderButton
                onClick={onHandleClick}
                icon={<FontAwesomeIcon className="mr-4" icon={faCalendar} />}
                active={true}
                disabled={false}
                className="relative"
            >
                {title ? title : 'Select dates...'}
            </DataHeaderButton>

            {openDatePicker && (
                <DatePickerWizard
                    resolution="month"
                    close={setOpenDatePicker}
                    setSelected={setSelected}
                    setParentTitle={setTitle}
                    className="absolute top-[40px] w-[300px] z-10"
                    onSelect={handleSelect}
                    range
                ></DatePickerWizard>
            )}
        </div>
    );
}

export default DatePickerButton;
