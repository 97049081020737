import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface AppSiderProps extends CommonProps {}

function AppSider({
    children,
    className,
    ...restProps
}: PropsWithChildren<AppSiderProps>) {
    const cssClasses = classNames(
        className,
        'pt-20 p-5 fixed flex flex-col top-0 h-screen bg-sider shadow-sider transition-all overflow-auto dark:bg-ncc-portal-dark'
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default AppSider;
export type { AppSiderProps };
