// import '../../app/globals.css';

import { Dropdown } from './Dropdown';
import { MultiSelectContext } from './context';
import { MultiSelectProps } from './interfaces';

export default function MultiSelect({ props }: { props: MultiSelectProps }) {
    console.log(props);

    return (
        <div>
            <MultiSelectContext.Provider value={props}>
                <div className="">
                    <h1>{props.labelledBy}</h1>

                    <Dropdown />
                </div>
            </MultiSelectContext.Provider>
        </div>
    );
}
