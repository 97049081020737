import { useAllocationsContext } from 'ui/allocations/allocations-context';
import { useMemo } from 'react';
import { useModals } from '@ncc-frontend/core';
import SearchBar from 'components/search-bar/search-bar';
import WeekMonthPicker from 'components/week-month-picker/week-month-picker';

function DashboardTableFilters({ ...props }) {
    const { month, setMonth, setWeek, setYear, week, year } =
        useAllocationsContext();
    const pickerType = 'month';
    const { push } = useModals();

    const tableContext = useMemo(
        () => ({
            month,
            pickerType,
            push,
            setMonth,
            setWeek,
            setYear,
            week,
            year
        }),
        [push, setWeek, week, year, pickerType, setMonth, setYear, month]
    );

    return (
        <div className="flex gap-2 items-center">
            <WeekMonthPicker context={tableContext}></WeekMonthPicker>
            <SearchBar
                onChange={(e) => props.setGlobalFilter(e.target.value)}
            ></SearchBar>
        </div>
    );
}

export default DashboardTableFilters;
