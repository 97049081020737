import './add-product-button.css';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const AddProductButton = ({ addNewProduct, disabled }) => {
    return (
        <Button
            variant="primary"
            onClick={addNewProduct}
            data-testid="add-new-row"
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Product
        </Button>
    );
};

export default AddProductButton;
