import { useFormContext } from 'react-hook-form';
import Button from 'components/button/button';
import MainRouteHeading from 'ui/page-headings/main-route-heading';

const UserDetailsActions = ({ trigger, usersName }) => {
    const { formState, reset } = useFormContext();

    return (
        <MainRouteHeading
            level={1}
            title={usersName}
            shadow={false}
            className="border-b-[1px] border-utilisation-border-color-main"
        >
            <div className="flex flex-grow justify-end">
                <Button
                    onClick={() => reset({ ...formState.defaultValues })}
                    variant="primary"
                    className="mx-2"
                >
                    Cancel
                </Button>
                <Button
                    disabled={!formState.isDirty}
                    onClick={trigger}
                    variant="primary"
                    className="mx-2"
                >
                    Save changes
                </Button>
            </div>
        </MainRouteHeading>
    );
};

export default UserDetailsActions;
