import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface AdminPageContainerProps extends CommonProps {}

function AdminPageContainer({
    children,
    className
}: PropsWithChildren<AdminPageContainerProps>) {
    const cssClasses = classNames(
        className,
        'flex-1 px-[24px] ml-[336px] pb-[50px] overflow-auto'
    );

    return <div className={cssClasses}>{children}</div>;
}

export default AdminPageContainer;
