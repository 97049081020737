import { useEffect, useState } from 'react';

function TypeRenderer(rowData) {
    const [typeName, setTypeName] = useState<string>('');

    useEffect(() => {
        switch (rowData.type) {
            case 'ncc-software':
                setTypeName('Software / Platforms');
                break;
            case 'third-party-platform':
                setTypeName('Apps');
                break;
            case 'gts-services':
                setTypeName('GTS Services');
                break;
            case 'finance':
                setTypeName('Finance');
                break;
            default:
                break;
        }
    }, [rowData.type]);

    return <div>{typeName}</div>;
}

export default TypeRenderer;
