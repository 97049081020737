import { CommonProps, useModals } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactElement, useCallback } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

interface ModalHeaderProps extends CommonProps {}

function ModalHeader({
    children,
    className,
    ...restProps
}: PropsWithChildren<ModalHeaderProps>): ReactElement | null {
    const { pop } = useModals();

    const close = useCallback(() => {
        pop();
    }, [pop]);

    const cssClasses = classNames(
        'flex items-center justify-center border-b-2 pb-ncc-padding-20',
        className
    );

    return (
        <div {...restProps} className={cssClasses}>
            <div className="flex">{children}</div>
            <FontAwesomeIcon
                onClick={close}
                className="ml-auto cursor-pointer"
                icon={faTimes}
            />
        </div>
    );
}

export default ModalHeader;
export type { ModalHeaderProps };
