import { PropsWithChildren } from 'react';
import MainRouteHeading from './main-route-heading';

interface PageHeadingProps {
    title: string;
}

function PageHeading({
    children,
    title,
    ...restProps
}: PropsWithChildren<PageHeadingProps>) {
    return (
        <MainRouteHeading
            data-testid="heading"
            level={2}
            title={title}
            shadow={true}
            className="pl-ncc-padding-24"
        >
            {children}
        </MainRouteHeading>
    );
}

export default PageHeading;
