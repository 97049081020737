import { endOfWeek, format, getWeek, startOfWeek } from 'date-fns';

import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { months } from 'helpers/helpers';
import MonthPicker from './month-picker';
import WeekPicker from './week-picker';

interface PickerConfig {
    month?: number;
    pickerType: string;
    push;
    setMonth: React.Dispatch<SetStateAction<number | string>>;
    setWeek: React.Dispatch<SetStateAction<number | string>>;
    setYear: React.Dispatch<SetStateAction<number | string>>;
    week?: number;
    year?: number;
}

interface PickerContext {
    context: PickerConfig;
}

const WeekMonthPicker = ({ context }: PickerContext) => {
    // Destructure from context
    const { month, pickerType, setMonth, setWeek, setYear, week, year } =
        context;

    // selected date
    const [selectedDate, setSelectedDate] = useState<Date | number>(0);
    // the selected week
    const [selectedWeek, setSelectedWeek] = useState(week);

    const thisWeek = getWeek(new Date());

    // the current weeks date range
    const getDate = useCallback((weekNumber, year) => {
        return new Date(year, 0, 0 + weekNumber * 7);
    }, []);

    useEffect(() => {
        setSelectedDate(getDate(week, year));
    }, [getDate, week, year]);

    const start = startOfWeek(selectedDate, {
        weekStartsOn: 1
    });
    const end = endOfWeek(selectedDate, { weekStartsOn: 1 });
    // format date range of current week
    const formatStart = format(start, 'dd/MMM');
    const formatEnd = format(end, 'dd/MMM');

    const currentWeekRange = `${formatStart} - ${formatEnd}`;

    const weekScroll = (direction) => {
        const newDate = new Date(selectedDate);
        if (direction === 'forw') {
            if (week === thisWeek + 2) {
                return false;
            } else if (selectedWeek === 52) {
                setSelectedWeek(1);
                setWeek(1);
                setYear(year! + 1);
            } else {
                setSelectedWeek(selectedWeek! + 1);
                setWeek(selectedWeek! + 1);
            }
            setSelectedDate(newDate.setDate(newDate.getDate() + 7));
        } else if (direction === 'back') {
            if (week === thisWeek - 2 || week === 51) {
                return false;
            } else if (selectedWeek === 1) {
                setSelectedWeek(52);
                setWeek(52);
                setYear(year! - 1);
            } else {
                setSelectedWeek(selectedWeek! - 1);
                setWeek(selectedWeek! - 1);
            }

            setSelectedDate(newDate.setDate(newDate.getDate() - 7));
        }
    };

    const currentFormattedDate = useCallback(
        (format) => {
            return `${months[Number(month) - 1]}-${year}` as string;
        },
        [month, year]
    );

    const getPrevious = useCallback(() => {
        const curMonthAsNumber = Number(month);
        const curYearAsNumber = Number(year);
        const incrementYear = curYearAsNumber - 1;
        let incrementMonth = curMonthAsNumber - 1;

        if (incrementMonth < 1) {
            setYear(incrementYear.toString());
            incrementMonth = 12;
        }

        if (incrementMonth < 10) {
            setMonth('0' + incrementMonth.toString());
        } else {
            setMonth(incrementMonth.toString());
        }
    }, [month, setMonth, setYear, year]);

    const getNext = useCallback(() => {
        const curMonthAsNumber = Number(month);
        const curYearAsNumber = Number(year);
        let incrementMonth = curMonthAsNumber + 1;
        const incrementYear = curYearAsNumber + 1;

        if (incrementMonth > 12) {
            setYear(incrementYear.toString());
            incrementMonth = 1;
        }

        if (incrementMonth < 10 && incrementMonth > 0) {
            setMonth('0' + incrementMonth.toString());
        } else {
            setMonth(incrementMonth.toString());
        }
    }, [month, setMonth, setYear, year]);

    return (
        <div className="flex">
            {pickerType === 'month' && (
                <MonthPicker
                    getNext={getNext}
                    getPrevious={getPrevious}
                    currentFormattedDate={currentFormattedDate}
                ></MonthPicker>
            )}

            {pickerType === 'week' && (
                <WeekPicker
                    currentWeekRange={currentWeekRange}
                    year={year}
                    selectedWeek={selectedWeek}
                    weekScroll={weekScroll}
                ></WeekPicker>
            )}
        </div>
    );
};

export default WeekMonthPicker;
