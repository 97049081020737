import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Select from 'react-select';

function ActivitySelector({ activity, activityGroups, id, index, product }) {
    const { control, register, setValue } = useFormContext();
    const [activityValue, setActivityValue] = useState(activity);
    const [disableSelect, setDisableSelect] = useState(false);

    useEffect(() => {
        if (product && product.is_activity) {
            setDisableSelect(true);
            setValue(`allocations[${index}].activity`, null);
        } else {
            setDisableSelect(false);
        }
    }, [index, activity, product, setValue]);

    const formatString = (str) => {
        const repl = str.replace('-', ' ');
        const sliceIntoArray = repl.split(' ');
        for (let i = 0; i < sliceIntoArray.length; i++) {
            sliceIntoArray[i] =
                sliceIntoArray[i].charAt(0).toUpperCase() +
                sliceIntoArray[i].slice(1);
        }
        const newStr = sliceIntoArray.join(' ');
        return newStr;
    };

    return (
        <Controller
            name={`allocations[${index}].listening_post`}
            control={control}
            render={({ field: { onChange, value } }) => (
                <>
                    {!disableSelect ? (
                        <Select
                            isDisabled={false}
                            key={id}
                            {...register(`allocations[${index}].activity`)}
                            value={{
                                label: activityValue
                                    ? formatString(activityValue)
                                    : 'Please select...',
                                value: activityValue ? activityValue : ''
                            }}
                            options={activityGroups}
                            onChange={(selected, item) => {
                                setActivityValue(selected?.value);
                                setValue(
                                    `allocations[${index}].activity`,
                                    selected?.value,
                                    {
                                        shouldDirty: true,
                                        shouldValidate: true
                                    }
                                );
                            }}
                        />
                    ) : (
                        <Select
                            isDisabled={true}
                            key={id}
                            {...register(`allocations[${index}].activity`)}
                            value={{
                                label: 'Not applicable',
                                value: null
                            }}
                            options={[]}
                            onChange={() => {
                                return null;
                            }}
                        />
                    )}
                </>
            )}
        />
    );
}

export default ActivitySelector;
