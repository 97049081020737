import { ProductReportsProvider } from './product-reports-context';
import { ResultModal } from 'components';
import { SubmitHandler } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import ProductReportsModal from './product-reports-modal';
import useGetProductReports from 'core/api/hooks/product-reports/use-get-product-reports';

type CreateProductReportFormValues = {
    end_date: string;
    name?: string;
    product_uuids?: string[];
    start_date: string;
};

function ProductReportsAddModal({
    endDate,
    setNoReportsAvailable,
    setReportData,
    startDate
}) {
    const { pop, push } = useModals();

    const [enabled, setEnabled] = useState(false);

    const [productUuids, setProductUuids] = useState<string[] | undefined>(
        undefined
    );

    const {
        data: report,
        error: reportError,
        isFetching: isFetchingReport,
        isLoading: isLoadingReport,
        isSuccess: onSuccess,
        refetch: refetchReport
    } = useGetProductReports(enabled, {
        end_date: endDate,
        product_uuids: productUuids,
        start_date: startDate
    });

    const reportReturned = useCallback(() => {
        if (onSuccess) {
            pop();
            if (report?.report?.products.length > 0) {
                push(ResultModal, {
                    description: 'Your report request was successful',
                    title: 'Request successful',
                    variant: 'success'
                });
                setNoReportsAvailable(false);
            } else {
                push(ResultModal, {
                    description:
                        'Unfortunately there are 0 reports available for those products for the selected dates',
                    title: 'Please try and alternative selection',
                    variant: 'error'
                });
                setNoReportsAvailable(true);
            }
            setReportData(report);
        }
    }, [onSuccess, pop, report, setReportData, push, setNoReportsAvailable]);

    useEffect(() => {
        if (onSuccess) {
            return reportReturned();
        }
    }, [onSuccess, reportReturned]);

    // const actionFailed = !isUndefined('error happened');

    const submitFn = useCallback<SubmitHandler<CreateProductReportFormValues>>(
        (formData) => {
            const data = {
                ...formData
            };
            setEnabled(true);
            setProductUuids(data.product_uuids);
        },
        []
    );

    const config = {
        endDate,
        // actionFailed,
        isLoadingReport,
        refetchReport,
        reportError,
        setReportData,
        startDate,
        submitFn
    };

    return (
        <ProductReportsProvider>
            <ProductReportsModal
                config={config}
                loading={isFetchingReport || isLoadingReport}
            ></ProductReportsModal>
        </ProductReportsProvider>
    );
}

export default ProductReportsAddModal;
