import { toastMessage } from 'helpers/helpers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserObject = () => {
        const userObject = localStorage.getItem('user');
        if (
            !userObject ||
            userObject === '' ||
            userObject === '{}' ||
            userObject === null
        ) {
            setIsLoggedIn(false);
            toastMessage('You need to log in to view this page', 'info');
            navigate('/login');
        } else {
            setIsLoggedIn(true);
        }
    };

    useEffect(() => {
        checkUserObject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoggedIn ? children : null;
};

export default ProtectedRoute;
