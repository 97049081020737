import { PropsWithChildren, useMemo } from 'react';
import _ from 'lodash';

import {
    TypographyHeaderProps as CoreTypographyHeaderProps,
    TailwindStyle,
    Typography
} from '@ncc-frontend/core';

interface TypographyHeaderProps extends CoreTypographyHeaderProps {
    level: 1 | 2 | 3 | 4 | 5 | 6;
    shadow: boolean;
}

function TypographyHeader({
    children,
    level,
    shadow,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TypographyHeaderProps>) {
    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            _.merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background: 'dark:bg-ncc-portal-grey-100',
                    boxShadow: level === 1 && shadow ? 'shadow-header' : 'none',
                    display: 'flex',
                    height: 'auto',
                    items: 'items-center',
                    textSize:
                        level === 1
                            ? 'text-h1 font-SemiBold'
                            : level === 2
                            ? 'text-h2 font-SemiBold'
                            : level === 3
                            ? 'text-h3 font-Medium'
                            : level === 4
                            ? 'text-h4 font-Medium'
                            : 'text-xs',
                    textWeight:
                        level === 1
                            ? 'font-semibold'
                            : level === 2
                            ? 'font-medium'
                            : level === 3
                            ? 'font-medium'
                            : level === 4
                            ? 'font-normal'
                            : level === 5
                            ? 'font-normal'
                            : 'font-normal'
                },
                tailwindStyle
            ),
        [level, tailwindStyle, shadow]
    );

    return (
        <Typography.Header
            level={level}
            tailwindStyle={mergedTailwindStyle}
            {...restProps}
        >
            {children}
        </Typography.Header>
    );
}

export default TypographyHeader;
export type { TypographyHeaderProps };
